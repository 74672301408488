import { ErrorMessage } from '@hookform/error-message';
import { Select, SelectProps } from 'antd';
import React, { FunctionComponent } from 'react';
import { FieldErrors } from 'react-hook-form';
import { Col, Row } from 'reactstrap';

import { defaultProps, IBasicInputProps } from './CLInputProps';

interface CLSelectInputProps extends SelectProps, IBasicInputProps {
	name: string;
	errors?: FieldErrors;
	options: { value: string | number | null; label: string }[] | undefined;
	allowEmptyValue?: boolean;
}

const CLSelectInputDefaultProps: CLSelectInputProps = {
	...defaultProps,
	name: undefined,
	options: undefined,
	allowEmptyValue: false,
};
const { Option } = Select;

const CLSelectInput: FunctionComponent<CLSelectInputProps> = React.forwardRef((props): JSX.Element => {
	const className = ['cl-input', `${props.errors?.type ? 'cl-input-errors' : ''}`, props.className]
		.join(' ')
		.trimEnd();
	const { options, errors, name } = props;

	if (props.allowEmptyValue) {
		options.unshift({ value: '', label: '' });
	}

	return (
		<div className="cl-input-wrapper">
			<Row>
				<Col lg={props.colSize.lg} md={props.colSize.md} sm={props.colSize.sm}>
					<label>
						{props.label} {props.required && <span className="cl-input-required">*</span>}
					</label>
				</Col>
				<Col lg={props.colSize.lg} md={props.colSize.md} sm={props.colSize.sm}>
					<Select
						{...props}
						onChange={props.onChange}
						showSearch
						placeholder={props.placeholder}
						className={className}
					>
						{options?.map((option) => (
							<Option key={option.value} value={option.value}>
								{option.label}
							</Option>
						))}
					</Select>
				</Col>
				<Col lg={props.colSize.lg} md={props.colSize.md} sm={props.colSize.sm}>
					{props.errors && (
						<ErrorMessage
							errors={errors}
							name={name}
							render={({ message }) => <div className="cl-text-input-errors">{message}</div>}
						/>
					)}
				</Col>
			</Row>
		</div>
	);
});

CLSelectInput.defaultProps = CLSelectInputDefaultProps;

export default CLSelectInput;
