import './style.css';

import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';

interface CheckboxInterface {
	label?: string;
	wrapperClassName?: string;
	className?: string;
	onChange: (event: CheckboxChangeEvent) => void;
	checked?: boolean;
}

const defaultProps: CheckboxInterface = {
	label: '',
	wrapperClassName: '',
	className: '',
	onChange: () => undefined,
};

const CLCheckbox: React.FC<CheckboxInterface> = (props: CheckboxInterface): JSX.Element => (
	<div className={`cl-checkbox-wrapper ${props.wrapperClassName}`}>
		<Checkbox className={`"cl-checkbox ${props.className}`} {...props}>
			{props.label}
		</Checkbox>
	</div>
);

CLCheckbox.defaultProps = defaultProps;

export default CLCheckbox;
