export enum AmountLimitTypeValues {
	Unknown = -1,
	PerPayment,
	Day,
	Week,
	Fortnight,
	Month,
	HalfAYear,
	Year,
	TotalAmount,
}

export type AmountLimitTypeName =
	| 'perPayment'
	| 'day'
	| 'week'
	| 'fortnight'
	| 'month'
	| 'halfAYear'
	| 'year'
	| 'totalAmount';

export type AmountLimitType = {
	name: AmountLimitTypeName;
	label: { checkboxLabel: string; amountLabel?: string };
	isPeriodic: boolean;
	id: number;
	hide: boolean;
	colSize?: { lg: number };
	startingAtTypesSource?: { name: string; id: number }[];
};

export type AmountLimit = {
	limitType: number;
	amount: number;
	currency: string;
	startingType: number;
	isChecked?: boolean;
	settings?: AmountLimitType;
};

export const getAmountLimitTypeNames = (): AmountLimitTypeName[] => [
	'totalAmount',
	'perPayment',
	'day',
	'week',
	'fortnight',
	'month',
	'halfAYear',
	'year',
];
