import './VrpConsentsGetStarted.css';

import { Alert, Form, Row } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

import { CLButton } from '../../cl-shared-components/Buttons';
import useAxios from '../../hooks/axios';
import { PageTitleContext } from '../../layout/Layout';
import { AdminMissionTypeDisplay } from '../../models/AdminMissions/AdminMissionType';
import { ErrorDetails } from '../../models/HttpResponse/ErrorDetails';
import StatusCodes from '../../models/HttpResponse/StatusCodes';
import { Area, NonUk, Uk } from '../../models/Missions/Area';
import { MISSION_STATUS_FAILED } from '../../models/Missions/MissionStatus';
import { VrpConsentsGetStartedConsentDetails } from '../../models/Missions/VrpMissionConsent/VrpConsentsGetStartedDetails';
import { UpdateMissionStatusPayload } from '../../models/Payments/UkFasterPayment';
import { CreateVrpConsentRequest, VrpPaymentAuthorization } from '../../models/Payments/Vrp/Payment/VrpConsent';
import { AlertStatusInterface } from '../../models/ui/Alert';
import { pageTitles, UK_FASTER_PAYMENT_IN_PROGRESS_STATUS } from '../../resources/config';
import { DEFAULT_ERROR_MESSAGE, PAYMENT_FAIL_MESSAGE, VRP_CONSENT_DETAIL_FAIL } from '../../resources/Errors';
import getNagCoreEnvironmentValue from '../../utils/EnvUtils';
import { License } from '../Licenses';
import VrpConsentsWithDetails from './VrpConsentsGetStartedDetails';

const CVrpConsentsGetStarted = (): JSX.Element => {
	const { missionId } = useParams<{ missionId: string }>();
	const { fetchData } = useAxios({});
	const [form] = Form.useForm();
	const { setPageTitle } = useContext(PageTitleContext);
	const [isLoading, setIsLoading] = useState(true);
	const [consentDetails, setConsentDetails] = useState<VrpConsentsGetStartedConsentDetails>(null);
	const [missionStatus, setMissionStatus] = useState<AlertStatusInterface>({
		type: 'success',
		message: '',
	});
	const [area, setArea] = useState<Area>();

	const getMission = async () => {
		setIsLoading(true);
		const result = (await fetchData({
			method: 'GET',
			url: `api/missions/consentdetails/${missionId}`,
			headers: { accept: '*/*' },
		})) as AxiosResponse<VrpConsentsGetStartedConsentDetails> | AxiosError<Error>;

		if ('data' in result) {
			const consentDetailsDb = result?.data;
			setConsentDetails(consentDetailsDb);
			setArea(consentDetailsDb.countryCode === 'GB' ? Uk : NonUk);
		} else {
			setMissionStatus({ type: 'error', message: result.response?.data?.message || VRP_CONSENT_DETAIL_FAIL });
		}

		setIsLoading(false);
	};

	useEffect(() => {
		setPageTitle(pageTitles.vrpConsent);
		getMission().catch((err) => console.error(err));
	}, []);

	const updateMissionStatus = async (payload: UpdateMissionStatusPayload): Promise<AxiosResponse | AxiosError> =>
		(await fetchData({
			method: 'post',
			url: 'api/missions/status',
			data: payload,
		})) as AxiosResponse | AxiosError;

	const redirectToCallbackUrl = (response: AxiosResponse<VrpPaymentAuthorization>): void => {
		const { redirectUrl, status } = response.data;
		if (status === UK_FASTER_PAYMENT_IN_PROGRESS_STATUS) {
			window.location.replace(redirectUrl);
		} else {
			throw Error(PAYMENT_FAIL_MESSAGE);
		}
	};

	const setMissionStatusAsFailed = async (errorMessage: string) => {
		setMissionStatus({ type: 'error', message: errorMessage || PAYMENT_FAIL_MESSAGE });

		const missionStatusPayload: UpdateMissionStatusPayload = {
			missionId: parseInt(missionId),
			missionStatus: MISSION_STATUS_FAILED,
		};
		await updateMissionStatus(missionStatusPayload);
	};

	const createVrpConsent = async () => {
		const payload: CreateVrpConsentRequest = {
			missionId,
			environment: getNagCoreEnvironmentValue(),
			endUserContext: {
				language: navigator.language,
				userAgent: navigator.userAgent,
				deviceUuid: uuidv4(),
			},
		};

		try {
			const vrpConsentDataResult = (await fetchData({
				method: 'post',
				url: 'api/cvrp/consent',
				data: payload,
			})) as AxiosResponse<VrpPaymentAuthorization> | AxiosError<ErrorDetails>;

			if (vrpConsentDataResult.status === StatusCodes.OK) {
				redirectToCallbackUrl(vrpConsentDataResult as AxiosResponse<VrpPaymentAuthorization>);
			} else {
				const error = vrpConsentDataResult as AxiosError<ErrorDetails>;
				await setMissionStatusAsFailed(error?.response?.data?.detail || DEFAULT_ERROR_MESSAGE);
			}
		} catch (error) {
			const errorObject = error as Error;
			await setMissionStatusAsFailed(errorObject?.message || DEFAULT_ERROR_MESSAGE);
		}
	};

	const onStart = async () => {
		try {
			setIsLoading(true);
			await form.validateFields(['termAndConditions']);

			await createVrpConsent();
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			{isLoading && <Spinner />}

			{missionStatus.message && <Alert message={missionStatus.message} type={missionStatus.type} />}

			{!missionStatus.message && !isLoading && consentDetails && (
				<>
					<VrpConsentsWithDetails data={consentDetails} />

					<Row className="vrp-consent-term-conditions">
						<Form
							form={form}
							initialValues={{ termAndConditions: consentDetails.hasTermsAccepted ?? false }}
							onFinish={onStart}
						>
							<p className="cl-uk-faster-paragraph">
								Please click on the bellow button to initiate selected{' '}
								{AdminMissionTypeDisplay[consentDetails.type as keyof typeof AdminMissionTypeDisplay]}{' '}
								mission.
							</p>
							{<License area={area?.value} buttonPosition="bellow" missionType={consentDetails.type} />}
							<CLButton className="cl-button-start" label="Start" htmlType="submit" />
						</Form>
					</Row>
				</>
			)}
		</>
	);
};

export default CVrpConsentsGetStarted;
