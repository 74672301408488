import './Layout.css';
import 'react-toastify/dist/ReactToastify.css';

import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Container } from 'reactstrap';

import { pathsWithFooter } from '../resources/config';
import Footer from './Footer';
import NavMenu from './Navbar/NavMenu';
import PageTitle from './PageTitle/PageTitle';

interface Props {
	children: React.ReactNode;
}

interface IPageTitleContextType {
	pageTitle: string;
	setPageTitle: Dispatch<SetStateAction<string>>;
}

export const PageTitleContext = createContext<IPageTitleContextType>({ pageTitle: '', setPageTitle: () => undefined });

const Layout = (props: Props): JSX.Element => {
	const [pageTitle, setPageTitle] = useState('');
	const [visible, setVisible] = useState<boolean>(true);
	const location = useLocation();
	useEffect(() => {
		const isFooterPathName = pathsWithFooter.includes(location.pathname);
		setVisible(isFooterPathName);
	}, [location]);
	return (
		<div className="aiia-container aiia-cl-theme">
			<PageTitleContext.Provider value={{ pageTitle, setPageTitle }}>
				<ToastContainer />
				<NavMenu />
				<div className="cl-container">
					<Container className={'content'}>
						<PageTitle title={pageTitle}></PageTitle>
						{props.children}
					</Container>
				</div>
				<Footer visible={visible} />
			</PageTitleContext.Provider>
		</div>
	);
};

export default Layout;
