export type Area = {
	id?: number;
	name?: string;
	value?: string;
};

export const areas: Area[] = [
	{
		id: 0,
		name: 'UK',
		value: 'Uk',
	},
	{
		id: 1,
		name: 'Non-UK',
		value: 'NonUk',
	},
];

export const [Uk, NonUk] = areas;
