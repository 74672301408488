import './BanksTable.css';

import { Link } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { Empty, Space } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import React, { useContext, useEffect, useState } from 'react';

import { CLButton } from '../../cl-shared-components/Buttons';
import GenericTableWithPagination from '../../cl-shared-components/GenericTable/GenericTableWithPagination';
import confirm from '../../cl-shared-components/Modal/GenericModal';
import Spinner from '../../cl-shared-components/Spinner/Spinner';
import { DisplayErrorToaster, DisplaySuccessToaster } from '../../cl-shared-components/Toaster/DisplayToaster';
import useAxios from '../../hooks/axios';
import { PageTitleContext } from '../../layout/Layout';
import { Bank } from '../../models/Bank/Bank';
import { Error } from '../../models/HttpResponse/Error';
import StatusCodes from '../../models/HttpResponse/StatusCodes';
import { pageTitles } from '../../resources/config';
import { confirmDeteleBankMessage } from '../../resources/Confirm';
import { successfullyDeletedBankMessage } from '../../resources/Success';
import BankModal from './BankModal';

const BanksProfile = (): JSX.Element => {
	const [openBankModal, setOpenBankModal] = useState<boolean>(false);
	const [banks, setBanks] = useState<Bank[]>([]);
	const { fetchData } = useAxios({});
	const { setPageTitle } = useContext(PageTitleContext);

	const {
		response: banksResponse,
		error: banksError,
		loading,
		sendData,
	}: { response: AxiosResponse<Bank[]>; error: Error; loading: boolean; sendData: () => void } = useAxios({
		method: 'GET',
		url: '/api/testerproviders',
		headers: {
			accept: '*/*',
		},
	});

	useEffect(() => {
		setPageTitle(pageTitles.testerProfilePage);
		const load = (): void => {
			if (banksResponse !== undefined) {
				setBanks(banksResponse.data);
			} else if (banksError !== undefined) {
				setBanks([]);
			}
		};
		load();
	}, [banksResponse, banksError]);

	const updateTable = () => {
		sendData();
	};
	const deleteBank = async (id: number) => {
		try {
			const result = (await fetchData({
				method: 'delete',
				url: `/api/testerproviders/${id}`,
			})) as AxiosResponse | AxiosError;

			if (result.status === StatusCodes.NO_CONTENT) {
				DisplaySuccessToaster(successfullyDeletedBankMessage);
				updateTable();
			} else {
				const resultError = result as AxiosError;
				const errorToDisplay = resultError.response.data as Error;
				DisplayErrorToaster(errorToDisplay.message);
			}
		} catch (error) {
			console.error(error);
		}
	};
	const confirmDeleteBank = (id: number): void => {
		confirm(confirmDeteleBankMessage, () => deleteBank(id));
	};

	const columns = React.useMemo<ColumnDef<Bank>[]>(
		() => [
			{
				header: 'Bank Name',
				size: 400,
				cell: ({ getValue }) => (
					<div>
						<>{getValue()}</>
					</div>
				),
				accessorFn: (row) => row.displayName ?? row.legalName,
				id: 'name',
			},
			{
				header: 'IBAN',
				size: 400,
				enableSorting: false,
				cell: ({ getValue }) => (
					<div>
						<>{getValue()}</>
					</div>
				),
				accessorFn: (row): string => row.iban,
				id: 'iban',
			},
			{
				header: 'BBAN',
				size: 400,
				enableSorting: false,
				cell: ({ getValue }) => (
					<div>
						<>{getValue()}</>
					</div>
				),
				accessorFn: (row): string => row.bban,
				id: 'bban',
			},
			{
				header: 'Status',
				accessorFn: (row) => row.status,
				cell: ({ getValue }) => (
					<Link className="cell-content">
						<>{getValue()}</>
					</Link>
				),
			},
			{
				header: 'Action',
				accessorFn: (row) => row.id,
				cell: ({ row: { original } }) => (
					<Link className="cell-content" onClick={() => confirmDeleteBank(original.id)}>
						Delete
					</Link>
				),
			},
		],
		[]
	);

	if (loading) return <Spinner />;

	return (
		<div>
			{banks.length > 0 ? (
				<GenericTableWithPagination
					{...{ data: banks, columns, defaultSortBy: [{ id: 'name', desc: false }] }}
				/>
			) : (
				<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
			)}

			<Space wrap className="cl-actions-wrapper">
				<CLButton label="Add Bank" onClick={() => setOpenBankModal(true)} />
			</Space>

			<BankModal
				isVisible={openBankModal}
				close={() => setOpenBankModal(false)}
				updateTable={() => updateTable()}
			/>
		</div>
	);
};

export default BanksProfile;
