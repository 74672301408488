import './Welcome.css';

import React, { useContext, useEffect } from 'react';

import { PageTitleContext } from '../../layout/Layout';
import { pageTitles } from '../../resources/config';

const Welcome = () => {
	const { setPageTitle } = useContext(PageTitleContext);
	useEffect(() => {
		setPageTitle(pageTitles.welcome);
	});

	return (
		<div className="welcome">
			<p className="message">Setup your account to finish the Signup process</p>
		</div>
	);
};

export default Welcome;
