import moment from 'moment';
import * as yup from 'yup';

import { DATE_FORMAT } from '../../../resources/config';
import {
	SIP_AMOUNT_GREATER_THAN_0_ERROR_MESSAGE,
	SIP_AMOUNT_MAX_DECIMALS_ERROR_MESSAGE,
	SIP_AMOUNT_REQUIRED_ERROR_MESSAGE,
	SIP_CURRENCY_MAX_LENGTH_ERROR_MESSAGE,
	SIP_CURRENCY_REQUIRED_ERROR_MESSAGE,
	SIP_END_TO_END_ID_LENGTH_ERROR_MESSAGE,
	SIP_END_TO_END_ID_REQUIRED_ERROR_MESSAGE,
	SIP_EXECUTION_DATE_IN_FUTURE_ERROR_MESSAGE,
	SIP_EXECUTION_DATE_REQUIRED_ERROR_MESSAGE,
	SIP_REFERENCE_INFORMATION_MAX_LENGTH_ERROR_MESSAGE,
	SIP_REFERENCE_INFORMATION_REQUIRED_ERROR_MESSAGE,
	SIP_THIRD_PARTY_TYPE_REQUIRED_ERROR_MESSAGE,
} from '../../../resources/Errors';
import { isTownNameRequired } from '../../../utils/MissionUtils';

const SingleImmediatePaymentSchemaValidation = yup.object().shape({
	schedulePayment: yup.bool().default(false),
	amount: yup
		.number()
		.min(0.01, SIP_AMOUNT_GREATER_THAN_0_ERROR_MESSAGE)
		.typeError(SIP_AMOUNT_REQUIRED_ERROR_MESSAGE)
		.test('is-decimal', SIP_AMOUNT_MAX_DECIMALS_ERROR_MESSAGE, (value: number) =>
			/^\d{0,4}(?:[.,]\d{1,2})?$/.test(`${value}`)
		),
	currency: yup
		.string()
		.test('required', SIP_CURRENCY_REQUIRED_ERROR_MESSAGE, (value: string) => value !== '')
		.test('number-of-3-characters', SIP_CURRENCY_MAX_LENGTH_ERROR_MESSAGE, (value: string) =>
			/^[a-zA-Z]{3}$/.test(value)
		)
		.trim(),
	executionDate: yup
		.date()
		.nullable()
		.when('schedulePayment', {
			is: true,
			then: yup
				.date()
				.nullable()
				.min(moment().add(1, 'day').format(DATE_FORMAT), SIP_EXECUTION_DATE_IN_FUTURE_ERROR_MESSAGE)
				.required(SIP_EXECUTION_DATE_REQUIRED_ERROR_MESSAGE),
		})
		.when('schedulePayment', {
			is: false,
			then: yup.date().default(null).nullable(),
		}),
	referenceInformation: yup
		.string()
		.required(SIP_REFERENCE_INFORMATION_REQUIRED_ERROR_MESSAGE)
		.max(18, SIP_REFERENCE_INFORMATION_MAX_LENGTH_ERROR_MESSAGE)
		.trim(),
	endToEndId: yup
		.string()
		.required(SIP_END_TO_END_ID_REQUIRED_ERROR_MESSAGE)
		.test('number-of-35-characters', SIP_END_TO_END_ID_LENGTH_ERROR_MESSAGE, (value: string) =>
			/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{11}$/.test(value)
		)
		.trim(),
	thirdPartyType: yup.string().required(SIP_THIRD_PARTY_TYPE_REQUIRED_ERROR_MESSAGE).trim(),
	townName: yup.string().when('bankId', {
		is: (value: string) => isTownNameRequired(value),
		then: yup
			.string()
			.max(35, 'The Town requests maximum 35 characters')
			.required('The Town must be filled in')
			.trim(),
		otherwise: yup.string().nullable().notRequired(),
	}),
});

export { SingleImmediatePaymentSchemaValidation };
