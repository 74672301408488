import moment from 'moment';
import * as yup from 'yup';

import { StartingAtType } from '../../../models/Payments/Vrp/VrpProviderDetails';
import { DATE_FORMAT } from '../../../resources/config';
import {
	ACCOUNT_NUMBER_REQUIRED_ERROR_MESSAGE,
	CVRP_NATWEST_MAXIMUM_AMOUNT_REQUIRED_ERROR_MESSAGE,
	CVRP_NATWEST_NUMBER_PAYMENTS_REQUIRED_ERROR_MESSAGE,
	DESTINATION_NAME_REQUIRED_ERROR_MESSAGE,
	SORT_CODE_REQUIRED_ERROR_MESSAGE,
	VRP_PAYMENT_DETAILS_AMOUNT_REQUIRED_ERROR_MESSAGE,
	VRP_PAYMENT_DETAILS_CURRENCY_REQUIRED_ERROR_MESSAGE,
	VRP_PAYMENT_DETAILS_END_TO_END_REQUIRED_ERROR_MESSAGE,
	VRP_PAYMENT_PURPOSE_CODE_REQUIRED_ERROR_MESSAGE,
	VRP_VALID_FROM_MIN_DATE_ERROR_MESSAGE,
	VRP_VALID_FROM_REQUIRED_ERROR_MESSAGE,
	VRP_VALID_TO_MIN_DATE_ERROR_MESSAGE,
	VRP_VALID_TO_REQUIRED_ERROR_MESSAGE,
} from '../../../resources/Errors';

const getSchemaAmountLimit = yup.object().shape({
	isChecked: yup.bool().default(false),
	amount: yup.number().when('isChecked', {
		is: true,
		then: yup
			.number()
			.min(0.1, 'The Amount must be greater than 0.1')
			.max(2.0, 'The Amount must be lower than 2.0')
			.typeError('The Amount must be filled in')
			.test(
				'is-decimal',
				'The Amount must have either a single digit or a maximum of two decimal places',
				(value) => /^\d(?:[.,]\d{1,2})?$/.test(`${value}`)
			),
		otherwise: yup.number().default(null).nullable(),
	}),
	currency: yup.string().when('isChecked', {
		is: true,
		then: yup
			.string()
			.test('required', 'The Currency must be filled in', (value) => value !== '')
			.test('number-of-3-characters', 'The Currency requires 3 characters', (value) =>
				/^[a-zA-Z]{3}$/.test(value)
			)
			.trim(),
		otherwise: yup.string().default(null).nullable(),
	}),
	startingType: yup.number().when('isChecked', {
		is: true,
		then: yup
			.number()
			.oneOf([StartingAtType.Consent, StartingAtType.Calendar], '"Starting at" must be filled in')
			.required('"Starting at" must be filled in'),
		otherwise: yup.number().default(-1).nullable(),
	}),
});

const getCVRPSchemaAmountLimit = yup.object().shape({
	isChecked: yup.bool().default(false),
	amount: yup.number().when('isChecked', {
		is: true,
		then: yup
			.number()
			.min(1.0, 'The Amount must be greater than 1.0')
			.max(10.0, 'The Amount must be lower than 10.0')
			.typeError('The Amount must be filled in')
			.test(
				'is-decimal',
				'The Amount must have either a single digit or a maximum of two decimal places',
				(value) => /^\d+(?:[.,]\d{1,1})?$/.test(`${value}`)
			),
		otherwise: yup.number().default(null).nullable(),
	}),
	currency: yup.string().when('isChecked', {
		is: true,
		then: yup
			.string()
			.test('required', 'The Currency must be filled in', (value) => value !== '')
			.test('number-of-3-characters', 'The Currency requires 3 characters', (value) =>
				/^[a-zA-Z]{3}$/.test(value)
			)
			.trim(),
		otherwise: yup.string().default(null).nullable(),
	}),
	startingType: yup.number().when('isChecked', {
		is: true,
		then: yup
			.number()
			.oneOf([StartingAtType.Consent, StartingAtType.Calendar], '"Starting at" must be filled in')
			.required('"Starting at" must be filled in'),
		otherwise: yup.number().default(-1).nullable(),
	}),
});

const getSchemaPaymentLimit = yup.object().shape({
	isChecked: yup.bool().default(false),
	numberOfPayments: yup.number().when('isChecked', {
		is: true,
		then: yup
			.number()
			.min(1, CVRP_NATWEST_NUMBER_PAYMENTS_REQUIRED_ERROR_MESSAGE)
			.typeError('The No. of payments must be filled in')
			.test('is-integer', 'The No. of payments should be an integer', (value) =>
				/^(0|[1-9]\d*)$/.test(`${value}`)
			),
		otherwise: yup.number().default(null).nullable(),
	}),
	startingType: yup.number().when('isChecked', {
		is: true,
		then: yup
			.number()
			.oneOf([StartingAtType.Consent, StartingAtType.Calendar], '"Starting at" must be filled in')
			.required('"Starting at" must be filled in'),
		otherwise: yup.number().default(-1).nullable(),
	}),
});

const DefaultSchemaValidation = yup.object().shape({
	validFrom: yup
		.date()
		.nullable()
		.min(moment().format(DATE_FORMAT), VRP_VALID_FROM_MIN_DATE_ERROR_MESSAGE)
		.required(VRP_VALID_FROM_REQUIRED_ERROR_MESSAGE),
	validTo: yup
		.date()
		.nullable()
		.required(VRP_VALID_TO_REQUIRED_ERROR_MESSAGE)
		.min(yup.ref('validFrom'), VRP_VALID_TO_MIN_DATE_ERROR_MESSAGE),
	perPayment: yup.object().shape({
		isChecked: yup.bool().default(false),
		amount: yup.number().when('isChecked', {
			is: true,
			then: yup
				.number()
				.min(1.0, 'The Amount must be greater than 1.0')
				.max(10.0, 'The Amount must be lower than 10.0')
				.typeError('The Amount must be filled in')
				.test(
					'is-decimal',
					'The Amount must have either a single digit or a maximum of two decimal places',
					(value) => /^\d+(?:[.,]\d{1,1})?$/.test(`${value}`)
				),
			otherwise: yup.number().default(null).nullable(),
		}),
		currency: yup.string().when('isChecked', {
			is: true,
			then: yup
				.string()
				.test('required', 'The Currency must be filled in', (value) => value !== '')
				.test('number-of-3-characters', 'The Currency requires 3 characters', (value) =>
					/^[a-zA-Z]{3}$/.test(value)
				)
				.trim(),
			otherwise: yup.string().default(null).nullable(),
		}),
	}),
	day: getCVRPSchemaAmountLimit,
	week: getCVRPSchemaAmountLimit,
	fortnight: getCVRPSchemaAmountLimit,
	month: getCVRPSchemaAmountLimit,
	halfAYear: getCVRPSchemaAmountLimit,
	year: getCVRPSchemaAmountLimit,
	creditorName: yup.string().required(DESTINATION_NAME_REQUIRED_ERROR_MESSAGE),
	creditorSortCode: yup
		.string()
		.nullable()
		.required(SORT_CODE_REQUIRED_ERROR_MESSAGE)
		.test(
			'only-numeric',
			'The Sort Code must contain only digits',
			(value: string) => !value || /^\d+$/.test(value)
		)
		.test(
			'number-of-8-characters',
			'The Sort Code requests a number of 6 characters',
			(value: string) => !value || /^\w{6}$/.test(value)
		)
		.trim(),
	creditorAccountNumber: yup
		.string()
		.nullable()
		.required(ACCOUNT_NUMBER_REQUIRED_ERROR_MESSAGE)
		.test(
			'only-numeric',
			'The Account Number must contain only digits',
			(value: string) => !value || /^\d+$/.test(value)
		)
		.test(
			'number-of-8-characters',
			'The Account Number requests a number of 8 characters',
			(value: string) => !value || /^\w{8}$/.test(value)
		)
		.trim(),
	referenceInformation: yup.string().nullable().max(15, 'The Message Text requests maximum 15 characters').trim(),
	paymentPurposeCode: yup.string().trim().required(VRP_PAYMENT_PURPOSE_CODE_REQUIRED_ERROR_MESSAGE),
	paymentDetails: yup.object().shape({
		endToEndId: yup
			.string()
			.required(VRP_PAYMENT_DETAILS_END_TO_END_REQUIRED_ERROR_MESSAGE)
			.max(31, 'The End-to-End Id requests maximum 31 characters')
			.trim(),
		currency: yup
			.string()
			.test('required', VRP_PAYMENT_DETAILS_CURRENCY_REQUIRED_ERROR_MESSAGE, (value: string) => value !== '')
			.test('number-of-3-characters', 'The Currency requests a number of 3 characters', (value: string) =>
				/^[a-zA-Z]{3}$/.test(value)
			)
			.trim(),
		amount: yup
			.number()
			.min(0.01, 'The Amount must be greater than 0')
			.max(1, 'The Amount The quantity must be less than 1')
			.typeError(VRP_PAYMENT_DETAILS_AMOUNT_REQUIRED_ERROR_MESSAGE)
			.test('is-decimal', 'The Amount must have maximum two decimals', (value: number) =>
				/^\d{0,4}(?:[.,]\d{1,2})?$/.test(`${value}`)
			),
	}),
});

const NatWestSchemaValidation = yup.object().shape({
	validFrom: yup
		.date()
		.nullable()
		.min(moment().format(DATE_FORMAT), VRP_VALID_FROM_MIN_DATE_ERROR_MESSAGE)
		.required(VRP_VALID_FROM_REQUIRED_ERROR_MESSAGE),
	validTo: yup
		.date()
		.nullable()
		.required(VRP_VALID_TO_REQUIRED_ERROR_MESSAGE)
		.min(yup.ref('validFrom'), VRP_VALID_TO_MIN_DATE_ERROR_MESSAGE),
	totalAmount: yup.object().shape({
		isChecked: yup.bool().default(false),
		amount: yup.number().when('isChecked', {
			is: true,
			then: yup
				.number()
				.nullable()
				.min(1, CVRP_NATWEST_MAXIMUM_AMOUNT_REQUIRED_ERROR_MESSAGE)
				.typeError('The Maximum total amount must be filled in'),
			otherwise: yup.number().default(null).nullable(),
		}),
		currency: yup.string().when('isChecked', {
			is: true,
			then: yup
				.string()
				.test('required', 'The Currency must be filled in', (value) => value !== '')
				.test('number-of-3-characters', 'The Currency requires 3 characters', (value) =>
					/^[a-zA-Z]{3}$/.test(value)
				)
				.trim(),
			otherwise: yup.string().default(null).nullable(),
		}),
	}),
	perPayment: yup.object().shape({
		isChecked: yup.bool().default(false),
		amount: yup.number().when('isChecked', {
			is: true,
			then: yup
				.number()
				.min(0.1, 'The Amount must be greater than 0.1')
				.max(2.0, 'The Amount must be lower than 2.0')
				.typeError('The Amount must be filled in')
				.test(
					'is-decimal',
					'The Amount must have either a single digit or a maximum of two decimal places',
					(value) => /^\d(?:[.,]\d{1,2})?$/.test(`${value}`)
				),
			otherwise: yup.number().default(null).nullable(),
		}),
		currency: yup.string().when('isChecked', {
			is: true,
			then: yup
				.string()
				.test('required', 'The Currency must be filled in', (value) => value !== '')
				.test('number-of-3-characters', 'The Currency requires 3 characters', (value) =>
					/^[a-zA-Z]{3}$/.test(value)
				)
				.trim(),
			otherwise: yup.string().default(null).nullable(),
		}),
	}),
	day: getSchemaAmountLimit,
	week: getSchemaAmountLimit,
	fortnight: getSchemaAmountLimit,
	month: getSchemaAmountLimit,
	halfAYear: getSchemaAmountLimit,
	year: getSchemaAmountLimit,
	creditorName: yup.string().required(DESTINATION_NAME_REQUIRED_ERROR_MESSAGE),
	creditorSortCode: yup
		.string()
		.nullable()
		.required(SORT_CODE_REQUIRED_ERROR_MESSAGE)
		.test(
			'only-numeric',
			'The Sort Code must contain only digits',
			(value: string) => !value || /^\d+$/.test(value)
		)
		.test(
			'number-of-8-characters',
			'The Sort Code requests a number of 6 characters',
			(value: string) => !value || /^\w{6}$/.test(value)
		)
		.trim(),
	creditorAccountNumber: yup
		.string()
		.nullable()
		.required(ACCOUNT_NUMBER_REQUIRED_ERROR_MESSAGE)
		.test(
			'only-numeric',
			'The Account Number must contain only digits',
			(value: string) => !value || /^\d+$/.test(value)
		)
		.test(
			'number-of-8-characters',
			'The Account Number requests a number of 8 characters',
			(value: string) => !value || /^\w{8}$/.test(value)
		)
		.trim(),
	referenceInformation: yup.string().nullable().max(15, 'The Message Text requests maximum 15 characters').trim(),
	paymentPurposeCode: yup.string().trim().required(VRP_PAYMENT_PURPOSE_CODE_REQUIRED_ERROR_MESSAGE),
	paymentDetails: yup.object().shape({
		endToEndId: yup
			.string()
			.required(VRP_PAYMENT_DETAILS_END_TO_END_REQUIRED_ERROR_MESSAGE)
			.max(31, 'The End-to-End Id requests maximum 31 characters')
			.trim(),
		currency: yup
			.string()
			.test('required', VRP_PAYMENT_DETAILS_CURRENCY_REQUIRED_ERROR_MESSAGE, (value: string) => value !== '')
			.test('number-of-3-characters', 'The Currency requests a number of 3 characters', (value: string) =>
				/^[a-zA-Z]{3}$/.test(value)
			)
			.trim(),
		amount: yup
			.number()
			.min(0.01, 'The Amount must be greater than 0')
			.max(1, 'The Amount The quantity must be less than 1')
			.typeError(VRP_PAYMENT_DETAILS_AMOUNT_REQUIRED_ERROR_MESSAGE)
			.test('is-decimal', 'The Amount must have maximum two decimals', (value: number) =>
				/^\d{0,4}(?:[.,]\d{1,2})?$/.test(`${value}`)
			),
	}),
	paymentDay: getSchemaPaymentLimit,
	paymentWeek: getSchemaPaymentLimit,
	paymentFortnight: getSchemaPaymentLimit,
	paymentMonth: getSchemaPaymentLimit,
	paymentHalfAYear: getSchemaPaymentLimit,
	paymentYear: getSchemaPaymentLimit,
});

const initiatePaymentSchemaValidation = yup.object().shape({
	amount: yup
		.number()
		.min(0.1, 'The Amount must be greater than 0')
		.max(2.0, 'The Amount must be lower than 2.0')
		.typeError(VRP_PAYMENT_DETAILS_AMOUNT_REQUIRED_ERROR_MESSAGE)
		.test('is-decimal', 'The Amount must have maximum two decimals', (value: number) =>
			/^\d{0,4}(?:[.,]\d{1,2})?$/.test(`${value}`)
		),
	currency: yup
		.string()
		.test('required', VRP_PAYMENT_DETAILS_CURRENCY_REQUIRED_ERROR_MESSAGE, (value: string) => value !== '')
		.test('number-of-3-characters', 'The Currency requests a number of 3 characters', (value: string) =>
			/^[a-zA-Z]{3}$/.test(value)
		)
		.trim(),
});

const getSchemaValidation = (defaultSchema: boolean) =>
	defaultSchema ? DefaultSchemaValidation : NatWestSchemaValidation;

export { getSchemaValidation, initiatePaymentSchemaValidation };
