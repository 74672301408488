import { Alert } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import Spinner from '../../cl-shared-components/Spinner/Spinner';
import useAxios from '../../hooks/axios';
import { PageTitleContext } from '../../layout/Layout';
import { ErrorDetails } from '../../models/HttpResponse/ErrorDetails';
import StatusCodes from '../../models/HttpResponse/StatusCodes';
import { MISSION_STATUS_COMPLETED, MISSION_STATUS_FAILED } from '../../models/Missions/MissionStatus';
import { UpdateMissionStatusPayload } from '../../models/Payments/SingleImmediatePayment';
import { AlertStatusInterface } from '../../models/ui/Alert';
import { pageTitles } from '../../resources/config';
import { SIP_TRANSACTIONS_FAIL_MESSAGE, SIP_TRANSACTIONS_ROUTING_ERRORS } from '../../resources/Errors';
import { getPaymentStatusMessage, PAYMENT_STATUSES } from '../../resources/Success';

const SingleImmediatePaymentStageRouting = (): JSX.Element => {
	const { setPageTitle } = useContext(PageTitleContext);
	const { fetchData } = useAxios({});
	const { missionId } = useParams<{ missionId: string }>();
	const { search, hash } = useLocation();
	const [isLoading, setIsLoading] = useState(false);
	const [missionStatus, setMissionStatus] = useState<AlertStatusInterface>({
		type: 'success',
		message: '',
	});

	const getHashParameters = (): URLSearchParams => new URLSearchParams(hash ? hash.replace('#', '') : search);
	const hashParameters = getHashParameters();

	const sipCallbackHandler = async (): Promise<AxiosResponse | AxiosError> => {
		const stageResponse = (await fetchData({
			method: 'get',
			url: `api/apv3/sip/status?missionId=${missionId}`,
			headers: { accept: '*/*' },
		})) as AxiosResponse | AxiosError;

		if (stageResponse.status !== StatusCodes.OK) {
			const stageResponseError = stageResponse as AxiosError<ErrorDetails>;
			if (stageResponseError) {
				const { data } = stageResponseError.response;
				throw Error(data?.detail || stageResponseError.message);
			}
		}
		return stageResponse;
	};

	const updateMissionStatus = async (payload: UpdateMissionStatusPayload): Promise<AxiosResponse | AxiosError> =>
		(await fetchData({
			method: 'post',
			url: 'api/missions/status',
			data: payload,
		})) as AxiosResponse | AxiosError;

	const showMissionStatus = (type: 'success' | 'warning' | 'error', paymentStatusMessage: string) => {
		setMissionStatus({ type, message: paymentStatusMessage });
	};

	const updateMissionStatusAsync = async (id: string, statusMission: number) => {
		const missionStatusPayload: UpdateMissionStatusPayload = {
			missionId: parseInt(id),
			missionStatus: statusMission,
		};

		const { status } = await updateMissionStatus(missionStatusPayload);
		const isMissionRequestOk = status === StatusCodes.OK;

		if (!isMissionRequestOk) {
			throw Error(SIP_TRANSACTIONS_FAIL_MESSAGE);
		}
	};
	const handleStageRoutingSuccessResponse = async () => {
		try {
			setIsLoading(true);
			const callbackSuccess = await sipCallbackHandler();

			if (callbackSuccess.status === StatusCodes.OK) {
				const sipStatus = JSON.parse(callbackSuccess.request.response);
				const { code } = sipStatus.status;

				const paymentStatus = PAYMENT_STATUSES[code];
				const paymentStatusMessage = getPaymentStatusMessage(paymentStatus);
				const successfulPaymentStatuses = [4, 5, 6];
				const pendingPaymentStatus = 3;

				if (successfulPaymentStatuses.includes(code)) {
					await updateMissionStatusAsync(missionId, MISSION_STATUS_COMPLETED);
					showMissionStatus('success', paymentStatusMessage);
				} else if (code === pendingPaymentStatus) {
					await updateMissionStatusAsync(missionId, MISSION_STATUS_FAILED);
					showMissionStatus('warning', paymentStatusMessage);
				} else {
					await updateMissionStatusAsync(missionId, MISSION_STATUS_FAILED);
					showMissionStatus('error', paymentStatusMessage);
				}
			}
		} catch (error) {
			const errorObject = error as Error;
			setMissionStatus({ type: 'error', message: errorObject.message || SIP_TRANSACTIONS_FAIL_MESSAGE });
		} finally {
			setIsLoading(false);
		}
	};

	const handleStageRoutingError = async (errorType: string): Promise<void> => {
		let message = SIP_TRANSACTIONS_FAIL_MESSAGE;
		if (errorType) {
			message = SIP_TRANSACTIONS_ROUTING_ERRORS[errorType];
		}

		setMissionStatus({
			type: 'error',
			message,
		});

		try {
			const missionStatusPayload: UpdateMissionStatusPayload = {
				missionId: parseInt(hashParameters.get('state')),
				missionStatus: MISSION_STATUS_FAILED,
			};
			await updateMissionStatus(missionStatusPayload);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		setPageTitle(pageTitles.accountAuthorization);
		const errorType = hashParameters.get('error');
		const load = async (): Promise<void> => {
			if (errorType) {
				await handleStageRoutingError(errorType);
			} else {
				await handleStageRoutingSuccessResponse();
			}
		};
		void load();
	}, []);

	return (
		<>
			{isLoading && (
				<div className="spinnerWrapper">
					<Spinner />
				</div>
			)}
			{missionStatus.message && <Alert message={missionStatus.message} type={missionStatus.type} />}
		</>
	);
};

export default SingleImmediatePaymentStageRouting;
