import './TesterAgreement.css';

import { useAuth0 } from '@auth0/auth0-react';
import { Col, Form, Row } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CLButton } from '../../cl-shared-components/Buttons';
import { CLCheckbox } from '../../cl-shared-components/Checkbox';
import useAxios from '../../hooks/axios';
import { PageTitleContext } from '../../layout/Layout';
import { pageTitles } from '../../resources/config';
import { RootState } from '../../store';
import { setJwtToken } from '../../store/reducers/authReducer';
import PrivacyPolicy from '../PrivacyPolicy';

const FormItem = Form.Item;

export const TesterAgreement = () => {
	const history = useHistory();
	const postData = useAxios({});
	const [checked, setChecked] = React.useState<boolean>(false);
	const { getAccessTokenSilently } = useAuth0();
	const dispatch = useDispatch();
	const { setPageTitle } = useContext(PageTitleContext);

	const handleChange = () => {
		setChecked(!checked);
	};

	const tester = useSelector((state: RootState) => state.tester.currentTester);

	useEffect(() => {
		setPageTitle(pageTitles.welcome);
	});

	const onFinish = async () => {
		try {
			await postData.fetchData({
				method: 'post',
				url: 'api/testers/register',
				data: {
					...tester,
					isPrivacyPolicyAccepted: checked,
				},
			});
			history.push('/tester-onboarding');
			const token = await getAccessTokenSilently({ ignoreCache: true });
			dispatch(setJwtToken(token));
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<>
			<div>
				<p className="message">Setup your account to finish the Signup process</p>
			</div>
			<div className="policy-content">
				<div className="rounded border overflow-auto p-3">
					<h1>Privacy Policy</h1>
					<PrivacyPolicy />
				</div>
				<div className="form">
					<Form onFinish={onFinish} layout="vertical">
						<Form.Item
							name="agreement"
							valuePropName="checked"
							rules={[
								{
									validator: (_, value) =>
										value
											? Promise.resolve()
											: Promise.reject(new Error('Please accept Terms & Conditions')),
								},
							]}
						>
							<CLCheckbox onChange={handleChange} label="I agree to all Terms & Conditions" />
						</Form.Item>
						<div className="buttons">
							<Row>
								<Col span={3}>
									<FormItem>
										<CLButton label="Submit" htmlType="submit" />
									</FormItem>
								</Col>
							</Row>
						</div>
					</Form>
				</div>
			</div>
		</>
	);
};
export default TesterAgreement;
