import './VrpConsentDetails.css';

import { Col, Row } from 'antd';

import { CONSENT_DETAIL_LABELS, VrpMissionDetails } from '../../../../../models/Missions/VrpConsentDetailsType';
import { AmountLimit } from '../../../../../models/Payments/Vrp/Limits/AmountLimit';
import { PaymentLimit } from '../../../../../models/Payments/Vrp/Limits/PaymentLimit';
import {
	formatDateWithoutTime,
	formatDateWithTime,
	getCreditorAccountTypeByOrdinal,
	getLabelForAmountLimit,
	getLabelForPaymentLimit,
	getMissionTypeByName,
	getPaymentContextCodeByOrdinal,
	getPaymentPurposeCodeByOrdinal,
	isBooleanValue,
	isPropertyDefinedAndNotNull,
} from '../../../../../utils/VrpConsentUtils';

interface VrpConsentDetailsProps {
	data: VrpMissionDetails;
}

const VrpConsentDetails = (props: VrpConsentDetailsProps): JSX.Element => {
	const { type, createdDate, consentDetails } = props.data;

	return (
		<Row className="cl-consent-details-container">
			<Col xs={24} lg={24}>
				<h6>Consent Details</h6>
			</Col>
			<Col xs={24} lg={24} className="cl-consent-details">
				{isPropertyDefinedAndNotNull(consentDetails, 'application') && (
					<Row gutter={{ xs: 8, sm: 16 }}>
						<Col xs={12} lg={12}>
							{CONSENT_DETAIL_LABELS.application}:
						</Col>
						<Col xs={12} lg={12}>
							{consentDetails.application}
						</Col>
					</Row>
				)}
				{isPropertyDefinedAndNotNull(consentDetails, 'provider') && (
					<Row gutter={{ xs: 8, sm: 16 }}>
						<Col xs={12} lg={12}>
							{CONSENT_DETAIL_LABELS.provider}:
						</Col>
						<Col xs={12} lg={12}>
							{consentDetails.provider}
						</Col>
					</Row>
				)}
				{type !== undefined && (
					<Row gutter={{ xs: 8, sm: 16 }}>
						<Col xs={12} lg={12}>
							{CONSENT_DETAIL_LABELS.vrpType}:
						</Col>
						<Col xs={12} lg={12}>
							{getMissionTypeByName(type)}
						</Col>
					</Row>
				)}
				{isPropertyDefinedAndNotNull(consentDetails, 'destinationName') && (
					<Row gutter={{ xs: 8, sm: 16 }}>
						<Col xs={12} lg={12}>
							{CONSENT_DETAIL_LABELS.destinationName}:
						</Col>
						<Col xs={12} lg={12}>
							{consentDetails.destinationName}
						</Col>
					</Row>
				)}
				{isPropertyDefinedAndNotNull(consentDetails, 'destinationSortCode') && (
					<Row gutter={{ xs: 8, sm: 16 }}>
						<Col xs={12} lg={12}>
							{CONSENT_DETAIL_LABELS.destinationSortCode}:
						</Col>
						<Col xs={12} lg={12}>
							{consentDetails.destinationSortCode}
						</Col>
					</Row>
				)}
				{isPropertyDefinedAndNotNull(consentDetails, 'destinationAccountNumber') && (
					<Row gutter={{ xs: 8, sm: 16 }}>
						<Col xs={12} lg={12}>
							{CONSENT_DETAIL_LABELS.destinationAccountNumber}:
						</Col>
						<Col xs={12} lg={12}>
							{consentDetails.destinationAccountNumber}
						</Col>
					</Row>
				)}
				{isPropertyDefinedAndNotNull(consentDetails, 'amountLimits') &&
					consentDetails.amountLimits.map((amountLimit: AmountLimit) => (
						<Row gutter={{ xs: 8, sm: 16 }} key={`amountLimit_${amountLimit.limitType}`}>
							<Col xs={12} lg={12}>
								{getLabelForAmountLimit(amountLimit)}:
							</Col>
							<Col xs={12} lg={12}>
								{amountLimit.amount} {amountLimit.currency}
							</Col>
						</Row>
					))}

				{isPropertyDefinedAndNotNull(consentDetails, 'paymentLimits') &&
					consentDetails.paymentLimits.map((paymentLimit: PaymentLimit) => (
						<Row gutter={{ xs: 8, sm: 16 }} key={`paymentLimit_${paymentLimit.paymentLimit}`}>
							<Col xs={12} lg={12}>
								{getLabelForPaymentLimit(paymentLimit)}:
							</Col>
							<Col xs={12} lg={12}>
								{paymentLimit.numberOfPayments}
							</Col>
						</Row>
					))}
				{isPropertyDefinedAndNotNull(consentDetails, 'validFrom') && (
					<Row gutter={{ xs: 8, sm: 16 }}>
						<Col xs={12} lg={12}>
							{CONSENT_DETAIL_LABELS.consentCreatedDate}:
						</Col>
						<Col xs={12} lg={12}>
							{formatDateWithoutTime(consentDetails.validFrom)}
						</Col>
					</Row>
				)}
				{isPropertyDefinedAndNotNull(consentDetails, 'validTo') && (
					<Row gutter={{ xs: 8, sm: 16 }}>
						<Col xs={12} lg={12}>
							{CONSENT_DETAIL_LABELS.consentExpiryDate}:
						</Col>
						<Col xs={12} lg={12}>
							{formatDateWithoutTime(consentDetails.validTo)}
						</Col>
					</Row>
				)}
				{createdDate !== undefined && (
					<Row gutter={{ xs: 8, sm: 16 }}>
						<Col xs={12} lg={12}>
							{CONSENT_DETAIL_LABELS.consentRequestDateAndTime}:
						</Col>
						<Col xs={12} lg={12}>
							{formatDateWithTime(createdDate)}
						</Col>
					</Row>
				)}
				{isPropertyDefinedAndNotNull(consentDetails, 'paymentContextCode') && (
					<Row gutter={{ xs: 8, sm: 16 }}>
						<Col xs={12} lg={12}>
							{CONSENT_DETAIL_LABELS.paymentContextCode}:
						</Col>
						<Col xs={12} lg={12}>
							{getPaymentContextCodeByOrdinal(consentDetails.paymentContextCode)}
						</Col>
					</Row>
				)}
				{isPropertyDefinedAndNotNull(consentDetails, 'creditorAccountType') && (
					<Row gutter={{ xs: 8, sm: 16 }}>
						<Col xs={12} lg={12}>
							{CONSENT_DETAIL_LABELS.creditorAccountType}:
						</Col>
						<Col xs={12} lg={12}>
							{getCreditorAccountTypeByOrdinal(consentDetails.creditorAccountType)}
						</Col>
					</Row>
				)}
				{isBooleanValue(consentDetails.isContractPresent) && (
					<Row gutter={{ xs: 8, sm: 16 }}>
						<Col xs={12} lg={12}>
							{CONSENT_DETAIL_LABELS.isContractPresent}:
						</Col>
						<Col xs={12} lg={12}>
							{consentDetails.isContractPresent ? 'True' : 'False'}
						</Col>
					</Row>
				)}
				{isBooleanValue(consentDetails.isCreditorPrePopulated) && (
					<Row gutter={{ xs: 8, sm: 16 }}>
						<Col xs={12} lg={12}>
							{CONSENT_DETAIL_LABELS.isCreditorPrePopulated}:
						</Col>
						<Col xs={12} lg={12}>
							{consentDetails.isCreditorPrePopulated ? 'True' : 'False'}
						</Col>
					</Row>
				)}
				{isPropertyDefinedAndNotNull(consentDetails, 'paymentPurposeCode') && (
					<Row gutter={{ xs: 8, sm: 16 }}>
						<Col xs={12} lg={12}>
							{CONSENT_DETAIL_LABELS.paymentPurposeCode}:
						</Col>
						<Col xs={12} lg={12}>
							{getPaymentPurposeCodeByOrdinal(consentDetails.paymentPurposeCode)}
						</Col>
					</Row>
				)}
			</Col>
		</Row>
	);
};

export default VrpConsentDetails;
