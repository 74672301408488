import { Col, Row } from 'antd';

import getPageTitle, { CONSENT_DETAIL_LABELS } from '../../models/Missions/VrpConsentDetailsType';
import { VrpConsentsGetStartedConsentDetails } from '../../models/Missions/VrpMissionConsent/VrpConsentsGetStartedDetails';
import { AmountLimit } from '../../models/Payments/Vrp/Limits/AmountLimit';
import { formatDateWithoutTime, formatDateWithTime, getLabelForAmountLimit } from '../../utils/VrpConsentUtils';
import ConsentItemDetail from './ConsentItemDetail';

interface VrpConsentsGetStartedDetailsProps {
	data: VrpConsentsGetStartedConsentDetails;
}

const VrpConsentsWithDetails = (props: VrpConsentsGetStartedDetailsProps): JSX.Element => {
	const { createdDate, destinationName, destinationSortCode, destinationAccountNumber, amountLimits, validTo, type } =
		props.data;

	return (
		<Row className="vrp-consent-container">
			<h3>{getPageTitle(type)}</h3>

			<Col xs={24} lg={24} className="vrp-consent-container-items">
				<ConsentItemDetail label={CONSENT_DETAIL_LABELS.destinationName} value={destinationName} />

				<ConsentItemDetail label={CONSENT_DETAIL_LABELS.destinationSortCode} value={destinationSortCode} />

				<ConsentItemDetail
					label={CONSENT_DETAIL_LABELS.destinationAccountNumber}
					value={destinationAccountNumber}
				/>

				{amountLimits.map((amountLimit: AmountLimit) => (
					<ConsentItemDetail
						label={getLabelForAmountLimit(amountLimit)}
						value={`${amountLimit.amount} ${amountLimit.currency}`}
						key={`amountLimit_${amountLimit.limitType}`}
					/>
				))}

				<ConsentItemDetail
					label={CONSENT_DETAIL_LABELS.consentExpiryDate}
					value={`${formatDateWithoutTime(validTo)}`}
				/>

				<ConsentItemDetail
					label={CONSENT_DETAIL_LABELS.consentRequestDateAndTime}
					value={`${formatDateWithTime(createdDate)}`}
				/>
			</Col>
		</Row>
	);
};

export default VrpConsentsWithDetails;
