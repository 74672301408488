import './Licenses.css';

import { ILicense } from '../../models/License/License';
import { MissionTypeCode } from '../../models/Missions/MissionType';
import { missionTokens } from './TokenLicenseTypes';

export const TokenLicense = (props: ILicense) => {
	const nullMissionType = props.missionType === null;

	const missionTokenElement =
		missionTokens[MissionTypeCode[props.missionType as keyof typeof MissionTypeCode]]?.(props) ||
		missionTokens.default(props, nullMissionType);

	return <div className="cl-license">{missionTokenElement}</div>;
};
