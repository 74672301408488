import { AppState, Auth0Provider } from '@auth0/auth0-react';
import React, { PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';

import config from './resources/config';

interface Auth0ProviderWithConfigProps {
	children: React.ReactNode;
}

const Auth0ProviderWithHistory = ({
	children,
}: PropsWithChildren<Auth0ProviderWithConfigProps>): JSX.Element | null => {
	const history = useHistory();
	const { clientId, audience, domain } = config;
	const scopes = process.env.REACT_APP_AUTH_SCOPES;
	const redirectUri = window.location.href;
	const onRedirectCallback = (appState?: AppState) => {
		history.push(appState?.returnTo || window.location.pathname);
	};

	if (!(domain && clientId)) {
		return null;
	}
	return (
		<Auth0Provider
			domain={domain}
			clientId={clientId}
			audience={audience}
			redirectUri={redirectUri}
			scope={scopes}
			onRedirectCallback={onRedirectCallback}
			useRefreshTokens={true}
			cacheLocation="localstorage"
		>
			{children}
		</Auth0Provider>
	);
};
export default Auth0ProviderWithHistory;
