import './style.css';

import { Modal, ModalProps } from 'antd';
import { ReactNode } from 'react';

import CLButton from '../Buttons/CLButton';

interface CLModalInterface extends ModalProps {
	open: boolean;
	title?: string | JSX.Element;
	submitButtonLabel?: string;
	cancelButtonLabel?: string;
	renderFooter?: () => JSX.Element;
	onClose?: () => void;
	onSubmit?: () => void;
	children?: ReactNode | ReactNode[];
	className?: string;
	isScrollable?: boolean;
}

const clModalDefaultProps: CLModalInterface = {
	open: false,
	submitButtonLabel: 'OK',
	cancelButtonLabel: 'Cancel',
	onClose: () => undefined,
	onSubmit: () => undefined,
	className: '',
	isScrollable: false,
};

const CLModal = (props: CLModalInterface): JSX.Element => {
	const onCancel = () => {
		props.onClose();
	};

	const onSubmit = () => {
		props.onSubmit();
	};

	const getIsScrollableClassName = (): string => `${props.isScrollable ? 'cl-scrollable' : 'cl-non-scrollable'}`;

	const renderFooter = (): JSX.Element => {
		if (props.renderFooter) {
			return props.renderFooter();
		}
		return (
			<>
				{props.submitButtonLabel && <CLButton label={props.submitButtonLabel} onClick={onSubmit} />}
				{props.cancelButtonLabel && (
					<CLButton
						label={props.cancelButtonLabel}
						onClick={onCancel}
						className="cl-button-reject"
						wrapperClassName="cl-bank-cancel-button"
					/>
				)}
			</>
		);
	};

	return (
		<Modal
			title={props.title}
			open={props.open}
			className={`cl-modal cl-modal-wraper ${props.className} ${getIsScrollableClassName()}`}
			onCancel={onCancel}
			footer={renderFooter()}
			style={props.style}
			bodyStyle={props.bodyStyle}
		>
			{props.children}
		</Modal>
	);
};

CLModal.defaultProps = clModalDefaultProps;

export default CLModal;
