import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
	jwt?: string;
	userEmail: string;
	antiForgeryToken?: string;
}

const initialState: AuthState = {
	jwt: null,
	userEmail: null,
	antiForgeryToken: null,
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,

	reducers: {
		setJwtToken: (state: AuthState, { payload }: PayloadAction<string>) => {
			state.jwt = payload;
		},

		setUserEmail: (state: AuthState, { payload }: PayloadAction<string>) => {
			state.userEmail = payload;
		},

		setAntiForgeryToken: (state: AuthState, { payload }: PayloadAction<string>) => {
			state.antiForgeryToken = payload;
		},
	},
});

export const { setJwtToken, setUserEmail, setAntiForgeryToken } = authSlice.actions;
