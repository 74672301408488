// import { yupResolver } from '@hookform/resolvers/yup';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Controller, useForm } from 'react-hook-form';
import { Col, Row } from 'reactstrap';

import { CLCheckbox } from '../../../cl-shared-components/Checkbox';
import { CLDatePicker, CLTextInput } from '../../../cl-shared-components/Inputs';
import { IMissionForm } from '../../../models/Missions/MissionForm';
import { AccountTransactionDetails } from '../../../models/Payments/AccountTransactions';
import { DATE_FORMAT, DATE_PLACEHOLDER } from '../../../resources/config';

const AccountsTransactionsForm = (props: IMissionForm<AccountTransactionDetails>): JSX.Element => {
	const {
		control,
		handleSubmit,
		setValue,
		getValues,
		trigger,
		formState: { errors, dirtyFields },
	} = useForm({
		defaultValues: props.data,
		values: props.data,
		mode: 'onChange',
	});

	return (
		<div className="cl-mission-modal-container">
			<form
				ref={(ref: HTMLFormElement): void => {
					props.setRef(ref);
				}}
				onSubmit={handleSubmit(() => {
					props.onSubmit(getValues(), Object.keys(dirtyFields).length > 0);
				})}
			>
				<Row>
					<Col lg={12}>
						<Controller
							name="fetchTransactionsSince"
							control={control}
							render={({ field }): JSX.Element => (
								<CLDatePicker
									{...field}
									label={'Fetch transactions since'}
									picker="date"
									format={DATE_FORMAT}
									placeholder={DATE_PLACEHOLDER}
									errors={errors.fetchTransactionsSince}
								/>
							)}
						/>
					</Col>
				</Row>

				<Row>
					<Col lg={12}>
						<Controller
							name="numberOfAccountsToFetch"
							control={control}
							render={({ field }): JSX.Element => (
								<CLTextInput
									{...field}
									label={'No. of accounts to fetch'}
									errors={errors}
									type="number"
								/>
							)}
						/>
					</Col>
				</Row>

				<Row>
					<Col lg={12}>
						<Controller
							name="numberOfTransactionsToFetch"
							control={control}
							render={({ field }): JSX.Element => (
								<CLTextInput
									{...field}
									label={'No. of transactions to fetch'}
									errors={errors}
									type="number"
								/>
							)}
						/>
					</Col>
				</Row>

				<Row>
					<Col lg={12}>
						<Controller
							name="sourceAccountNumber"
							control={control}
							render={({ field }): JSX.Element => (
								<CLTextInput {...field} label={'Source account number'} errors={errors} type="number" />
							)}
						/>
					</Col>
				</Row>

				<Row>
					<Col lg={6}>
						<Controller
							name="fetchDetails"
							control={control}
							render={({ field }): JSX.Element => (
								<CLCheckbox
									className="cl-sip-schedule-payment"
									{...field}
									label="Fetch Details"
									checked={getValues('fetchDetails')}
									onChange={(event: CheckboxChangeEvent): void => {
										setValue('fetchDetails', event.target.checked, { shouldDirty: true });
										void trigger('fetchDetails');
									}}
								/>
							)}
						/>
					</Col>
				</Row>
			</form>
		</div>
	);
};

export default AccountsTransactionsForm;
