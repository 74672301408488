import { CreateMissionEnvironmentEnum } from './CreateMissionEnvironmentEnum';

export type AisMissionDashboardDetails = {
	missionId: number;
	applications: string;
	environment: string;
	provider: string;
	fetchTransactionsSince?: string;
	numberOfAccountsToFetch?: number;
	numberOfTransactionsToFetch?: number;
	sourceAccountNumber?: number;
};

export enum AIS_DETAIL_LABELS {
	application = 'Application',
	environment = 'Environment',
	provider = 'Provider',
	fetchTransactionsSince = 'Fetch transactions since',
	numberOfAccountsToFetch = 'No.of accounts to fetch',
	numberOfTransactionsToFetch = 'No.of transactions to fetch',
	sourceAccountNumber = 'Source account number',
}

export const convertEnvironmentEnumToString = (environment: string): string => {
	let environmentName = '';
	Object.entries(CreateMissionEnvironmentEnum).map(([key, value]) => {
		if (Number(value) === Number(environment)) {
			environmentName = key;
		}
		return null;
	});
	return environmentName;
};
