import React from 'react';

import AdminMissionProvidersTable from './AdminMissionProvidersTable';

export default function AdminMissionResultsPage(): JSX.Element {
	return (
		<div>
			<AdminMissionProvidersTable />
		</div>
	);
}
