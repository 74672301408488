export type BOOLEAN_STRING_TYPE = 'true' | 'false';

type BooleanMap = { [KEY in BOOLEAN_STRING_TYPE]: string };

export enum CREDITOR_ACCOUNT_TYPE {
	Personal = 'Personal',
	JointPersonal = 'JointPersonal',
	PersonalSavingsAccount = 'PersonalSavingsAccount',
	Business = 'Business',
	BusinessSavingsAccount = 'BusinessSavingsAccount',
	Charity = 'Charity',
	Collection = 'Collection',
	Corporate = 'Corporate',
	Government = 'Government',
	Ewallet = 'Ewallet',
	Investment = 'Investment',
	ISA = 'ISA',
	Premier = 'Premier',
	Wealth = 'Wealth',
	Pension = 'Pension',
}

export enum PAYMENT_PURPOSE_CODE {
	BONU = 'Bonus Payment',
	CASH = 'Cash Management Transfer',
	CBLK = 'Card Bulk Clearing',
	CCRD = 'Credit Card Payment',
	DCRD = 'Debit Card Payment',
	DIVI = 'Dividend',
	FCOL = 'Fee Collection',
	GOVT = 'Government Payment',
	GP2P = 'Person to Person Payment',
	INTC = 'Intra Company Payment',
	INTE = 'Interest',
	LOAN = 'Loan',
	MP2P = 'Consumer',
	OTHR = 'Other Payment',
	PENS = 'Pension Payment',
	RRCT = 'Reinbursement Received Credit Transfer',
	SALA = 'Salary Payment',
	SSBE = 'Social Security Benefit',
	SUPP = 'Supplier Payment',
	TAXS = 'Treasury Payment',
	VATX = 'Value Added Tax Payment',
	WHLD = 'With Holding',
}

export const BOOLEAN_VALUES: BooleanMap = {
	true: 'Yes',
	false: 'No',
};

export enum PAYMENT_CONTEXT_CODE {
	BillPayment = 'BillPayment',
	EcommerceGoods = 'EcommerceGoods',
	EcommerceServices = 'EcommerceServices',
	Other = 'Other',
	PartyToParty = 'PartyToParty',
	BillingGoodsAndServicesInAdvance = 'BillingGoodsAndServicesInAdvance',
	BillingGoodsAndServicesInArrears = 'BillingGoodsAndServicesInArrears',
	PispPayee = 'PispPayee',
	EcommerceMerchantInitiatedPayment = 'EcommerceMerchantInitiatedPayment',
	FaceToFacePointOfSale = 'FaceToFacePointOfSale',
	TransferToSelf = 'TransferToSelf',
	TransferToThirdParty = 'TransferToThirdParty',
}
