import { RenderConditions } from './interfaces';

export const renderConditions: RenderConditions = {
	DE: {
		Default: {
			row1: {
				fields: {
					amount: { isVisible: true },
					currency: { isVisible: true },
				},
			},
			row2: {
				fields: {
					schedulePayment: { isVisible: true },
					executionDate: { isVisible: true },
				},
			},
			row3: {
				fields: {
					referenceInformation: { isVisible: true },
				},
			},
			row4: {
				fields: {
					endToEndId: { isVisible: false },
				},
			},
			row5: {
				fields: {
					destinationId: { isVisible: true },
				},
			},
			row6: {
				fields: {
					paymentContextCode: { isVisible: false },
					paymentPurposeCode: { isVisible: false },
				},
			},
			row7: {
				fields: {
					thirdPartyIdentifier: { isVisible: false },
					thirdPartyAddress: { isVisible: false },
				},
			},
			row8: {
				fields: {
					countryCode: { isVisible: true },
					townName: { isVisible: true },
				},
			},
			row9: {
				fields: {
					thirdPartyType: { isVisible: false },
				},
			},
		},
	},
	DK: {
		Default: {
			row1: {
				fields: {
					amount: { isVisible: true },
					currency: { isVisible: true },
				},
			},
			row2: {
				fields: {
					schedulePayment: { isVisible: true },
					executionDate: { isVisible: true },
				},
			},
			row3: {
				fields: {
					referenceInformation: { isVisible: true },
				},
			},
			row4: {
				fields: {
					endToEndId: { isVisible: false },
				},
			},
			row5: {
				fields: {
					destinationId: { isVisible: true },
				},
			},
			row6: {
				fields: {
					paymentContextCode: { isVisible: false },
					paymentPurposeCode: { isVisible: false },
				},
			},
			row7: {
				fields: {
					thirdPartyIdentifier: { isVisible: false },
					thirdPartyAddress: { isVisible: false },
				},
			},
			row8: {
				fields: {
					countryCode: { isVisible: true },
					townName: { isVisible: true },
				},
			},
			row9: {
				fields: {
					thirdPartyType: { isVisible: false },
				},
			},
		},
	},
	NO: {
		Default: {
			row1: {
				fields: {
					amount: { isVisible: true },
					currency: { isVisible: true },
				},
			},
			row2: {
				fields: {
					schedulePayment: { isVisible: true },
					executionDate: { isVisible: true },
				},
			},
			row3: {
				fields: {
					referenceInformation: { isVisible: true },
				},
			},
			row4: {
				fields: {
					endToEndId: { isVisible: false },
				},
			},
			row5: {
				fields: {
					destinationId: { isVisible: true },
				},
			},
			row6: {
				fields: {
					paymentContextCode: { isVisible: false },
					paymentPurposeCode: { isVisible: false },
				},
			},
			row7: {
				fields: {
					thirdPartyIdentifier: { isVisible: false },
					thirdPartyAddress: { isVisible: false },
				},
			},
			row8: {
				fields: {
					countryCode: { isVisible: true },
					townName: { isVisible: true },
				},
			},
			row9: {
				fields: {
					thirdPartyType: { isVisible: false },
				},
			},
		},
	},
	SE: {
		Default: {
			row1: {
				fields: {
					amount: { isVisible: true },
					currency: { isVisible: true },
				},
			},
			row2: {
				fields: {
					schedulePayment: { isVisible: true },
					executionDate: { isVisible: true },
				},
			},
			row3: {
				fields: {
					referenceInformation: { isVisible: true },
				},
			},
			row4: {
				fields: {
					endToEndId: { isVisible: false },
				},
			},
			row5: {
				fields: {
					destinationId: { isVisible: true },
				},
			},
			row6: {
				fields: {
					paymentContextCode: { isVisible: false },
					paymentPurposeCode: { isVisible: false },
				},
			},
			row7: {
				fields: {
					thirdPartyIdentifier: { isVisible: false },
					thirdPartyAddress: { isVisible: false },
				},
			},
			row8: {
				fields: {
					countryCode: { isVisible: true },
					townName: { isVisible: true },
				},
			},
			row9: {
				fields: {
					thirdPartyType: { isVisible: false },
				},
			},
		},
	},
	AT: {
		Default: {
			row1: {
				fields: {
					amount: { isVisible: true },
					currency: { isVisible: true },
				},
			},
			row2: {
				fields: {
					schedulePayment: { isVisible: true },
					executionDate: { isVisible: true },
				},
			},
			row3: {
				fields: {
					referenceInformation: { isVisible: true },
				},
			},
			row4: {
				fields: {
					endToEndId: { isVisible: false },
				},
			},
			row5: {
				fields: {
					destinationId: { isVisible: true },
				},
			},
			row6: {
				fields: {
					paymentContextCode: { isVisible: false },
					paymentPurposeCode: { isVisible: false },
				},
			},
			row7: {
				fields: {
					thirdPartyIdentifier: { isVisible: false },
					thirdPartyAddress: { isVisible: false },
				},
			},
			row8: {
				fields: {
					countryCode: { isVisible: true },
					townName: { isVisible: true },
				},
			},
			row9: {
				fields: {
					thirdPartyType: { isVisible: false },
				},
			},
		},
	},
	Default: {
		row1: {
			fields: {
				amount: { isVisible: true },
				currency: { isVisible: true },
			},
		},
		row2: {
			fields: {
				schedulePayment: { isVisible: true },
				executionDate: { isVisible: true },
			},
		},
		row3: {
			fields: {
				referenceInformation: { isVisible: true },
			},
		},
		row4: {
			fields: {
				endToEndId: { isVisible: true },
			},
		},
		row5: {
			fields: {
				destinationId: { isVisible: true },
			},
		},
		row6: {
			fields: {
				paymentContextCode: { isVisible: true },
				paymentPurposeCode: { isVisible: true },
			},
		},
		row7: {
			fields: {
				thirdPartyIdentifier: { isVisible: true },
				thirdPartyAddress: { isVisible: true },
			},
		},
		row8: {
			fields: {
				countryCode: { isVisible: true },
				townName: { isVisible: true },
			},
		},
		row9: {
			fields: {
				thirdPartyType: { isVisible: true },
			},
		},
	},
};
