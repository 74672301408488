import './AISMissionDetails.css';

import { Col, Row } from 'antd';

import {
	AIS_DETAIL_LABELS,
	AisMissionDashboardDetails,
	convertEnvironmentEnumToString,
} from '../../../../../models/Missions/AisMissionDashboardDetailsType';
import { datePickerFormateDate } from '../../../../../utils/AISUtils';

interface AisMissionDetailsProps {
	data: AisMissionDashboardDetails | null;
}

const AISMissionDetails = (props: AisMissionDetailsProps): JSX.Element => {
	const { data } = props;

	return (
		<>
			<Row className="cl-consent-details-container ais-row">
				<Col xs={24} lg={24}>
					<h6>Mission details</h6>
				</Col>
				<Row className="ais-container">
					<Col xs={24} lg={24} className="cl-consent-details ais-cell">
						<Row gutter={{ xs: 8, sm: 16 }}>
							<Col xs={12} lg={12}>
								{AIS_DETAIL_LABELS.application}:
							</Col>
							<Col xs={12} lg={12}>
								{data.applications}
							</Col>
						</Row>
					</Col>
					<Col xs={24} lg={24} className="cl-consent-details ais-cell">
						<Row gutter={{ xs: 8, sm: 16 }}>
							<Col xs={12} lg={12}>
								{AIS_DETAIL_LABELS.environment}:
							</Col>
							<Col xs={12} lg={12}>
								{convertEnvironmentEnumToString(data.environment)}
							</Col>
						</Row>
					</Col>
					<Col xs={24} lg={24} className="cl-consent-details ais-cell">
						<Row gutter={{ xs: 8, sm: 16 }}>
							<Col xs={12} lg={12}>
								{AIS_DETAIL_LABELS.provider}:
							</Col>
							<Col xs={12} lg={12}>
								{data.provider}
							</Col>
						</Row>
					</Col>
					<Col xs={24} lg={24} className="cl-consent-details ais-cell">
						<Row gutter={{ xs: 8, sm: 16 }}>
							<Col xs={12} lg={12}>
								{AIS_DETAIL_LABELS.fetchTransactionsSince}:
							</Col>
							<Col xs={12} lg={12}>
								{data.fetchTransactionsSince
									? datePickerFormateDate(data.fetchTransactionsSince)
									: 'NA'}
							</Col>
						</Row>
					</Col>
					<Col xs={24} lg={24} className="cl-consent-details ais-cell">
						<Row gutter={{ xs: 8, sm: 16 }}>
							<Col xs={12} lg={12}>
								{AIS_DETAIL_LABELS.numberOfAccountsToFetch}:
							</Col>
							<Col xs={12} lg={12}>
								{data.numberOfAccountsToFetch || 'NA'}
							</Col>
						</Row>
					</Col>
					<Col xs={24} lg={24} className="cl-consent-details ais-cell">
						<Row gutter={{ xs: 8, sm: 16 }}>
							<Col xs={12} lg={12}>
								{AIS_DETAIL_LABELS.numberOfTransactionsToFetch}:
							</Col>
							<Col xs={12} lg={12}>
								{data.numberOfTransactionsToFetch || 'NA'}
							</Col>
						</Row>
					</Col>
					<Col xs={24} lg={24} className="cl-consent-details ais-cell">
						<Row gutter={{ xs: 8, sm: 16 }}>
							<Col xs={12} lg={12}>
								{AIS_DETAIL_LABELS.sourceAccountNumber}:
							</Col>
							<Col xs={12} lg={12}>
								{data.sourceAccountNumber || 'NA'}
							</Col>
						</Row>
					</Col>
				</Row>
			</Row>
		</>
	);
};

export default AISMissionDetails;
