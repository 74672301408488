export const CORE_ID = 0;
export const APV3_ID = 1;
export const ENTERPRISE_ID = 2;

export type applicationIdType = typeof CORE_ID | typeof APV3_ID | typeof ENTERPRISE_ID;

export type ApplicationLayerType = {
	name?: string;
	id?: number;
};

export const applicationLayerTypes: ApplicationLayerType[] = [
	{
		name: 'Core',
		id: 0,
	},
	{
		name: 'APv3 Platform',
		id: 1,
	},
	{
		name: 'Enterprise',
		id: 2,
	},
];

export const [Core, APv3, Enterprise] = applicationLayerTypes;
