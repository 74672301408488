import { ObjectSchema } from 'yup';

import { AustrianSepaCreditTransferValidation } from '../../YupSchemaValidation/AustrianSepaCreditTransferValidation';
import { DanishDomesticCreditTransferValidation } from '../../YupSchemaValidation/DanishDomesticCreditTransferValidation';
import { GermanSepaCreditTransferValidation } from '../../YupSchemaValidation/GermanSepaCreditTransferValidation';
import { NorwegianDomesticCreditTransferValidation } from '../../YupSchemaValidation/NorwegianDomesticCreditTransferValidation';
import { SwedishDomesticCreditTransferLegacyValidation } from '../../YupSchemaValidation/SwedishDomesticCreditTransferLegacyValidation';

export const ValidationMapper: Record<string, ObjectSchema<Record<string, any>>> = {
	DK: DanishDomesticCreditTransferValidation,
	NO: NorwegianDomesticCreditTransferValidation,
	SE: SwedishDomesticCreditTransferLegacyValidation,
	DE: GermanSepaCreditTransferValidation,
	AT: AustrianSepaCreditTransferValidation,
};
