import { Link } from 'react-router-dom';

interface Props {
	visible: boolean;
}

const Footer = (props: Props): JSX.Element => (
	<>
		{props.visible && (
			<footer className="container">
				<div className="cl-footer">
					<div className="cl-footer-info">
						Aiia A/S is a licensed Payment Initiation Service Provider (PISP) and Account Information
						<br />
						Service Provider (AISP) and operates under the supervision of the Danish Financial Supervisory
						Authority.
					</div>
					<div className="cl-footer-contact">
						<span>Contact us</span>
						<br />
						<Link to="/privacy-policy">Privacy policy</Link>
					</div>
				</div>
			</footer>
		)}
	</>
);

export default Footer;
