import './UnapprovedTestersTable.css';

import { ColumnDef, Table } from '@tanstack/react-table';
import { Empty, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CLButton } from '../../cl-shared-components/Buttons';
import { CLCheckbox } from '../../cl-shared-components/Checkbox';
import GenericTableWithPagination from '../../cl-shared-components/GenericTable/GenericTableWithPagination';
import confirm from '../../cl-shared-components/Modal/GenericModal';
import Spinner from '../../cl-shared-components/Spinner/Spinner';
import {
	DisplayErrorToaster,
	DisplaySuccessToaster,
	DisplayWarningToaster,
} from '../../cl-shared-components/Toaster/DisplayToaster';
import useAxios from '../../hooks/axios';
import { PageTitleContext } from '../../layout/Layout';
import { ErrorDetails } from '../../models/HttpResponse/ErrorDetails';
import StatusCodes from '../../models/HttpResponse/StatusCodes';
import { DeleteTesterStatusRequest } from '../../models/Tester/DeleteTesterStatusRequest';
import { Tester } from '../../models/Tester/Tester';
import TesterStatus from '../../models/Tester/TesterStatus';
import { UpdateTestersStatusRequest } from '../../models/Tester/UpdateTestersStatusRequest';
import ApproveRejectButtonsText from '../../models/ui/ApproveRejectButtonsText';
import { dateFormatWithTime, pageTitles } from '../../resources/config';
import { confirmRejectTesterMessage } from '../../resources/Confirm';
import { noUsersSelectedMessage } from '../../resources/Errors';
import { approvedTesterMessage, rejectedTesterMessage } from '../../resources/Success';
import { RootState } from '../../store';
import { setAuth0UserIds } from '../../store/reducers/testerReducer';

export default function UnapprovedTestersTable() {
	const { setPageTitle } = useContext(PageTitleContext);
	const [testerData, setTesterData] = useState<Tester[]>([]);
	const [table, setTable] = useState<Table<Tester>>();
	const [rowSelection, setRowSelection] = useState({});
	const dispatch = useDispatch();
	const postData = useAxios({});
	const deleteData = useAxios({});

	const columns = React.useMemo<ColumnDef<Tester>[]>(
		() => [
			{
				header: 'Tester Name',
				enableSorting: false,
				cell: ({ row, getValue }) => (
					<div>
						<>
							<CLCheckbox
								wrapperClassName="cl-checkbox-grid"
								onChange={(event: CheckboxChangeEvent) => {
									row.toggleSelected(event.target?.checked);
								}}
							/>
							{getValue()}
						</>
					</div>
				),
				footer: (props) => props.column.id,
				accessorFn: (row) => row.name,
				id: 'testerName',
			},
			{
				header: 'Onboarding Date',
				footer: (props) => props.column.id,
				accessorFn: (row) => moment(row.createdDate).format(dateFormatWithTime.format),
				id: 'OnboardingDate',
			},
		],
		[]
	);
	const testerStatus = TesterStatus.Awaiting_Approval;
	const auth0UserIds = useSelector((state: RootState) => state.tester.auth0UserIds);

	const initializeDeleteRequestData = (): DeleteTesterStatusRequest => ({
		auth0UserIds,
	});

	const initializePostRequestData = (status: string): UpdateTestersStatusRequest => ({
		auth0UserIds,
		status,
	});
	const {
		response,
		loading,
		sendData,
	}: { response: AxiosResponse<Tester[]>; loading: boolean; sendData: () => void } = useAxios({
		method: 'GET',
		url: `/api/testers/status?status=${testerStatus}`,
	});
	const bulkDeleteTesters = async () => {
		const data = initializeDeleteRequestData();
		try {
			const deleteDataResult = (await deleteData.fetchData({
				method: 'delete',
				url: '/api/testers',
				data,
			})) as AxiosResponse;

			if (deleteDataResult.status === StatusCodes.BAD_REQUEST) {
				const errorDetails: ErrorDetails = deleteDataResult.data as ErrorDetails;
				DisplayWarningToaster(errorDetails.detail);
			} else {
				DisplaySuccessToaster(rejectedTesterMessage);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleReject = async () => {
		await bulkDeleteTesters();
		sendData();
		table.resetRowSelection();
		dispatch(setAuth0UserIds({ auth0UserIds: [] }));
	};
	const showConfirm = () => {
		if (auth0UserIds?.length === 0 || auth0UserIds === undefined) {
			DisplayErrorToaster(noUsersSelectedMessage);
		} else {
			confirm(confirmRejectTesterMessage, handleReject);
		}
	};

	const bulkTestersStatusUpdate = async (status: string) => {
		const data = initializePostRequestData(status);
		try {
			const postDataResult = (await postData.fetchData({
				method: 'post',
				url: '/api/testers/bulk-testers-status-update',
				data,
			})) as AxiosResponse;

			if (postDataResult.status === StatusCodes.BAD_REQUEST) {
				const errorDetails: ErrorDetails = postDataResult.data as ErrorDetails;
				DisplayWarningToaster(errorDetails.detail);
			} else {
				DisplaySuccessToaster(approvedTesterMessage);
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		const affectedAuth0UserIds = table?.getSelectedRowModel().rows.map(({ original }) => original.auth0UserId);
		dispatch(setAuth0UserIds({ auth0UserIds: affectedAuth0UserIds }));
	}, [rowSelection, table, dispatch]);

	useEffect(() => {
		setPageTitle(pageTitles.adminApprovePage);
		if (response !== undefined) {
			setTesterData(response.data);
		}
	}, [response]);

	const handleApprove = async () => {
		if (auth0UserIds?.length === 0 || auth0UserIds === undefined) {
			DisplayErrorToaster(noUsersSelectedMessage);
		} else {
			const status = TesterStatus.Approved;
			await bulkTestersStatusUpdate(status);
			sendData();
			table.resetRowSelection();
			dispatch(setAuth0UserIds({ auth0UserIds: [] }));
		}
	};

	if (loading) return <Spinner />;

	return (
		<div>
			{testerData.length > 0 ? (
				<GenericTableWithPagination
					onTableInitialization={(tableValue) => setTable(tableValue)}
					onRowSelectionChanging={(rowSelectionValue) => setRowSelection(rowSelectionValue)}
					{...{ data: testerData, columns }}
					defaultSortBy={[{ id: 'testerName', desc: false }]}
				/>
			) : (
				<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
			)}

			<Space wrap className="cl-actions-wrapper">
				<CLButton label={ApproveRejectButtonsText.APPROVE} onClick={handleApprove} />
				<CLButton label={ApproveRejectButtonsText.REJECT} onClick={showConfirm} className="cl-button-reject" />
			</Space>
		</div>
	);
}
