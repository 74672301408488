import { Alert } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { CLButton } from '../../cl-shared-components/Buttons';
import Spinner from '../../cl-shared-components/Spinner/Spinner';
import useAxios from '../../hooks/axios';
import { PageTitleContext } from '../../layout/Layout';
import { AdminMissionTypeDisplay } from '../../models/AdminMissions/AdminMissionType';
import { ErrorDetails } from '../../models/HttpResponse/ErrorDetails';
import StatusCodes from '../../models/HttpResponse/StatusCodes';
import { Area, NonUk, Uk } from '../../models/Missions/Area';
import { MISSION_STATUS_FAILED } from '../../models/Missions/MissionStatus';
import { SipGetStartedDetails } from '../../models/Missions/SipGetStartedDetails';
import {
	SingleImmediatePaymentResponse,
	UpdateMissionStatusPayload,
} from '../../models/Payments/SingleImmediatePayment';
import { AlertStatusInterface } from '../../models/ui/Alert';
import { pageTitles } from '../../resources/config';
import { PAYMENT_FAIL_MESSAGE } from '../../resources/Errors';
import { License } from '../Licenses';
import SipsWithDetails from './SingleImmediatePaymentGetStartedDetails';

const SingleImmediatePaymentGetStarted = (): JSX.Element => {
	const { fetchData } = useAxios({});
	const { missionId } = useParams<{ missionId: string }>();
	const { setPageTitle } = useContext(PageTitleContext);
	const [consentDetails, setConsentDetails] = useState<SipGetStartedDetails>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [missionStatus, setMissionStatus] = useState<AlertStatusInterface>({
		type: 'success',
		message: '',
	});
	const [area, setArea] = useState<Area>();

	const getMission = async (): Promise<void> => {
		setIsLoading(true);
		const result = (await fetchData({
			method: 'GET',
			url: `api/missions/${missionId}/sip/details`,
			headers: { accept: '*/*' },
		})) as AxiosResponse<SipGetStartedDetails> | AxiosError<Error>;

		if ('data' in result) {
			const consentDetailsDb = result?.data;
			setConsentDetails(consentDetailsDb);
			setArea(consentDetailsDb.countryCode === 'GB' ? Uk : NonUk);
		} else {
			setMissionStatus({ type: 'error', message: result.response?.data?.message });
		}

		setIsLoading(false);
	};

	useEffect(() => {
		setPageTitle(pageTitles.missionStart);
		getMission().catch((err) => console.error(err));
	}, []);

	const updateMissionStatus = async (payload: UpdateMissionStatusPayload): Promise<AxiosResponse | AxiosError> =>
		(await fetchData({
			method: 'post',
			url: 'api/missions/status',
			data: payload,
		})) as AxiosResponse | AxiosError;

	const onStart = async (): Promise<void> => {
		setIsLoading(true);
		const payload = {
			missionId,
			endUserContext: {
				language: navigator.language,
				userAgent: navigator.userAgent,
				deviceUuid: uuidv4(),
			},
		};

		try {
			const result = (await fetchData({
				method: 'post',
				url: 'api/apv3/sip/execute',
				data: payload,
			})) as AxiosResponse<SingleImmediatePaymentResponse> | AxiosError<ErrorDetails>;
			setIsLoading(false);
			if (result.status === StatusCodes.OK) {
				const response = result as AxiosResponse<SingleImmediatePaymentResponse>;
				const { flowUrl } = response.data;

				window.location.replace(flowUrl);
			} else {
				const error = result as AxiosError<ErrorDetails>;
				throw Error(error.response.data.detail || PAYMENT_FAIL_MESSAGE);
			}
		} catch (error) {
			const errorObject = error as Error;
			setMissionStatus({ type: 'error', message: errorObject.message });
			const missionStatusPayload: UpdateMissionStatusPayload = {
				missionId: parseInt(missionId),
				missionStatus: MISSION_STATUS_FAILED,
			};
			await updateMissionStatus(missionStatusPayload);
			setIsLoading(false);
		}
	};

	return (
		<>
			{isLoading && <Spinner />}

			{missionStatus.message && <Alert message={missionStatus.message} type={missionStatus.type} />}

			{!missionStatus.message && !isLoading && consentDetails && (
				<>
					<SipsWithDetails data={consentDetails} />

					<p className="cl-uk-faster-paragraph">
						Please click on the bellow button to initiate selected{' '}
						{AdminMissionTypeDisplay[consentDetails.type as keyof typeof AdminMissionTypeDisplay]} mission.
					</p>
					{
						<License
							area={area?.value}
							buttonPosition="bellow"
							missionType={consentDetails.type}
							displayName={consentDetails.displayName}
						/>
					}
					<CLButton className="cl-button-start" label="Start" onClick={onStart} />
				</>
			)}
		</>
	);
};

export default SingleImmediatePaymentGetStarted;
