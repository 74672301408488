import { useContext, useEffect } from 'react';

import { PageTitleContext } from '../../layout/Layout';
import { pageTitles } from '../../resources/config';

export default function NotFound() {
	const { setPageTitle } = useContext(PageTitleContext);

	useEffect(() => {
		setPageTitle(pageTitles.notFound);
	});

	return <></>;
}
