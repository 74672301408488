import { Col, Row } from 'antd';

import { UKFP_DETAIL_LABELS, UkfpGetStartedDetails } from '../../models/Missions/UkfpGetStartedDetails';
import ConsentItemDetail from '../VrpConsent/ConsentItemDetail';

interface UkfpGetStartedDetailsProps {
	data: UkfpGetStartedDetails;
}

const UkfpsWithDetails = (props: UkfpGetStartedDetailsProps): JSX.Element => {
	const { amount, currency, creditorSortCode, creditorAccountNumber, referenceInformation } = props.data;

	return (
		<Row className="vrp-consent-container">
			<h3>{UKFP_DETAIL_LABELS.title}</h3>

			<Col xs={24} lg={24} className="vrp-consent-container-items">
				<ConsentItemDetail label={UKFP_DETAIL_LABELS.amount} value={`${amount} ${currency}`} />

				<ConsentItemDetail
					label={UKFP_DETAIL_LABELS.destination}
					value={`${creditorSortCode}-${creditorAccountNumber}`}
				/>

				<ConsentItemDetail label={UKFP_DETAIL_LABELS.message} value={referenceInformation} />
			</Col>
		</Row>
	);
};

export default UkfpsWithDetails;
