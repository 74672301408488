import { Alert } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Spinner from '../../cl-shared-components/Spinner/Spinner';
import useAxios from '../../hooks/axios';
import { PageTitleContext } from '../../layout/Layout';
import { ErrorDetails } from '../../models/HttpResponse/ErrorDetails';
import StatusCodes from '../../models/HttpResponse/StatusCodes';
import { MISSION_STATUS_COMPLETED, MISSION_STATUS_FAILED } from '../../models/Missions/MissionStatus';
import {
	ExecutePaymentPayload,
	PaymentAuthorization,
	UpdateMissionStatusPayload,
} from '../../models/Payments/UkFasterPayment';
import { AlertStatusInterface } from '../../models/ui/Alert';
import { pageTitles } from '../../resources/config';
import {
	BANK_RESPONSE_ERROR_DESCRIPTION_KEY,
	PAYMENT_FAIL_MESSAGE,
	PAYMENT_ROUTING_ERRORS,
} from '../../resources/Errors';
import { PAYMENT_SUCCESS_MESSAGE } from '../../resources/Success';
import getNagCoreEnvironmentValue from '../../utils/EnvUtils';

const UKFasterPaymentsStageRouting = (): JSX.Element => {
	const { setPageTitle } = useContext(PageTitleContext);
	const { fetchData } = useAxios({});
	const { search, hash } = useLocation();

	const getHashParameters = (): URLSearchParams => new URLSearchParams(hash ? hash.replace('#', '') : search);

	const hashParameters = getHashParameters();

	const [paymentStatus, setPaymentStatus] = useState<AlertStatusInterface>({
		type: 'success',
		message: '',
	});
	const [isLoading, setIsLoading] = useState(false);

	const getPaymentTokens = async (): Promise<AxiosResponse<PaymentAuthorization> | AxiosError<ErrorDetails>> => {
		const payload = {
			code: hashParameters.get('code'),
			idToken: hashParameters.get('id_token'),
			state: hashParameters.get('state'),
		};

		return (await fetchData({
			method: 'post',
			url: 'api/stagerouting',
			params: payload,
		})) as AxiosResponse<PaymentAuthorization> | AxiosError<ErrorDetails>;
	};

	const executePayment = async (payload: ExecutePaymentPayload): Promise<AxiosResponse | AxiosError> =>
		(await fetchData({
			method: 'post',
			url: 'api/ukfasterpayments/execute',
			data: payload,
		})) as AxiosResponse | AxiosError;

	const updateMissionStatus = async (payload: UpdateMissionStatusPayload): Promise<AxiosResponse | AxiosError> =>
		(await fetchData({
			method: 'post',
			url: 'api/missions/status',
			data: payload,
		})) as AxiosResponse | AxiosError;

	const handleMissionStatusUpdate = async (missionStatus: number): Promise<void> => {
		const missionStatusPayload: UpdateMissionStatusPayload = {
			missionId: parseInt(hashParameters.get('state')),
			missionStatus,
		};
		const { status } = await updateMissionStatus(missionStatusPayload);
		const isMissionRequestOk = status === StatusCodes.OK;

		if (missionStatus === MISSION_STATUS_FAILED || !isMissionRequestOk) {
			throw Error(PAYMENT_FAIL_MESSAGE);
		}

		setPaymentStatus({ type: 'success', message: PAYMENT_SUCCESS_MESSAGE });
	};

	const getPaymentAuthorizationTokens = async () => {
		const stageResponse = await getPaymentTokens();

		if (stageResponse.status !== StatusCodes.OK) {
			const stageResponseError = stageResponse as AxiosError<ErrorDetails>;
			if (stageResponseError) {
				const { data } = stageResponseError.response;
				throw Error(data?.detail || stageResponseError.message);
			}
		}
		const tokens = stageResponse as AxiosResponse<PaymentAuthorization>;
		const isVrpType = tokens.data.paymentToken === null;
		return { tokens, isVrpType };
	};

	const executePaymentHandler = async (tokens: AxiosResponse<PaymentAuthorization>) => {
		const paymentPayload: ExecutePaymentPayload = {
			environment: getNagCoreEnvironmentValue(),
			payment: tokens.data.paymentToken,
			session: tokens.data.sessionToken,
		};
		const { status } = await executePayment(paymentPayload);
		const missionStatus = status === StatusCodes.OK ? MISSION_STATUS_COMPLETED : MISSION_STATUS_FAILED;

		await handleMissionStatusUpdate(missionStatus);
	};

	const handleStageRoutingSuccessResponse = async () => {
		try {
			setIsLoading(true);
			const { tokens, isVrpType } = await getPaymentAuthorizationTokens();

			if (isVrpType) {
				await handleMissionStatusUpdate(MISSION_STATUS_COMPLETED);
			} else {
				await executePaymentHandler(tokens);
			}
		} catch (error) {
			const errorObject = error as Error;
			setPaymentStatus({ type: 'error', message: errorObject.message || PAYMENT_FAIL_MESSAGE });
		} finally {
			setIsLoading(false);
		}
	};

	const handleStageRoutingError = async (errorType: string): Promise<void> => {
		let message = PAYMENT_FAIL_MESSAGE;

		if (hashParameters.get(BANK_RESPONSE_ERROR_DESCRIPTION_KEY)) {
			message = hashParameters.get(BANK_RESPONSE_ERROR_DESCRIPTION_KEY);
		} else if (errorType) {
			message = PAYMENT_ROUTING_ERRORS[errorType];
		}

		setPaymentStatus({
			type: 'error',
			message,
		});

		try {
			const missionStatusPayload: UpdateMissionStatusPayload = {
				missionId: parseInt(hashParameters.get('state')),
				missionStatus: MISSION_STATUS_FAILED,
			};
			await updateMissionStatus(missionStatusPayload);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		setPageTitle(pageTitles.paymentAuthorization);
		const errorType = hashParameters.get('error');
		const load = async (): Promise<void> => {
			if (errorType) {
				await handleStageRoutingError(errorType);
			} else {
				await handleStageRoutingSuccessResponse();
			}
		};
		void load();
	}, []);

	return (
		<>
			{isLoading && (
				<div className="spinnerWrapper">
					<Spinner />
				</div>
			)}
			{paymentStatus.message && <Alert message={paymentStatus.message} type={paymentStatus.type} />}
		</>
	);
};

export default UKFasterPaymentsStageRouting;
