import './BankModal.css';

import { Col, Empty, Form, Input, Modal, Row, Select, Spin } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { AxiosError, AxiosResponse } from 'axios';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { CLButton } from '../../cl-shared-components/Buttons';
import { DisplayErrorToaster, DisplaySuccessToaster } from '../../cl-shared-components/Toaster/DisplayToaster';
import useAxios from '../../hooks/axios';
import { Bank } from '../../models/Bank/Bank';
import { Country } from '../../models/Countries/Country';
import { ErrorDetails } from '../../models/HttpResponse/ErrorDetails';
import StatusCodes from '../../models/HttpResponse/StatusCodes';
import { BBAN_VALID_LENGTH_GB, COUNTRY_CODE_GB, IBAN_VALID_LENGTH_GB } from '../../resources/config';
import {
	BANK_REQUIRED,
	BBAN_IBAN_NOT_MATCH,
	BBAN_INVALID_CHARS,
	BBAN_INVALID_LENGTH,
	IBAN_INVALID_CHARS,
	IBAN_INVALID_LENGTH,
	IBAN_OR_BBAN_REQUIRED,
	MARKET_REQUIRED,
} from '../../resources/Errors';
import { bankAdded } from '../../resources/Success';

interface Props {
	isVisible: boolean;
	close: () => void;
	updateTable: () => void;
}

const BankModal = (props: Props): ReactElement => {
	const { Option } = Select;
	const [form] = Form.useForm();
	const {
		setFieldsValue,
		getFieldValue,
		validateFields,
		resetFields,
	}: {
		setFieldsValue(fields: Record<string, string | [] | object>): void;
		getFieldValue(name: NamePath): string;
		validateFields(nameList?: NamePath[]): Promise<void>;
		resetFields(fields?: NamePath[]): void;
	} = form;
	const [banks, setBanks] = useState<Bank[]>([]);
	const [markets, setMarkets] = useState<Country[]>([]);
	const [selectedBank, setSelectedBank] = useState<Bank>();
	const [fetchingBanks, setFetchingBanks] = useState<boolean>(false);
	const getProvidersByCountryCode = useAxios({});

	const { response: marketsResponse, error: marketsError }: { response: AxiosResponse<Country[]>; error: Error } =
		useAxios({
			method: 'GET',
			url: '/api/countries',
			headers: {
				accept: '*/*',
			},
		});

	const { fetchData } = useAxios({});
	const cancel = (): void => {
		props.close();
		resetFields();
		setBanks([]);
	};

	const addBank = async (): Promise<void> => {
		let errorFields: [] = [];
		try {
			await validateFields(['market', 'bank', 'iban', 'bban']);
		} catch (error) {
			const errorAssert: Record<string, []> = error as Record<string, []>;
			errorFields = errorAssert.errorFields;
		}

		if (errorFields.length) {
			return;
		}

		try {
			const data: Bank = selectedBank;
			data.iban = getFieldValue('iban');
			data.bban = getFieldValue('bban');

			const result = (await fetchData({
				method: 'post',
				url: '/api/testerproviders',
				data,
			})) as AxiosResponse<Bank> | AxiosError<Bank>;

			if (result.status === StatusCodes.OK) {
				DisplaySuccessToaster(bankAdded);
				props.updateTable();
			} else {
				const resultError = result as AxiosError;
				const error = resultError.response.data as ErrorDetails;
				DisplayErrorToaster(error.detail);
			}
		} catch (error) {
			console.error(error);
		}
		cancel();
	};

	const getDisplayedValue = (bank: Bank): ReactNode => {
		if (bank.displayName === null) return bank.legalName;
		if (bank.legalName === null) return bank.displayName;
		return `${bank.displayName} - ${bank.legalName}`;
	};

	const resetBankValues = (): void => {
		setBanks([]);
		form.setFieldsValue({ bank: undefined });
	};

	const onMarketChange = async (selectedMarket: string): Promise<void> => {
		try {
			resetBankValues();
			setFetchingBanks(true);
			const result = (await getProvidersByCountryCode.fetchData({
				method: 'GET',
				url: `/api/providers?countryCode=${selectedMarket}`,
				headers: {
					accept: '*/*',
				},
			})) as AxiosResponse<Bank[]>;
			setBanks(result.data);
			setFetchingBanks(false);
		} catch (error) {
			console.error(error);
		}
	};

	const onBankChange = (index: number): void => {
		const selectedBankValue: Bank = banks[index];
		setSelectedBank(selectedBankValue);
	};

	const isAlphanumeric = (value: string): boolean => {
		const strRegex = /^[a-z0-9]+$/i;
		return strRegex.test(value);
	};

	useEffect(() => {
		const load = (): void => {
			if (marketsResponse !== undefined) {
				setMarkets(marketsResponse.data);
			} else if (marketsError !== undefined) {
				setMarkets([]);
			}
		};
		load();
	}, [marketsResponse, marketsError]);

	const validateIBAN = {
		validator(rule: object, value: string): Promise<void> {
			return new Promise((resolve, reject) => {
				if (!value && !getFieldValue('bban')) {
					reject(new Error(IBAN_OR_BBAN_REQUIRED));
				}
				if (getFieldValue('market') === COUNTRY_CODE_GB && value && value.length !== IBAN_VALID_LENGTH_GB) {
					reject(new Error(IBAN_INVALID_LENGTH));
				}
				if (value && !isAlphanumeric(value)) {
					reject(new Error(IBAN_INVALID_CHARS));
				}
				if (value && getFieldValue('bban') && !value.endsWith(getFieldValue('bban'))) {
					reject(new Error(BBAN_IBAN_NOT_MATCH));
				}

				resolve();
			});
		},
	};

	const validateBBAN = {
		validator(rule: object, value: string): Promise<void> {
			return new Promise((resolve, reject) => {
				if (!value && !getFieldValue('iban')) {
					reject(new Error(IBAN_OR_BBAN_REQUIRED));
				}
				if (
					getFieldValue('market') === COUNTRY_CODE_GB &&
					value &&
					!BBAN_VALID_LENGTH_GB.includes(value.length)
				) {
					reject(new Error(BBAN_INVALID_LENGTH));
				}
				if (value && !isAlphanumeric(value)) {
					reject(new Error(BBAN_INVALID_CHARS));
				}
				if (value && getFieldValue('iban') && !getFieldValue('iban').endsWith(value)) {
					reject(new Error(BBAN_IBAN_NOT_MATCH));
				}

				resolve();
			});
		},
	};

	const onChangeIBAN = async (): Promise<void> => {
		const ibanValue = getFieldValue('iban').replace(/\s/g, '');
		setFieldsValue({ iban: ibanValue });
		await validateFields(['bban']);
	};

	const onChangeBBAN = async (): Promise<void> => {
		const bbanValue = getFieldValue('bban').replace(/\s/g, '');
		setFieldsValue({ bban: bbanValue });
		await validateFields(['iban']);
	};

	return (
		<Modal
			title="Add Bank"
			open={props.isVisible}
			className="add-bank-modal"
			onCancel={cancel}
			footer={[
				<CLButton key={uuidv4()} label="Add" onClick={addBank} />,
				<CLButton
					key={uuidv4()}
					label="Cancel"
					onClick={cancel}
					className="cl-button-reject"
					wrapperClassName="cl-bank-cancel-button"
				/>,
			]}
		>
			<h6>
				<strong>Please fill the folowing details:</strong>
			</h6>
			<Form className="add-bank-form" form={form}>
				<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
					<Col className="gutter-row">
						<Form.Item
							label="Market"
							name="market"
							className="select-form-item"
							labelCol={{ span: 24 }}
							wrapperCol={{ span: 24 }}
							rules={[{ required: true, message: MARKET_REQUIRED }]}
						>
							<Select
								showSearch
								optionFilterProp="children"
								placeholder="Click on the dropdown to select a market"
								className="market-select"
								onChange={async (selectedMarket: string): Promise<void> => {
									await onMarketChange(selectedMarket);
								}}
							>
								{markets?.map((market: Country, index: number) => (
									<Option value={market.countryCode} key={index}>
										{market.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col className="gutter-row bank-col">
						<Form.Item
							label="Bank Name"
							name="bank"
							className="select-form-item"
							labelCol={{ span: 24 }}
							wrapperCol={{ span: 24 }}
							rules={[{ required: true, message: BANK_REQUIRED }]}
						>
							<Select
								showSearch
								placeholder="Select a market first and then select a bank"
								className="bank-select"
								optionFilterProp="children"
								notFoundContent={
									fetchingBanks ? (
										<Spin size="large" className="bank-spin-content" />
									) : (
										<Empty className="bank-empty-content" image={Empty.PRESENTED_IMAGE_SIMPLE} />
									)
								}
								onChange={(index: number): void => onBankChange(index)}
							>
								{banks?.map((bank, index) => (
									<Option value={index} key={index}>
										{getDisplayedValue(bank)}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col className="gutter-row bank-col">
						<Form.Item
							label="International Bank Account Number"
							name="iban"
							className="text-form-item"
							labelCol={{ span: 24 }}
							wrapperCol={{ span: 24 }}
							rules={[validateIBAN]}
							required={true}
						>
							<Input className="bank-input" placeholder="Enter IBAN" onChange={onChangeIBAN} />
						</Form.Item>
					</Col>
					<Col className="gutter-row bank-col">
						<Form.Item
							label="Basic Bank Account Number"
							name="bban"
							className="text-form-item"
							labelCol={{ span: 24 }}
							wrapperCol={{ span: 24 }}
							rules={[validateBBAN]}
							required={true}
						>
							<Input className="bank-input" placeholder="Enter BBAN" onChange={onChangeBBAN} />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

export default BankModal;
