import '../style.css';

import { Col, Row } from 'antd';
import moment from 'moment';
import React from 'react';

import { IMissionViewDetailsFields, UkfpViewDetailsFields } from '../../../../../models/Missions/MissionViewDetails';
import { UKFasterPaymentDetails } from '../../../../../models/Payments/UkFasterPayment';

const UkfpViewAdminDetails = ({ data }: { data: UKFasterPaymentDetails }): JSX.Element => (
	<>
		<Row className="cl-mission-admin-details-title">
			<Col xs={24} lg={24}>
				<h6>Payment Details</h6>
			</Col>
		</Row>
		<Row className="cl-mission-admin-details">
			{(Object.keys(UkfpViewDetailsFields) as Array<keyof IMissionViewDetailsFields>).map((key, index) => {
				const UkfpKey = key as keyof UKFasterPaymentDetails;
				const value = data[UkfpKey];
				return (
					value !== undefined && (
						<React.Fragment key={`row_${index}`}>
							<Col xs={12} lg={12} key={`label_${index}`}>
								{UkfpViewDetailsFields[key].label}
							</Col>
							<Col xs={12} lg={12} key={`value_${index}`}>
								{value instanceof moment ? '' : value?.toString() || 'NA'}
							</Col>
						</React.Fragment>
					)
				);
			})}
		</Row>
	</>
);

export default UkfpViewAdminDetails;
