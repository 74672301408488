import { applicationIdType, APV3_ID, CORE_ID, ENTERPRISE_ID } from './ApplicationLayerType';

export type EnvironmentType = {
	name?: string;
	id?: number;
};

export type IEnvironmentTypeOptions = { [key in applicationIdType]: { [key: string]: EnvironmentType[] } | undefined };

const testEnvironmentCoreTypes: EnvironmentType[] = [
	{
		name: 'Test',
		id: 0,
	},
];

const sandboxEnvironmentCoreTypes: EnvironmentType[] = [
	{
		name: 'SquadExpansionProd',
		id: 1,
	},
	{
		name: 'Capybara',
		id: 3,
	},
	{
		name: 'Mousedeer',
		id: 4,
	},
	{
		name: 'Platypus',
		id: 5,
	},
	{
		name: 'Sloth',
		id: 6,
	},
	{
		name: 'Squirrel',
		id: 7,
	},
];

const prodEnvironmentCoreTypes: EnvironmentType[] = [
	{
		name: 'Prod',
		id: 2,
	},
];

const prodRegionsEnvironmentCoreTypes: EnvironmentType[] = [
	{
		name: 'ProdNe',
		id: 8,
	},
	{
		name: 'ProdWe',
		id: 9,
	},
];

const spiirEnvironmentCoreTypes: EnvironmentType[] = [
	{
		name: 'Spiir',
		id: 10,
	},
];

const spiirRegionsEnvironmentCoreTypes: EnvironmentType[] = [
	{
		name: 'SpiirNe',
		id: 11,
	},
	{
		name: 'SpiirWe',
		id: 12,
	},
];

const testEnvironmentEnterpriseTypes: EnvironmentType[] = [
	{
		name: 'Test',
		id: 2,
	},
];

const sandboxEnvironmentEnterpriseTypes: EnvironmentType[] = [
	{
		name: 'SquadExpansionProd',
		id: 3,
	},
	{
		name: 'Capybara',
		id: 4,
	},
	{
		name: 'Mousedeer',
		id: 5,
	},
	{
		name: 'Platypus',
		id: 6,
	},
	{
		name: 'Sloth',
		id: 7,
	},
	{
		name: 'Squirrel',
		id: 8,
	},
];

const prodEnvironmentEnterpriseTypes: EnvironmentType[] = [
	{
		name: 'Prod',
		id: 1,
	},
];

const concatenatedCoreEnv = testEnvironmentCoreTypes
	.concat(prodEnvironmentCoreTypes)
	.concat(sandboxEnvironmentCoreTypes)
	.concat(prodRegionsEnvironmentCoreTypes)
	.concat(spiirEnvironmentCoreTypes)
	.concat(spiirRegionsEnvironmentCoreTypes);

const concatenatedEnterpriseEnv = testEnvironmentEnterpriseTypes
	.concat(prodEnvironmentEnterpriseTypes)
	.concat(sandboxEnvironmentEnterpriseTypes);

export const EnvironmentOptions: IEnvironmentTypeOptions = {
	[CORE_ID]: {
		'Local-DEV': concatenatedCoreEnv,
		DEV: testEnvironmentCoreTypes,
		Test: testEnvironmentCoreTypes,
		Sandbox: concatenatedCoreEnv,
		Production: concatenatedCoreEnv,
	},
	[APV3_ID]: undefined,
	[ENTERPRISE_ID]: {
		'Local-DEV': concatenatedEnterpriseEnv,
		DEV: testEnvironmentEnterpriseTypes,
		Test: testEnvironmentEnterpriseTypes,
		Sandbox: concatenatedEnterpriseEnv,
		Production: concatenatedEnterpriseEnv,
	},
};
