import { DatePicker, DatePickerProps } from 'antd';
import { Moment } from 'moment';
import React, { FunctionComponent } from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { Col, Row } from 'reactstrap';

import { CLInputPropsType, defaultProps } from './CLInputProps';

type CLDatePickerProps = DatePickerProps &
	CLInputPropsType & {
		errors: FieldError | Merge<FieldError, FieldErrorsImpl<Moment>>;
	};

const CLDatePickerDefaultProps: CLDatePickerProps = {
	...defaultProps,
	errors: { type: undefined },
};

const CLDatePicker: FunctionComponent<CLDatePickerProps> = (props): JSX.Element => {
	const className = `cl-input ${props.errors?.type ? 'cl-input-errors' : ''}`;

	return (
		<div className={`cl-input-wrapper ${props.className}`}>
			<Row>
				<Col lg={props.colSize.lg} md={props.colSize.md} sm={props.colSize.sm}>
					<label>
						{props.label} {props.required && <span className="cl-input-required">*</span>}
					</label>
				</Col>
				<Col lg={props.colSize.lg} md={props.colSize.md} sm={props.colSize.sm}>
					<DatePicker
						{...props}
						size="large"
						className={className}
						format={props.format}
						placeholder={props.placeholder}
					/>
				</Col>
				<Col lg={props.colSize.lg} md={props.colSize.md} sm={props.colSize.sm}>
					<div className="cl-text-input-errors">{props.errors?.message}</div>
				</Col>
			</Row>
		</div>
	);
};

CLDatePicker.defaultProps = CLDatePickerDefaultProps;

export default CLDatePicker;
