import './TesterOnboarding.css';

import { useAuth0 } from '@auth0/auth0-react';
import * as React from 'react';

import { CLButton } from '../../cl-shared-components/Buttons';
import config from '../../resources/config';
import Welcome from '../Welcome/Welcome';

const TesterOnboarding = () => {
	const { logout } = useAuth0();
	const redirectToLoginPage = () => {
		console.info(`Origin in TesterOnboarding component ${window.location.origin}`);
		logout({
			returnTo: window.location.origin,
			federated: true,
			localOnly: false,
			client_id: config.clientId,
		});
	};

	return (
		<>
			<div className="tester-onboarding-page">
				<Welcome />
			</div>
			<div className="onboardingMessageContent">
				<div>The Signup process is completed with succes.</div>
				<div>It will take 24h to approve your account.</div>
				<div>Please come back after that.</div>
				<div className="onboarding-login-button">
					<span>Try to login</span> <CLButton label="Login" onClick={redirectToLoginPage} />
				</div>
			</div>
		</>
	);
};
export default TesterOnboarding;
