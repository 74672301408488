import { BBAN_VALID_LENGTH_GB, IBAN_VALID_LENGTH_GB } from './config';

export const BANK_RESPONSE_ERROR_DESCRIPTION_KEY = 'error_description';

// User
export const noUsersSelectedMessage = 'No User Profile is selected';

// Bank
const BBAN_VALID_LENGTH_GB_MESSAGE = BBAN_VALID_LENGTH_GB.join(' or ');

export const MARKET_REQUIRED = 'A Market must be selected';
export const BANK_REQUIRED = 'A Bank must be selected';
export const IBAN_OR_BBAN_REQUIRED = 'The IBAN or BBAN must be filled in';
export const IBAN_INVALID_LENGTH = `The IBAN provided doesn't match the requested number of ${IBAN_VALID_LENGTH_GB} characters.`;
export const IBAN_INVALID_CHARS = 'The IBAN must contain only alphanumeric characters.';
export const IBAN_INVALID = 'IBAN is invalid';
export const BBAN_INVALID_LENGTH = `The BBAN provided doesn't match the requested number of ${BBAN_VALID_LENGTH_GB_MESSAGE} characters.`;
export const BBAN_INVALID_CHARS = 'The BBAN must contain only alphanumeric characters.';
export const BBAN_INVALID = 'BBAN is invalid';
export const BBAN_IBAN_NOT_MATCH = 'The IBAN and BBAN provided do not match';

// Missions
export const OPERATION_WITH_NO_CHANGES_MESSAGE = 'The missions already exist';
export const OPERATION_PARTIALLY_SUCCESSFUL = 'You created some missions, except those already existing';
export const MISSIONS_MARKET_RULE_MESSAGE = 'A Market must be selected';
export const MISSIONS_APPLICATION_LAYER_RULE_MESSAGE = 'An Application layer must be selected';
export const MISSIONS_PAYMENT_RAIL_RULE_MESSAGE = 'A payment rail must be selected';
export const MISSIONS_INTEGRATOR_TYPE_RULE_MESSAGE = 'An Integrator type must be selected';
export const MISSIONS_MISSION_TYPE_RULE_MESSAGE = 'A Mission Type must be selected';
export const MISSIONS_PROVIDERS_RULE_MESSAGE = 'A Provider must be selected';
export const MISSIONS_AREA_RULE_MESSAGE = 'An Area must be selected';

// General
export const DEFAULT_ERROR_MESSAGE = 'Something went wrong';
export const UNKNOWN_TYPE_ERROR_MESSAGE = 'Unknown Type';

// Common Payment
export const DESTINATION_NAME_REQUIRED_ERROR_MESSAGE = 'The Destination Name must be filled in';
export const SORT_CODE_REQUIRED_ERROR_MESSAGE = 'The Sort Code must be filled in';
export const ACCOUNT_NUMBER_REQUIRED_ERROR_MESSAGE = 'The Account Number must be filled in';
export const SECOND_ACCOUNT_NUMBER_REQUIRED_ERROR_MESSAGE =
	'Another bank account is needed. Please add a 2nd bank account';

// Payment execution and Stage Routing Errors
export const PAYMENT_FAIL_MESSAGE = "An error occurred. Please check mission's final status within your missions table";
export const PAYMENT_ROUTING_ERRORS: Record<string, string> = {
	access_denied: 'Access denied - Please check your credentials',
};

// Variable Recurring Payment
export const VRP_VALID_FROM_MIN_DATE_ERROR_MESSAGE = '"Valid From" must be today or a future date';
export const VRP_VALID_FROM_REQUIRED_ERROR_MESSAGE = '"Valid From" must be filled in';
export const VRP_VALID_TO_MIN_DATE_ERROR_MESSAGE = '"Valid To" must be after "Valid From"';
export const VRP_VALID_TO_REQUIRED_ERROR_MESSAGE = '"Valid To" must be filled in';
export const VRP_STARTING_AT_REQUIRED_ERROR_MESSAGE = '"Starting at" must be filled in';
export const VRP_LIMIT_AMOUNT_COUNT_ERROR_MESSAGE = 'Include at least one "Per Payment" limit and one periodic limit';
export const VRP_PAYMENT_DETAILS_AMOUNT_REQUIRED_ERROR_MESSAGE = 'The Amount must be greater than 0';
export const VRP_PAYMENT_DETAILS_CURRENCY_REQUIRED_ERROR_MESSAGE = 'The Currency must be filled in';
export const VRP_PAYMENT_PURPOSE_CODE_REQUIRED_ERROR_MESSAGE = 'The Payment Purpose Code must be filled in';
export const VRP_PAYMENT_DETAILS_END_TO_END_REQUIRED_ERROR_MESSAGE = 'The End-to-End Id must be filled in';
export const VRP_CONSENT_DETAIL_FAIL =
	'An error occurred while attempting to obtain consent details. Please try again.';
export const VRP_CONSENT_TERM_CONDITIONS_FAIL = 'You should accept term and conditions first';
export const VRP_CONSENT_ROUTING_ERRORS: Record<string, string> = {
	access_denied: 'Access denied - Please check your credentials',
};

// Single Immediate Payment
export const SIP_AMOUNT_REQUIRED_ERROR_MESSAGE = 'The Amount must be filled in';
export const SIP_AMOUNT_GREATER_THAN_0_ERROR_MESSAGE = 'The Amount must be greater than 0';
export const SIP_AMOUNT_MAX_DECIMALS_ERROR_MESSAGE = 'The Amount must have maximum two decimals';
export const SIP_CURRENCY_REQUIRED_ERROR_MESSAGE = 'The Currency must be filled in';
export const SIP_CURRENCY_MAX_LENGTH_ERROR_MESSAGE = 'The Currency requests a number of 3 characters';
export const SIP_CURRENCY_DIFFERENT_CURRENCY_ERROR_MESSAGE = 'The Currency must be filled in';
export const SIP_END_TO_END_ID_REQUIRED_ERROR_MESSAGE = 'The End-to-End Id must be filled in';
export const SIP_END_TO_END_ID_LENGTH_ERROR_MESSAGE = 'The End-to-End Id requests a number of 35 characters';
export const SIP_THIRD_PARTY_TYPE_REQUIRED_ERROR_MESSAGE = 'The Third Party Type must be filled in';
export const SIP_EXECUTION_DATE_REQUIRED_ERROR_MESSAGE = 'The Execution Date must be filled in';
export const SIP_EXECUTION_DATE_IN_FUTURE_ERROR_MESSAGE = 'The Execution Date must be a future date';
export const SIP_REFERENCE_INFORMATION_REQUIRED_ERROR_MESSAGE = 'The Message Text must be filled in';
export const SIP_REFERENCE_INFORMATION_ERROR_MESSAGE = 'The Message Text must be filled in';
export const SIP_REFERENCE_INFORMATION_MAX_LENGTH_ERROR_MESSAGE = 'The Message Text requests maximum 18 characters';
export const SIP_TRANSACTIONS_FAIL_MESSAGE =
	"An error occurred. Please check mission's final status within your missions table";
export const SIP_TRANSACTIONS_ROUTING_ERRORS: Record<string, string> = {
	access_denied: 'Access denied - Please check your credentials',
};

// NATWEST
export const CVRP_NATWEST_LIMIT_AMOUNT_COUNT_ERROR_MESSAGE =
	'Include "Total Amount", at least one "Per Payment" limit and one periodic limit';
export const CVRP_NATWEST_MAXIMUM_AMOUNT_REQUIRED_ERROR_MESSAGE = 'The Maximum total amount must be greater than 0';
export const CVRP_NATWEST_NUMBER_PAYMENTS_REQUIRED_ERROR_MESSAGE = 'The No. of payments must be greater than 0';

// ACCOUNTS AND TRANSACTIONS
export const ACCOUNTS_TRANSACTIONS_FAIL_MESSAGE =
	"An error occurred. Please check mission's final status within your missions table";
export const ACCOUNTS_TRANSACTIONS_ROUTING_ERRORS: Record<string, string> = {
	access_denied: 'Access denied - Please check your credentials',
};
