import './CreateMission.css';

import { EditOutlined } from '@ant-design/icons';
import { Col, Empty, Form, Row, Select, Space, Tree } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { AxiosResponse } from 'axios';
import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';

import { CLButton } from '../../../cl-shared-components/Buttons';
import CLModal from '../../../cl-shared-components/Modal/CLModal';
import Spinner from '../../../cl-shared-components/Spinner/Spinner';
import { DisplayWarningToaster } from '../../../cl-shared-components/Toaster/DisplayToaster';
import useAxios from '../../../hooks/axios';
import useRouterPrompt from '../../../hooks/routerPrompt';
import { PageTitleContext } from '../../../layout/Layout';
import { CheckInfo } from '../../../models/antd/Tree/CheckInfo';
import { Country } from '../../../models/Countries/Country';
import StatusCodes from '../../../models/HttpResponse/StatusCodes';
import {
	applicationIdType,
	ApplicationLayerType,
	applicationLayerTypes,
	APV3_ID,
	CORE_ID,
	ENTERPRISE_ID,
} from '../../../models/Missions/ApplicationLayerType';
import { Area, areas } from '../../../models/Missions/Area';
import { CreateMissionResponse } from '../../../models/Missions/CreateMissionsResponse';
import { EnvironmentOptions } from '../../../models/Missions/EnvironmentType';
import { InputResetType } from '../../../models/Missions/InputResetType';
import {
	AccountTransaction,
	CvrpPayment,
	MissionType,
	MissionTypeID,
	missionTypes,
	SingleImmediatePayment,
	SvrPayment,
	UkFasterPayment,
} from '../../../models/Missions/MissionType';
import { PaymentRailValues } from '../../../models/Missions/PaymentRail';
import { TreeDataNode } from '../../../models/Missions/TreeDataNodeType';
import { PaymentProviderDetailsType } from '../../../models/Payments';
import { AccountTransactionDetails } from '../../../models/Payments/AccountTransactions';
import { SingleImmediatePaymentDetails } from '../../../models/Payments/SingleImmediatePayment';
import { UKFasterPaymentDetails } from '../../../models/Payments/UkFasterPayment';
import { VrpProviderDetails } from '../../../models/Payments/Vrp/VrpProviderDetails';
import { Tester } from '../../../models/Tester/Tester';
import ApproveRejectButtonsText from '../../../models/ui/ApproveRejectButtonsText';
import { pageTitles } from '../../../resources/config';
import {
	CVRP_NATWEST_LIMIT_AMOUNT_COUNT_ERROR_MESSAGE,
	DEFAULT_ERROR_MESSAGE,
	MISSIONS_APPLICATION_LAYER_RULE_MESSAGE,
	MISSIONS_INTEGRATOR_TYPE_RULE_MESSAGE,
	MISSIONS_MARKET_RULE_MESSAGE,
	MISSIONS_MISSION_TYPE_RULE_MESSAGE,
	MISSIONS_PAYMENT_RAIL_RULE_MESSAGE,
	MISSIONS_PROVIDERS_RULE_MESSAGE,
	SECOND_ACCOUNT_NUMBER_REQUIRED_ERROR_MESSAGE,
	UNKNOWN_TYPE_ERROR_MESSAGE,
	VRP_LIMIT_AMOUNT_COUNT_ERROR_MESSAGE,
} from '../../../resources/Errors';
import useMissionService from '../../../services/useMissionService';
import {
	findPaymentProviderByTesterProviderId,
	getEmptyAccountTransactionsProviderDetails,
	getEmptySingleImmediatePaymentProviderDetails,
	getEmptyUkFasterPaymentsProviderDetails,
	getEmptyVrpProviderDetails,
	getSelectedNodes,
	getSelectedProviders,
	isTotalAmountHidden,
} from '../../../utils/MissionUtils';
import AccountsTransactionsForm from './AccountsTransactionsForm';
import SingleImmediatePaymentMissionForm from './SingleImmediatePaymentForm';
import UkFasterPaymentMissionForm from './UkFasterPaymentMissionForm';
import VrpMissionForm from './VrpMissionForm';

const formInputName = {
	missionType: 'missionType',
	environment: 'environment',
	executionEnvironment: 'executionEnvironment',
	providers: 'providers',
	applicationLayer: 'applicationLayer',
	market: 'market',
	integrator: 'integrator',
	schemeId: 'schemeId',
};

const CreateMission = () => {
	const {
		getShowPromptForMissionCreation,
		getUpdateProviderPayment,
		handleGetTesterProvidersSuccessfully,
		handleGetTesterProvidersUnsuccessfully,
		initializeVrpProvidersDetails,
		initializeUkFasterPaymentProvidersDetails,
		initializeSingleImmediatePaymentProvidersDetails,
		initializeAccountTransactionProvidersDetails,
		getCreateMissionByMissionType,
	} = useMissionService();
	const { Option } = Select;
	const [form] = Form.useForm();
	const {
		validateFields,
		resetFields,
		setFieldsValue,
	}: {
		validateFields(nameList?: NamePath[]): Promise<void>;
		resetFields(fields?: NamePath[]): void;
		setFieldsValue(fields: Record<string, string | [] | object>): void;
	} = form;
	const { fetchData } = useAxios({});
	const [isLoading, setIsLoading] = useState(false);
	const { setPageTitle } = useContext(PageTitleContext);
	const [treeData, setTreeData] = useState<TreeDataNode[]>([]);
	const [checkedKeys, setCheckedKeys] = useState<React.Key[] | { checked: React.Key[]; halfChecked: React.Key[] }>(
		[]
	);
	const [checkedKeysInfo, setCheckedKeysInfo] = useState<number[]>([]);

	const [applicationLayer, setApplicationLayer] = useState<applicationIdType>(null);
	const [environmentLayer, setEnvironmentLayer] = useState<string>('');
	const [integratorType, setIntegratorType] = useState<string | null>(null);
	const [manageCountries, setManageCountries] = useState<{
		selectedCountry: string;
		sourceCountries: Country[];
	}>({ selectedCountry: null, sourceCountries: [] });
	const [missionType, setMissionType] = useState<string>(null);
	const [selectedMissionTypes, setSelectedMissionTypes] = useState<MissionType[]>();

	const [providerPaymentDetailsToView, setProviderPaymentDetailsToView] = useState<PaymentProviderDetailsType>(null);
	const [providersPaymentDetails, setProvidersPaymentDetails] = useState<PaymentProviderDetailsType[]>([]);
	const [updatedProvidersPaymentDetails, setUpdatedProvidersPaymentDetails] = useState<PaymentProviderDetailsType[]>(
		[]
	);
	const [filteredProvidersDetails, setFilteredProvidersDetails] = useState<TreeDataNode[]>([]);

	const [showActionPrompt, setShowActionPrompt] = useState<boolean>(false);
	const [openMissionModal, setOpenMissionModal] = useState<boolean>(false);

	const componentRef = useRef({ actionToConfirm: (cb?: () => void): void => cb() });
	const formRef = useRef<HTMLFormElement>(null);

	const { showRoutePrompt, onRoutePromptConfirm, onRoutePromptCancel } = useRouterPrompt({
		when: updatedProvidersPaymentDetails.length > 0,
		onOK: () => true,
		onCancel: () => false,
	});

	const getCountries = async (): Promise<Country[]> => {
		const response = (await fetchData({
			method: 'GET',
			url: '/api/countries',
			headers: { accept: '*/*' },
		})) as AxiosResponse<Country[]>;

		return response?.data;
	};

	useEffect(() => {
		setPageTitle(pageTitles.createMissions);
		void (async () => {
			const sourceCountries = (await getCountries().catch((e) => console.error(e))) as Country[];
			setManageCountries({ ...manageCountries, sourceCountries });
		})();
	}, []);

	useEffect(() => {
		if (applicationLayer) {
			setManageCountries({ ...manageCountries });
		}
	}, [applicationLayer]);

	useEffect(() => {
		if (providerPaymentDetailsToView?.testerProviderId === null) {
			setOpenMissionModal(false);
		}
	}, [providerPaymentDetailsToView]);

	const onResetInputs = (inputResetType: InputResetType): void => {
		setCheckedKeys([]);
		setCheckedKeysInfo([]);
		switch (inputResetType) {
			case 'Application':
				setTreeData([]);
				setSelectedMissionTypes([]);
				resetFields([
					formInputName.environment,
					formInputName.market,
					formInputName.integrator,
					formInputName.missionType,
					formInputName.providers,
					formInputName.schemeId,
				]);
				break;
			case 'Environment':
				setTreeData([]);
				setSelectedMissionTypes([]);
				resetFields([
					formInputName.market,
					formInputName.integrator,
					formInputName.missionType,
					formInputName.providers,
				]);
				break;
			case 'Market':
				setTreeData([]);
				setSelectedMissionTypes([]);
				resetFields([
					formInputName.integrator,
					formInputName.missionType,
					formInputName.providers,
					formInputName.schemeId,
				]);
				break;
			case 'MissionType':
				resetFields([formInputName.providers]);
				setProvidersPaymentDetails([]);
				setUpdatedProvidersPaymentDetails([]);
				setProviderPaymentDetailsToView(null);
				setIsLoading(false);
				break;
			case 'All':
			default:
				resetFields();
				setTreeData([]);
				setApplicationLayer(null);
				setEnvironmentLayer('');
				setSelectedMissionTypes([]);
				setIntegratorType(null);
				setManageCountries({ ...manageCountries, selectedCountry: null });
				setProvidersPaymentDetails([]);
				setUpdatedProvidersPaymentDetails([]);
				setProviderPaymentDetailsToView(null);
				setIsLoading(false);
				break;
		}
	};

	const onApplicationLayerChange = (selectedApplicationLayer: applicationIdType): void => {
		try {
			setApplicationLayer(selectedApplicationLayer);
			onResetInputs('Application');
		} catch (error) {
			console.error(error);
		}
	};

	const onEnvironmentLayerChange = (selectedEnvironmentLayer: string): void => {
		try {
			setEnvironmentLayer(selectedEnvironmentLayer);
			onResetInputs('Environment');
		} catch (error) {
			console.error(error);
		}
	};

	const onIntegratorTypeChange = (selectedIntegratorType: string): void => {
		try {
			setIntegratorType(selectedIntegratorType);
		} catch (error) {
			console.error(error);
		}
	};

	const onMarketChange = (selectedMarket: string): void => {
		try {
			onResetInputs('Market');

			setSelectedMissionTypes(
				missionTypes.filter(
					(mission) =>
						mission.application.includes(applicationLayer) && mission.market.includes(selectedMarket)
				)
			);

			setManageCountries({ ...manageCountries, selectedCountry: selectedMarket });
			setFieldsValue({ market: selectedMarket });
		} catch (error) {
			console.error(error);
		}
	};

	const getCountryCode = (countryName: string, countries: Country[]): string | undefined => {
		const country = countries.find((c) => c.name.toLowerCase() === countryName.toLowerCase());
		return country ? country.countryCode : undefined;
	};

	const onMissionTypeChange = async (selectedMissionType: string) => {
		try {
			setIsLoading(true);
			const result = (await fetchData({
				method: 'GET',
				url: '/api/testers/providers',
				headers: { accept: '*/*' },
				params: {
					countryCode: manageCountries.selectedCountry,
				},
			})) as AxiosResponse<Country[]>;
			setIsLoading(false);

			if (result.data === undefined) {
				return;
			}

			const checkablePaymenstIds = [UkFasterPayment.id, SingleImmediatePayment.id, AccountTransaction.id];
			const mappedTesters: TreeDataNode[] = result.data.map((tester: Tester) => ({
				title: tester.name,
				testerId: tester.id,
				key: `Tester_${tester.id}`,
				isParent: true,
				checkable: checkablePaymenstIds.includes(selectedMissionType),
				children: tester.providers.map((provider) => ({
					testerId: tester.id,
					testerName: tester.name,
					townName: tester.city,
					countryCode: getCountryCode(tester.country, manageCountries.sourceCountries),
					title: provider.displayName || provider.legalName,
					key: provider.id,
					details: provider,
				})),
			}));
			setMissionType(selectedMissionType);
			setTreeData(mappedTesters);
			onResetInputs('MissionType');
		} catch (error) {
			setIsLoading(false);
			console.error(error);
		}
	};

	const initializeProvidersPaymentDetails = (
		selectedNodes: TreeDataNode[],
		filteredProviders: TreeDataNode[]
	): void => {
		switch (missionType) {
			case UkFasterPayment.id:
				setProvidersPaymentDetails(
					initializeUkFasterPaymentProvidersDetails(
						selectedNodes,
						providersPaymentDetails,
						updatedProvidersPaymentDetails
					)
				);
				break;
			case CvrpPayment.id:
			case SvrPayment.id:
				setProvidersPaymentDetails(
					initializeVrpProvidersDetails(
						selectedNodes,
						providersPaymentDetails,
						updatedProvidersPaymentDetails,
						missionType,
						filteredProviders
					)
				);
				break;
			case SingleImmediatePayment.id:
				setProvidersPaymentDetails(
					initializeSingleImmediatePaymentProvidersDetails(
						selectedNodes,
						providersPaymentDetails,
						updatedProvidersPaymentDetails
					)
				);

				break;
			case AccountTransaction.id:
				setProvidersPaymentDetails(
					initializeAccountTransactionProvidersDetails(
						selectedNodes,
						providersPaymentDetails,
						updatedProvidersPaymentDetails
					)
				);

				break;
			default:
				console.error(UNKNOWN_TYPE_ERROR_MESSAGE);
		}
	};

	const onCheck = async (
		checkedKeysValue: React.Key[] | { checked: React.Key[]; halfChecked: React.Key[] },
		info: CheckInfo<TreeDataNode>
	): Promise<void> => {
		const nodes = getSelectedNodes(missionType, info);
		const filteredProviders = getSelectedProviders(treeData, nodes, missionType);
		setFilteredProvidersDetails(filteredProviders);

		setCheckedKeys(nodes.map((node) => node.key));
		setCheckedKeysInfo(nodes.map((node) => node.key as number));

		if (filteredProviders.length > 0) {
			initializeProvidersPaymentDetails(nodes, filteredProviders);
		}

		await validateFields([formInputName.providers]).catch(() => {
			// TODO fix thrown error for unit tests
		});
	};

	const onCreate = async () => {
		let errorFields: [] = [];
		try {
			await validateFields([
				formInputName.missionType,
				formInputName.market,
				formInputName.integrator,
				formInputName.providers,
				formInputName.applicationLayer,
				formInputName.environment,
				formInputName.schemeId,
			]);
		} catch (error) {
			const errorAssert: Record<string, []> = error as Record<string, []>;
			errorFields = errorAssert.errorFields;
		}

		if (errorFields.length) {
			return;
		}

		const { data, actionName } = getCreateMissionByMissionType({
			missionType,
			applicationLayer,
			providersPaymentDetails,
			environmentLayer,
			integratorType,
			market: manageCountries.selectedCountry,
			schemeId: form.getFieldValue(formInputName.schemeId),
		});

		try {
			const postDataResult = (await fetchData({
				method: 'post',
				url: `/api/missions/${actionName}`,
				data,
			})) as AxiosResponse<CreateMissionResponse>;
			if (postDataResult.status === StatusCodes.OK) {
				handleGetTesterProvidersSuccessfully(postDataResult);
			} else {
				handleGetTesterProvidersUnsuccessfully(postDataResult);
			}
		} catch (error) {
			console.error(error); // TODO: Must provide real error handling
			DisplayWarningToaster(DEFAULT_ERROR_MESSAGE);
		} finally {
			onResetInputs('All');
		}
	};

	const isValidateVrpAmountLimitsProviders = (): boolean =>
		![CvrpPayment.id, SvrPayment.id].includes(missionType) ||
		(providersPaymentDetails[0] as VrpProviderDetails).amountLimits?.length > 0;

	const isValidateMultipleProvidersCreditorDetails = (): boolean =>
		(missionType === MissionTypeID.AccountTransaction && filteredProvidersDetails?.length >= 1) ||
		[CvrpPayment.id, SvrPayment.id].includes(missionType) ||
		filteredProvidersDetails?.length >= 2;

	const checkProvidersValidator = () => {
		if (checkedKeysInfo.length === 0) {
			return Promise.reject(new Error(MISSIONS_PROVIDERS_RULE_MESSAGE));
		}
		if (!isValidateMultipleProvidersCreditorDetails()) {
			return Promise.reject(new Error(SECOND_ACCOUNT_NUMBER_REQUIRED_ERROR_MESSAGE));
		}

		if (isValidateVrpAmountLimitsProviders() === false) {
			const limitAmountErrorMessage = isTotalAmountHidden(providersPaymentDetails[0] as VrpProviderDetails)
				? VRP_LIMIT_AMOUNT_COUNT_ERROR_MESSAGE
				: CVRP_NATWEST_LIMIT_AMOUNT_COUNT_ERROR_MESSAGE;

			return Promise.reject(new Error(limitAmountErrorMessage));
		}

		return Promise.resolve();
	};

	const viewProviderPaymentDetails = (e: React.MouseEvent<HTMLElement>, node: TreeDataNode): void => {
		e.stopPropagation();

		const providerDetails = findPaymentProviderByTesterProviderId(providersPaymentDetails, node.key);

		setProviderPaymentDetailsToView(providerDetails);

		const showMissionModal = providerDetails !== undefined;

		setOpenMissionModal(showMissionModal);
	};

	const showPromptBeforeActions = (action: () => void, showPrompt: boolean): void => {
		componentRef.current.actionToConfirm = action;

		if (!showPrompt) {
			componentRef.current.actionToConfirm();
		} else {
			setShowActionPrompt(showPrompt);
		}
	};

	const onPromptConfirm = async (): Promise<void> => {
		if (showActionPrompt) {
			componentRef.current.actionToConfirm();
		} else {
			await onRoutePromptConfirm();
		}
		setShowActionPrompt(false);
	};

	const onPromptCancel = async (): Promise<void> => {
		setShowActionPrompt(false);
		await onRoutePromptCancel();
	};

	const onModalClose = (): void => {
		switch (missionType) {
			case UkFasterPayment.id:
				setProviderPaymentDetailsToView(getEmptyUkFasterPaymentsProviderDetails());
				break;
			case CvrpPayment.id:
			case SvrPayment.id:
				setProviderPaymentDetailsToView(getEmptyVrpProviderDetails());
				break;
			case SingleImmediatePayment.id:
				setProviderPaymentDetailsToView(getEmptySingleImmediatePaymentProviderDetails());
				break;

			case AccountTransaction.id:
				setProviderPaymentDetailsToView(getEmptyAccountTransactionsProviderDetails());
				break;
			default:
				console.error(UNKNOWN_TYPE_ERROR_MESSAGE);
				break;
		}
	};

	const handleFormSubmit = async (data: PaymentProviderDetailsType, isDirty?: boolean) => {
		const showPrompt = getShowPromptForMissionCreation(
			updatedProvidersPaymentDetails,
			data.testerProviderId,
			isDirty
		);

		showPromptBeforeActions(() => {
			setOpenMissionModal(false);
			if (!isDirty) {
				return;
			}
			const { providersPaymentDetailsCopy, updatedProvidersPaymentDetailsCopy } = getUpdateProviderPayment(
				missionType,
				data,
				providersPaymentDetails,
				updatedProvidersPaymentDetails
			);
			setProvidersPaymentDetails(providersPaymentDetailsCopy);
			setUpdatedProvidersPaymentDetails(updatedProvidersPaymentDetailsCopy);
		}, showPrompt);

		await validateFields([formInputName.providers]).catch(() => {
			// TODO fix thrown error for unit tests
		});
	};

	const renderNode = (node: TreeDataNode, keys: (string | number)[]): ReactNode => (
		<>
			{node?.title}
			{!node.isParent && keys.includes(node.key) && (
				<span className="cl-edit-mission-icon">
					<EditOutlined
						onClick={(event: React.MouseEvent<HTMLElement>) => viewProviderPaymentDetails(event, node)}
					/>
				</span>
			)}
		</>
	);

	return (
		<div>
			<Form className="create-mission-form" form={form}>
				<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
					<Col className="gutter-row" span={8}>
						<Form.Item
							label="Application"
							name={formInputName.applicationLayer}
							className="select-form-item"
							labelCol={{ span: 24 }}
							wrapperCol={{ span: 24 }}
							rules={[{ required: true, message: MISSIONS_APPLICATION_LAYER_RULE_MESSAGE }]}
						>
							<Select
								showSearch
								optionFilterProp="children"
								placeholder="Click on the dropdown to select an application"
								onChange={onApplicationLayerChange}
							>
								{applicationLayerTypes?.map((appLayer: ApplicationLayerType) => (
									<Option value={appLayer.id} key={appLayer.id}>
										{appLayer.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
				</Row>
				{(applicationLayer === CORE_ID || applicationLayer === ENTERPRISE_ID) && (
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
						<Col className="gutter-row" span={8}>
							<Form.Item
								label="Environment"
								name={formInputName.environment}
								className="select-form-item"
								labelCol={{ span: 24 }}
								wrapperCol={{ span: 24 }}
								rules={[{ required: true, message: MISSIONS_APPLICATION_LAYER_RULE_MESSAGE }]}
							>
								<Select
									showSearch
									optionFilterProp="children"
									placeholder="Click on the dropdown to select an environment"
									onChange={onEnvironmentLayerChange}
								>
									{EnvironmentOptions[applicationLayer]
										? EnvironmentOptions[applicationLayer][process.env.REACT_APP_ENV_NAME].map(
												(env) => (
													<Option value={env.id} key={env.id}>
														{env.name}
													</Option>
												)
										  )
										: []}
								</Select>
							</Form.Item>
						</Col>
					</Row>
				)}
				<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
					<Col className="gutter-row" span={8}>
						<Form.Item
							label="Market"
							name={formInputName.market}
							className="select-form-item"
							labelCol={{ span: 24 }}
							wrapperCol={{ span: 24 }}
							rules={[{ required: true, message: MISSIONS_MARKET_RULE_MESSAGE }]}
						>
							<Select
								showSearch
								optionFilterProp="children"
								placeholder="Click on the dropdown to select a market"
								onChange={(selectedMarket: string) => {
									setFieldsValue({ market: manageCountries.selectedCountry });
									showPromptBeforeActions(() => {
										onMarketChange(selectedMarket);
									}, updatedProvidersPaymentDetails.length > 0);
								}}
							>
								{manageCountries.sourceCountries.map((market: Country) => (
									<Option value={market.countryCode} key={market.countryCode}>
										{market.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
				</Row>

				{String(applicationLayer) && Number(applicationLayer) === APV3_ID && (
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
						<Col className="gutter-row" span={8}>
							<Form.Item
								label="Integrator"
								name={formInputName.integrator}
								className="select-form-item"
								labelCol={{ span: 24 }}
								wrapperCol={{ span: 24 }}
								rules={[{ required: true, message: MISSIONS_INTEGRATOR_TYPE_RULE_MESSAGE }]}
							>
								<Select
									showSearch
									optionFilterProp="children"
									placeholder="Click on the dropdown to select an integrator"
									onChange={onIntegratorTypeChange}
								>
									{areas?.map((area: Area) => (
										<Option value={area.id} key={area.id}>
											{area.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
				)}

				{Number(applicationLayer) === APV3_ID &&
					PaymentRailValues[form.getFieldValue(formInputName.market) as string] && (
						<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
							<Col className="gutter-row" span={8}>
								<Form.Item
									label="Payment Rail"
									name={formInputName.schemeId}
									className="select-form-item"
									labelCol={{ span: 24 }}
									wrapperCol={{ span: 24 }}
									rules={[{ required: true, message: MISSIONS_PAYMENT_RAIL_RULE_MESSAGE }]}
								>
									<Select
										showSearch
										optionFilterProp="children"
										placeholder="Click on the dropdown to select a payment rail"
									>
										{PaymentRailValues[manageCountries.selectedCountry]?.map(
											(paymentRail: string, index) => (
												<Option value={paymentRail} key={`payment_rail_${index}`}>
													{paymentRail}
												</Option>
											)
										)}
									</Select>
								</Form.Item>
							</Col>
						</Row>
					)}

				<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
					<Col className="gutter-row" span={8}>
						<Form.Item
							label="Mission Type"
							name={formInputName.missionType}
							className="select-form-item"
							labelCol={{ span: 24 }}
							wrapperCol={{ span: 24 }}
							rules={[{ required: true, message: MISSIONS_MISSION_TYPE_RULE_MESSAGE }]}
						>
							<Select
								showSearch
								optionFilterProp="children"
								placeholder="Click on the dropdown to select a test"
								notFoundContent={
									<Empty
										className="mission-type-empty-content"
										image={Empty.PRESENTED_IMAGE_SIMPLE}
									/>
								}
								onChange={(selectedMissionType: string) => {
									onMissionTypeChange(selectedMissionType).catch((err) => console.error(err));
								}}
							>
								{selectedMissionTypes?.map((missionTypeValue: MissionType, index: number) => (
									<Option value={missionTypeValue.id} key={index}>
										{missionTypeValue.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
					<Col className="gutter-row">
						<Form.Item
							label="Available Providers and Testers"
							name={formInputName.providers}
							className="cl-checkbox-wrapper select-form-item"
							labelCol={{ span: 24 }}
							wrapperCol={{ span: 24 }}
							rules={[{ required: true, validator: checkProvidersValidator }]}
						>
							<Tree
								checkable
								onCheck={onCheck}
								checkedKeys={checkedKeys}
								treeData={treeData}
								titleRender={(node) => renderNode(node, checkedKeys as [])}
							/>
						</Form.Item>
					</Col>
				</Row>

				<div className="wrappedSpinner">
					{isLoading && (
						<div>
							<Spinner />
						</div>
					)}
				</div>

				<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
					<Col className="gutter-row">
						<Form.Item>
							<Space wrap>
								<CLButton label={ApproveRejectButtonsText.CREATE} onClick={onCreate} />
								<CLButton
									label={ApproveRejectButtonsText.RESET}
									onClick={() => {
										const showPrompt = updatedProvidersPaymentDetails.length > 0;
										showPromptBeforeActions(() => {
											onResetInputs('All');
										}, showPrompt);
									}}
									className="cl-button-reject"
								/>
							</Space>
						</Form.Item>
					</Col>
				</Row>
			</Form>

			<CLModal
				className="cl-create-mission-modal"
				isScrollable={true}
				open={openMissionModal}
				title={
					<>
						<h5>
							<b>{providerPaymentDetailsToView?.title}</b>
						</h5>
						Please fill in the following details:
					</>
				}
				submitButtonLabel="Save"
				onClose={onModalClose}
				onSubmit={(): void => {
					formRef.current.requestSubmit();
				}}
			>
				{missionType === UkFasterPayment.id && (
					<UkFasterPaymentMissionForm
						data={providerPaymentDetailsToView as UKFasterPaymentDetails}
						countries={manageCountries}
						setRef={(ref: HTMLFormElement): void => {
							formRef.current = ref;
						}}
						onSubmit={handleFormSubmit}
					/>
				)}
				{[CvrpPayment.id, SvrPayment.id].includes(missionType) && (
					<VrpMissionForm
						data={providerPaymentDetailsToView as VrpProviderDetails}
						setRef={(ref: HTMLFormElement): void => {
							formRef.current = ref;
						}}
						onSubmit={handleFormSubmit}
					/>
				)}

				{missionType === SingleImmediatePayment.id && (
					<SingleImmediatePaymentMissionForm
						data={providerPaymentDetailsToView as SingleImmediatePaymentDetails}
						countries={manageCountries}
						setRef={(ref: HTMLFormElement): void => {
							formRef.current = ref;
						}}
						onSubmit={handleFormSubmit}
					/>
				)}
				{missionType === AccountTransaction.id && (
					<AccountsTransactionsForm
						data={providerPaymentDetailsToView as AccountTransactionDetails}
						setRef={(ref: HTMLFormElement): void => {
							formRef.current = ref;
						}}
						onSubmit={handleFormSubmit}
					/>
				)}
			</CLModal>

			<CLModal
				title="Abort Mission"
				open={showRoutePrompt || showActionPrompt}
				onSubmit={onPromptConfirm}
				submitButtonLabel="Ok"
				onClose={onPromptCancel}
				cancelButtonLabel="Cancel"
			>
				You have saved changes, but you have not used them. Are you sure you want to abort the mission creation?
			</CLModal>
		</div>
	);
};
export default CreateMission;
