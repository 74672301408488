import { CheckInfo } from '../models/antd/Tree/CheckInfo';
import {
	AccountTransaction,
	CvrpPayment,
	SingleImmediatePayment,
	SvrPayment,
	UkFasterPayment,
} from '../models/Missions/MissionType';
import { TreeDataNode } from '../models/Missions/TreeDataNodeType';
import { PaymentProviderDetailsType } from '../models/Payments';
import { AccountTransactionDetails } from '../models/Payments/AccountTransactions';
import { SingleImmediatePaymentDetails } from '../models/Payments/SingleImmediatePayment';
import { SingleImmediatePaymentDefaultValues } from '../models/Payments/SingleImmediatePaymentDefaultValues';
import { PAYMENT_PURPOSE_CODE } from '../models/Payments/TransactionRiskIndicators';
import { UKFasterPaymentDetails } from '../models/Payments/UkFasterPayment';
import { UkFasterPaymentDefaultValues } from '../models/Payments/UkFasterPaymentDefaultValues';
import { AmountLimit } from '../models/Payments/Vrp/Limits/AmountLimit';
import { PaymentLimit } from '../models/Payments/Vrp/Limits/PaymentLimit';
import { StartingAtType, VrpProviderDetails } from '../models/Payments/Vrp/VrpProviderDetails';
import {
	defaultAmountLimitValues,
	getDefaultPaymentLimitValues,
	VrpProviderDetailsDefaultValues,
} from '../models/Payments/Vrp/VrpProviderDetailsDefaultValues';
import { DEFAULT_CURRENCY } from '../resources/config';

export const removeUncheckedProviders = (
	nodeKeys: (string | number)[],
	paymentsDetails: PaymentProviderDetailsType[]
): PaymentProviderDetailsType[] => {
	for (let i = paymentsDetails.length - 1; i >= 0; --i) {
		if (!nodeKeys.includes(paymentsDetails[i].testerProviderId)) {
			paymentsDetails.splice(i, 1);
		}
	}

	return paymentsDetails;
};

export const getSelectedNodes = (type: string, info: CheckInfo<TreeDataNode>): TreeDataNode[] => {
	const multipleNodes = info.checkedNodes.filter((node: TreeDataNode) => !node.isParent);
	const singleNode = info.checked ? multipleNodes.filter((n) => n.key === info.node.key) : [];
	const multipleNodesMissions = [UkFasterPayment.id, SingleImmediatePayment.id, AccountTransaction.id];
	return multipleNodesMissions.includes(type) ? multipleNodes : singleNode;
};

export const findPaymentIndexByTesterProviderId = (
	details: PaymentProviderDetailsType[],
	id: string | number
): number => details.findIndex((payment) => payment.testerProviderId === id);

export const findPaymentProviderByTesterProviderId = (
	details: PaymentProviderDetailsType[],
	id: string | number
): PaymentProviderDetailsType => details.find((payment) => payment.testerProviderId === id);

export const getSelectedProviders = (
	treeData: TreeDataNode[],
	selectedNodes: TreeDataNode[],
	missionType: string
): TreeDataNode[] => {
	if (selectedNodes.length === 0) return [];

	const { testerId, key } = selectedNodes[0];
	const selectedParentTesterNode = treeData.find((parentTesterNode) => parentTesterNode.testerId === testerId);
	const allProvidersFromParentNode = selectedParentTesterNode.children;
	const shouldExcludeProviders = [CvrpPayment.id, SvrPayment.id].includes(missionType);

	return shouldExcludeProviders
		? allProvidersFromParentNode.filter((providerNode) => providerNode.key !== key)
		: allProvidersFromParentNode;
};

export const getFirstProviderBban = (providers: TreeDataNode[]): string => providers[0].details.bban;

export const createAccountNumberFromBban = (bban: string): string => (bban ? bban.slice(-8) : null);

export const createSortCodeFromBban = (bban: string): string => (bban ? bban.slice(0, -8).slice(-6) : null);

const bankIdsRequiringCountryCode = [
	'GB_VirginMoney',
	'GB_VirginMoneySandbox',
	'DE_DeutscheBank',
	'DE_Noris',
	'DE_Postbank',
	'DE_Ing',
];

const bankIdsRequiringTownName = ['GB_VirginMoney', 'GB_VirginMoneySandbox'];

export const isCountryCodeRequired = (bankId: string): boolean =>
	bankIdsRequiringCountryCode.some((id) => bankId.toLowerCase() === id.toLowerCase());

export const isTownNameRequired = (bankId: string): boolean =>
	bankIdsRequiringTownName.some((id) => bankId.toLowerCase() === id.toLowerCase());

export const getEmptyUkFasterPaymentsProviderDetails = (): UKFasterPaymentDetails => ({
	testerProviderId: null,
	title: '',
	amount: 0,
	currency: '',
	executionDate: undefined,
	referenceInformation: '',
	endToEndID: '',
	creditorName: '',
	creditorSortCode: '',
	townName: null,
	countryCode: null,
	creditorAccountNumber: '',
	creditorAccountType: '',
	isCreditorPrePopulated: '',
	paymentPurposeCode: '',
	isContractPresent: '',
	bankId: '',
});

export const getEmptyVrpProviderDetails = (): VrpProviderDetails => ({
	testerProviderId: null,
	title: '',
	validFrom: undefined,
	validTo: undefined,
	...defaultAmountLimitValues,
	creditorName: '',
	creditorSortCode: '',
	creditorAccountNumber: '',
	referenceInformation: '',
	queryDebtorAccount: false,
	...getDefaultPaymentLimitValues(),
	paymentDetails: {
		amount: 0,
		currency: '',
		endToEndId: '',
	},
	IsFirstPaymentApplicable: false,
});

export const getEmptySingleImmediatePaymentProviderDetails = (): SingleImmediatePaymentDetails => ({
	testerProviderId: null,
	title: '',
	amount: 0,
	currency: '',
	schedulePayment: false,
	executionDate: undefined,
	referenceInformation: '',
	endToEndId: '',
	creditorName: '',
	creditorSortCode: '',
	creditorAccountNumber: '',
	creditorAccountType: '',
	isCreditorPrePopulated: '',
	paymentPurposeCode: '',
	isContractPresent: '',
	paymentContextCode: '',
	thirdPartyAddress: '',
	thirdPartyIdentifier: '',
	thirdPartyType: '',
	countryCode: '',
	townName: '',
	bankId: '',
	bankCountryCode: '',
});

export const getEmptyAccountTransactionsProviderDetails = (): AccountTransactionDetails => ({
	testerProviderId: null,
	title: '',
	fetchTransactionsSince: undefined,
	numberOfAccountsToFetch: 0,
	numberOfTransactionsToFetch: 0,
	sourceAccountNumber: 0,
	fetchDetails: false,
});

export const getDefaultUkFasterPaymentsProviderDetails = (node: TreeDataNode): UKFasterPaymentDetails => ({
	testerProviderId: node.key,
	title: node.title as string,
	amount: UkFasterPaymentDefaultValues.amount,
	currency: UkFasterPaymentDefaultValues.currency,
	executionDate: null,
	referenceInformation: UkFasterPaymentDefaultValues.referenceInformation,
	endToEndID: crypto.randomUUID().substring(0, 35),
	creditorName: node.testerName,
	townName: node.townName,
	countryCode: node.countryCode,
	creditorSortCode: null,
	creditorAccountNumber: null,
	creditorAccountType: null,
	isCreditorPrePopulated: null,
	paymentPurposeCode: null,
	isContractPresent: null,
	bankId: node.details.bankId,
});

export const getDefaultAccountTransactionsProviderDetails = (node: TreeDataNode): AccountTransactionDetails => ({
	testerProviderId: node.key,
	title: node.title as string,
	fetchTransactionsSince: undefined,
	numberOfAccountsToFetch: 0,
	numberOfTransactionsToFetch: 0,
	sourceAccountNumber: 0,
	fetchDetails: false,
});

export const countryCurrencyMap: { [key: string]: string } = {
	DE: 'EUR',
	DK: 'DKK',
	GB: 'GBP',
	AT: 'EUR',
	NO: 'NOK',
	SE: 'SEK',
};

export const getDefaultSingleImmediatePaymentsProviderDetails = (node: TreeDataNode): SingleImmediatePaymentDetails => {
	const currency = countryCurrencyMap[node.details.countryCode] || SingleImmediatePaymentDefaultValues.currency;

	return {
		testerProviderId: node.key,
		title: node.title as string,
		amount: SingleImmediatePaymentDefaultValues.amount,
		currency,
		schedulePayment: false,
		executionDate: null,
		endToEndId: crypto.randomUUID().substring(0, 35),
		destinationId: node.details.destinationId,
		referenceInformation: SingleImmediatePaymentDefaultValues.referenceInformation,
		paymentContextCode: null,
		paymentPurposeCode: null,
		thirdPartyIdentifier: null,
		thirdPartyAddress: null,
		thirdPartyType: SingleImmediatePaymentDefaultValues.thirdPartyType,
		countryCode: node.countryCode,
		townName: node.townName,
		bankId: node.details.bankId,
		bankCountryCode: node.details.countryCode,
	};
};

const isNatWestBank = (bankId: string): boolean => ['GB_NatWest', 'GB_NatWestSandbox'].some((id) => bankId === id);

export const getSvrpProviderDetailsDefaultValues = (node: TreeDataNode, providerBban: string): VrpProviderDetails => {
	const hideTotalAmount = true;
	const { amount, currency, endToEndId } = VrpProviderDetailsDefaultValues;
	const amountLimitValues = structuredClone(defaultAmountLimitValues);
	amountLimitValues.perPayment.amount = 0.2;
	amountLimitValues.perPayment.currency = DEFAULT_CURRENCY;
	amountLimitValues.day.amount = 0.2;
	amountLimitValues.day.currency = DEFAULT_CURRENCY;
	amountLimitValues.day.startingType = StartingAtType.Consent;
	amountLimitValues.totalAmount.settings.hide = hideTotalAmount;

	return {
		testerProviderId: node.key,
		title: node.title as string,
		validFrom: undefined,
		validTo: undefined,
		...amountLimitValues,
		creditorName: node.testerName,
		creditorAccountNumber: createAccountNumberFromBban(providerBban),
		creditorSortCode: createSortCodeFromBban(providerBban),
		referenceInformation: 'SVRP Test',
		queryDebtorAccount: false,
		paymentPurposeCode: Object.keys(PAYMENT_PURPOSE_CODE).indexOf('OTHR'),
		paymentDetails: {
			amount,
			currency,
			endToEndId,
		},
		IsFirstPaymentApplicable: false,
		...getDefaultPaymentLimitValues(hideTotalAmount),
	};
};

export const getCvrpProviderDetailsDefaultValues = (node: TreeDataNode, providerBban: string): VrpProviderDetails => {
	const hideTotalAmount = isNatWestBank(node.details.bankId) === false;
	const { amount, currency, referenceInformation, endToEndId } = VrpProviderDetailsDefaultValues;
	const amountLimitValues = structuredClone(defaultAmountLimitValues);
	amountLimitValues.totalAmount.settings.hide = hideTotalAmount;

	return {
		testerProviderId: node.key,
		title: node.title as string,
		validFrom: undefined,
		validTo: undefined,
		...amountLimitValues,
		creditorName: node.testerName,
		creditorAccountNumber: createAccountNumberFromBban(providerBban),
		creditorSortCode: createSortCodeFromBban(providerBban),
		referenceInformation,
		queryDebtorAccount: false,
		paymentPurposeCode: Object.keys(PAYMENT_PURPOSE_CODE).indexOf('OTHR'),
		paymentDetails: {
			amount,
			currency,
			endToEndId,
		},
		IsFirstPaymentApplicable: false,
		...getDefaultPaymentLimitValues(hideTotalAmount),
	};
};

export const getDefaultVrpProviderDetails = (
	node: TreeDataNode,
	filteredProviders: TreeDataNode[],
	vrpType: string
): VrpProviderDetails => {
	const providerBban = getFirstProviderBban(filteredProviders);
	switch (vrpType) {
		case SvrPayment.id:
			return getSvrpProviderDetailsDefaultValues(node, providerBban);
		default:
			return getCvrpProviderDetailsDefaultValues(node, providerBban);
	}
};

export const getCheckedAmountLimits = (limits: AmountLimit[]): AmountLimit[] =>
	limits
		.filter((amountLimit) => amountLimit?.isChecked)
		.map((filtered) => ({
			amount: +filtered.amount,
			currency: filtered.currency,
			limitType: filtered.settings.id,
			startingType: filtered.startingType,
		}));

export const getCheckedPaymentLimits = (limits: PaymentLimit[]): PaymentLimit[] =>
	limits
		.filter((paymentDetail) => paymentDetail?.isChecked)
		.map((filtered) => ({
			numberOfPayments: +filtered.numberOfPayments,
			paymentLimit: filtered.settings.id,
			startingType: filtered.startingType,
		}));

export const extractLimitDataSources = (
	data: VrpProviderDetails
): { amountLimitsSource: AmountLimit[]; paymentLimitsSource: PaymentLimit[] } => {
	const { totalAmount, perPayment, day, week, fortnight, month, halfAYear, year } = data;
	const amountLimitsSource = [totalAmount, perPayment, day, week, fortnight, month, halfAYear, year];

	const { paymentDay, paymentWeek, paymentFortnight, paymentMonth, paymentHalfAYear, paymentYear } = data;
	const paymentLimitsSource = [
		paymentDay,
		paymentWeek,
		paymentFortnight,
		paymentMonth,
		paymentHalfAYear,
		paymentYear,
	];
	return { amountLimitsSource, paymentLimitsSource };
};

export const isTotalAmountHidden = (data: VrpProviderDetails): boolean => data.totalAmount.settings.hide === true;
