import './spinner.css';

import React from 'react';

const Spinner = (): JSX.Element => (
	<div className="spin3">
		<div className="c1"></div>
		<div className="c2"></div>
		<div className="c3"></div>
	</div>
);
export default Spinner;
