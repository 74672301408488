import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

export const insightsPages = new Map<string, string>([
	['/', 'Dashboard'],
	['/admin-tester-approval', 'Admin Approve'],
	['/create-mission', 'Create Mission'],
	['/results', 'Results'],
	['/admin-dashboard-page', 'Admin Dashboard'],
	['/tester-dashboard-page', 'Tester Dashboard'],
	['/tester-profile-page', 'Tester Profile'],
]);

export function setMetrics(insights: ReactPlugin, route: string): void {
	const page = insightsPages.get(route) || 'App';
	const additionalProperties = { 'Component Name': page };

	insights.trackMetric(
		{
			average: 1000,
			name: 'React Component Engaged Time (seconds)',
			sampleCount: 1,
		},
		additionalProperties
	);

	insights.trackPageView({
		name: `${page} Page`,
	});
}
