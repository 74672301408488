import { TablePagination } from '@mui/material';
import { RowData, Table } from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';

import { defaultRowsPerPageOptions } from '../../resources/config';
import GenericTablePaginationActions from './GenericTablePaginationActions';

export default function GenericTablePagination({ table, data }: { table: Table<RowData>; data: RowData[] }) {
	const { pageSize, pageIndex } = table.getState().pagination;

	const rowsPerPageOptions = [...defaultRowsPerPageOptions, { label: 'All', value: data.length }];

	const [pageSizeSelected, setPageSizeSelected] = useState<number | { value: number }>(pageSize);

	useEffect(() => {
		if (typeof pageSizeSelected === 'object' && pageSize !== data.length) {
			table.setPageSize(data.length);
		}
	}, [table.getState().pagination]);

	return (
		<TablePagination
			sx={{
				'.MuiInputBase-root': {
					top: -7,
				},
				'.MuiTablePagination-displayedRows': {
					top: -7,
				},
				'.MuiButtonBase-root': {
					top: -7,
				},
			}}
			rowsPerPageOptions={rowsPerPageOptions}
			component="div"
			count={table.getFilteredRowModel().rows.length}
			rowsPerPage={pageSize}
			page={pageIndex}
			SelectProps={{
				inputProps: { 'aria-label': 'rows per page' },
				native: true,
			}}
			onPageChange={(_, page) => {
				table.setPageIndex(page);
			}}
			onRowsPerPageChange={(e) => {
				const size = e.target.value ? Number(e.target.value) : 15;
				const selectedOption = rowsPerPageOptions.find((option) =>
					typeof option === 'object' ? option.value === size : option === size
				);

				table.setPageSize(size);
				setPageSizeSelected(selectedOption);
			}}
			ActionsComponent={GenericTablePaginationActions}
		/>
	);
}
