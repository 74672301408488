import './Dropdowns.css';

import { Col, Empty, Form, Row, Select } from 'antd';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';

import useAxios from '../../../../hooks/axios';
import { Country } from '../../../../models/Countries/Country';
import { MissionType, MissionTypeCode, MissionTypeID, missionTypes } from '../../../../models/Missions/MissionType';
import { PaymentRailValues } from '../../../../models/Missions/PaymentRail';

export interface IFilters {
	countryCode: string;
	missionType: MissionTypeCode;
	schemeId: string;
}

interface Props {
	onFiltersChange: (filters: IFilters) => void;
}

export default function MarketDropdown(props: Props): JSX.Element {
	const [form] = Form.useForm();
	const { Option } = Select;
	const [markets, setMarkets] = useState<Country[]>([]);
	const [selectedMissionTypes, setSelectedMissionTypes] = useState<MissionType[]>();
	const { response: marketsResponse, error: marketsError }: { response: AxiosResponse<Country[]>; error: Error } =
		useAxios({
			method: 'GET',
			url: '/api/countries',
			headers: {
				accept: '*/*',
			},
		});

	useEffect(() => {
		const load = (): void => {
			if (marketsResponse !== undefined) {
				setMarkets(marketsResponse.data);
			} else if (marketsError !== undefined) {
				setMarkets([]);
			}
		};
		load();
	}, [marketsResponse, marketsError]);

	useEffect(() => {
		form.resetFields(['schemeId']);
	}, [form.getFieldValue('missionType')]);

	useEffect(() => {
		props.onFiltersChange({ ...form.getFieldsValue(), missionType: null } as IFilters);
	}, [form.getFieldValue('countryCode')]);

	useEffect(() => {
		if (!form.getFieldInstance('schemeId')) {
			props.onFiltersChange({ ...form.getFieldsValue(), schemeId: null } as IFilters);
		}
	}, [form.getFieldInstance('schemeId')]);

	const handleClear = (): void => {
		form.resetFields();
	};

	const resetMissionTypesValues = (): void => {
		form.resetFields(['missionType']);
		setSelectedMissionTypes([]);
	};

	const onMarketChange = (): void => {
		resetMissionTypesValues();
		setSelectedMissionTypes(missionTypes);
	};

	return (
		<div>
			<Form
				className="find-missions-form"
				form={form}
				onValuesChange={(_changedValues, allValues: IFilters): void => {
					props.onFiltersChange(allValues);
				}}
			>
				<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
					<Col className="gutter-row" span={8}>
						<Form.Item
							label="Market"
							name="countryCode"
							className="select-form-item"
							labelCol={{ span: 24 }}
							wrapperCol={{ span: 24 }}
						>
							<Select
								allowClear
								showSearch
								optionFilterProp="children"
								placeholder="Click on the dropdown to select a market"
								onClear={handleClear}
								onChange={(): void => onMarketChange()}
							>
								{markets?.map((market: Country, index: number) => (
									<Select.Option value={market.countryCode} key={index}>
										{market.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
					<Col className="gutter-row" span={8}>
						<Form.Item
							label="Mission Type"
							name="missionType"
							className="select-form-item"
							labelCol={{ span: 24 }}
							wrapperCol={{ span: 24 }}
						>
							<Select
								showSearch
								placeholder="Select a market first and then select a mission type"
								notFoundContent={
									<Empty className="mission-empty-content" image={Empty.PRESENTED_IMAGE_SIMPLE} />
								}
								onChange={(): void => form.setFieldValue('schemeId', '')}
							>
								{selectedMissionTypes?.map((missionTypeValue: MissionType, index: number) => (
									<Option value={missionTypeValue.id} key={index}>
										{missionTypeValue.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
				</Row>

				{form.getFieldValue('missionType') === MissionTypeID.SingleImmediatePayment && (
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
						<Col className="gutter-row" span={8}>
							<Form.Item
								label="Payment Rail"
								name="schemeId"
								className="select-form-item"
								labelCol={{ span: 24 }}
								wrapperCol={{ span: 24 }}
							>
								<Select
									showSearch
									optionFilterProp="children"
									placeholder="Click on the dropdown to select a payment rail"
								>
									{PaymentRailValues[form.getFieldValue('countryCode') as string]?.map(
										(paymentRail: string, index) => (
											<Option value={paymentRail} key={`payment_rail_${index}`}>
												{paymentRail}
											</Option>
										)
									)}
								</Select>
							</Form.Item>
						</Col>
					</Row>
				)}
			</Form>
		</div>
	);
}
