import { useContext, useEffect } from 'react';

import { PageTitleContext } from '../../layout/Layout';
import { pageTitles } from '../../resources/config';

export default function Unauthorized() {
	const { setPageTitle } = useContext(PageTitleContext);

	useEffect(() => {
		setPageTitle(pageTitles.notAuthorized);
	});

	return <></>;
}
