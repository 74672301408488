import { splunkBaseUrl } from '../resources/config';

export const generateSplunkUrl = (userHash: string, bankId?: string, modifyDate?: Date): URL => {
	let query = `("fields.UserHash"="${userHash}" OR "fields.PaymentId"="${userHash}" OR "fields.UserReference"="${userHash}")`;

	if (bankId && bankId.trim() !== '') {
		query += ` AND ("fields.BankId"="${bankId}" OR "fields.ProviderId"="${bankId}")`;
	}

	if (modifyDate) {
		const modifyDateEpoch = Math.floor(new Date(modifyDate).getTime() / 1000);

		// 30 minutes earliest and after (1800 seconds)
		const earliest = modifyDateEpoch - 1800;
		const latest = modifyDateEpoch + 1800;

		query += ` AND earliest=${earliest} AND latest=${latest}`;
	}

	return new URL(`${splunkBaseUrl}${encodeURIComponent(query)}`);
};
