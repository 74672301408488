import { Col, Empty, Row } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';
import { forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { DisplayErrorToaster } from '../../../../cl-shared-components/Toaster/DisplayToaster';
import useAxios from '../../../../hooks/axios';
import { MissionTypeCode } from '../../../../models/Missions/MissionType';
import { dateFormatWithTime } from '../../../../resources/config';
import { DEFAULT_ERROR_MESSAGE } from '../../../../resources/Errors';

interface IPaymentStatus {
	code: string;
	createdDate: string;
}

export interface ChildComponentRef {
	triggerFetchDataFromServer: () => Promise<void>;
}

interface IPaymentStatusProps {
	missionId: number;
	missionType: string;
}

export const PaymentStatus = forwardRef((props: IPaymentStatusProps, ref: Ref<ChildComponentRef>): JSX.Element => {
	const { fetchData } = useAxios({});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [data, setData] = useState<IPaymentStatus[]>([]);
	const scrollRef = useRef<HTMLDivElement>(null);

	const fetchPaymentStatusHistory = async (): Promise<void> => {
		try {
			setIsLoading(true);
			const result = (await fetchData({
				method: 'GET',
				url: '/api/paymentstatus/history',
				headers: {
					accept: '*/*',
				},
				params: {
					missionId: props.missionId,
				},
			})) as AxiosResponse<IPaymentStatus[]>;
			setData(result.data);
		} catch (error) {
			console.error(error);
			DisplayErrorToaster(DEFAULT_ERROR_MESSAGE);
		} finally {
			setIsLoading(false);
		}
	};

	const getUkFasterPaymentStatus = async (missionId: number): Promise<AxiosResponse | AxiosError> =>
		(await fetchData({
			method: 'GET',
			url: '/api/ukfasterpayments/sync-status',
			headers: {
				accept: '*/*',
			},
			params: {
				missionId,
			},
		})) as AxiosResponse | AxiosError;

	const getVrpStatus = async (missionId: number): Promise<AxiosResponse | AxiosError> =>
		(await fetchData({
			method: 'GET',
			url: '/api/vrp/sync-status',
			headers: {
				accept: '*/*',
			},
			params: {
				missionId,
			},
		})) as AxiosResponse | AxiosError;

	const getSipStatus = async (missionId: number): Promise<AxiosResponse | AxiosError> =>
		(await fetchData({
			method: 'GET',
			url: '/api/apv3/sip/sync-status',
			headers: {
				accept: '*/*',
			},
			params: {
				missionId,
			},
		})) as AxiosResponse | AxiosError;

	const getPaymentStatus = async (missionType: string, missionId: number): Promise<void> => {
		try {
			setIsLoading(true);
			switch (missionType) {
				case MissionTypeCode.UkFasterPayment:
					await getUkFasterPaymentStatus(missionId);
					break;
				case MissionTypeCode.Cvrp:
				case MissionTypeCode.Svrp:
					await getVrpStatus(missionId);
					break;
				case MissionTypeCode.SingleImmediatePayment:
					await getSipStatus(missionId);
					break;
				default:
			}
		} catch (error) {
			DisplayErrorToaster(DEFAULT_ERROR_MESSAGE);
		}
	};

	useEffect(() => {
		void fetchPaymentStatusHistory();
		return () => {
			setData([]);
		};
	}, [props.missionId]);

	useImperativeHandle(ref, () => ({
		triggerFetchDataFromServer: async () => {
			if (scrollRef.current) {
				scrollRef.current.scrollTo({ top: 0 });
			}
			await getPaymentStatus(props.missionType, props.missionId);
			await fetchPaymentStatusHistory();
		},
	}));

	return (
		<>
			<Row className="cl-mission-admin-details-title">
				<Col xs={24} lg={24}>
					<h6>Payment Status</h6>
				</Col>
			</Row>

			{data.length > 0 ? (
				<div
					className="cl-mission-admin-details"
					style={{ height: '300px', overflowY: 'auto' }}
					ref={scrollRef}
				>
					{isLoading && <div>Loading...</div>}
					{data.map((row, key) => (
						<Row key={key} className="tr">
							<Col xs={12} lg={12} key={`code_${key}`}>
								{row.code || 'NA'}
							</Col>
							<Col xs={12} lg={12} key={`message_${key}`}>
								{moment(row.createdDate).format(dateFormatWithTime.format) || 'NA'}
							</Col>
						</Row>
					))}
				</div>
			) : (
				<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
			)}
		</>
	);
});
