import { APv3, Core, Enterprise } from './ApplicationLayerType';

export type MissionType = {
	name?: string;
	id?: string;
	market?: string[];
	application?: number[];
};

export const COMMERCIAL_VARIABLE_RECURRING_PAYMENTS = 'Commercial Variable Recurring Payments';
export const UK_FASTER_PAYMENTS = 'UK Faster Payments';
export const SINGLE_IMMEDIATE_PAYMENTS = 'Single Immediate Payments';
export const SWEEPING_VARIABLE_RECURRING_PAYMENTS = 'Sweeping Variable Recurring Payments';
export const ACCOUNTS_TRANSACTIONS = 'Accounts and Transactions';

export enum MissionTypeCode {
	UkFasterPayment = 'UkFasterPayment',
	Cvrp = 'Cvrp',
	SingleImmediatePayment = 'SingleImmediatePayment',
	Svrp = 'Svrp',
	AccountTransaction = 'AccountsAndTransactions',
}

export enum MISSION_TYPE {
	UkFasterPayment = 'UkFasterPayment',
	Cvrp = 'Commercial',
	SingleImmediatePayment = 'SingleImmediatePayment',
	Svrp = 'Sweeping',
	AccountTransaction = 'AccountsAndTransactions',
}

export enum MissionTypeID {
	UkFasterPayment = '0',
	Cvrp = '1',
	SingleImmediatePayment = '2',
	Svrp = '3',
	AccountTransaction = '4',
}

// TODO: propose to create a nomenclature
// consider missionTypeCode as UID, and pass as a reference
export const missionTypes: MissionType[] = [
	{
		name: UK_FASTER_PAYMENTS,
		id: MissionTypeID.UkFasterPayment,
		market: ['GB'],
		application: [Core.id],
	},
	{
		name: COMMERCIAL_VARIABLE_RECURRING_PAYMENTS,
		id: MissionTypeID.Cvrp,
		market: ['GB'],
		application: [Core.id, APv3.id],
	},
	{
		name: SINGLE_IMMEDIATE_PAYMENTS,
		id: MissionTypeID.SingleImmediatePayment,
		market: ['GB', 'DE', 'DK', 'AT', 'NO', 'SE'],
		application: [APv3.id],
	},
	{
		name: SWEEPING_VARIABLE_RECURRING_PAYMENTS,
		id: MissionTypeID.Svrp,
		market: ['GB'],
		application: [Core.id],
	},
	{
		name: ACCOUNTS_TRANSACTIONS,
		id: MissionTypeID.AccountTransaction,
		market: [
			'GB',
			'CH',
			'SE',
			'DK',
			'AT',
			'BE',
			'BG',
			'HR',
			'CY',
			'CZ',
			'EE',
			'FO',
			'FI',
			'FR',
			'DE',
			'GI',
			'GR',
			'GL',
			'GG',
			'HU',
			'IS',
			'IE',
			'IM',
			'IT',
			'JE',
			'LV',
			'LI',
			'LT',
			'LU',
			'MT',
			'NL',
			'NO',
			'PL',
			'PT',
			'RO',
			'SM',
			'SK',
			'SI',
			'ES',
		],

		application: [Enterprise.id],
	},
];
export const [UkFasterPayment, CvrpPayment, SingleImmediatePayment, SvrPayment, AccountTransaction] = missionTypes;
