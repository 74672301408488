import './Licenses.css';

import { ILicense } from '../../models/License/License';

export const AiiaLicense = (props: ILicense): JSX.Element => (
	<div className="cl-license">
		By clicking the button {props.buttonPosition}, you consent to the processing of your financial data, for the
		purpose of quality control the connection to provider. We will create a payment on your behalf, and for that
		purpose we might process the list of accounts you have access to. For more information about the processing of
		your data, including information on how to revoke your consent, please see the{' '}
		<a href="/privacy-policy">privacy policy</a>.
	</div>
);
