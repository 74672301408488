/* eslint-disable no-mixed-spaces-and-tabs */
import './AdminMissionsTable.css';

import { useAuth0 } from '@auth0/auth0-react';
import { Link } from '@mui/material';
import { CellContext, ColumnDef, Row } from '@tanstack/react-table';
import { Empty } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { CLButton } from '../../../../cl-shared-components/Buttons';
import GenericTableWithServerSidePagination from '../../../../cl-shared-components/GenericTable/GenericTableWithServerSidePagination';
import { TableParams } from '../../../../cl-shared-components/GenericTable/TableParams';
import { onPaginationUpdate } from '../../../../cl-shared-components/GenericTable/TableUtils';
import CLModal from '../../../../cl-shared-components/Modal/CLModal';
import { DisplayErrorToaster, DisplaySuccessToaster } from '../../../../cl-shared-components/Toaster/DisplayToaster';
import useAxios from '../../../../hooks/axios';
import { PageTitleContext } from '../../../../layout/Layout';
import { AdminMissionsTable } from '../../../../models/AdminMissions/AdminMissionsTable';
import { AdminMissionTypeDisplay } from '../../../../models/AdminMissions/AdminMissionType';
import { PaginationUpdateParams } from '../../../../models/GenericTable/PaginationUpdateParams';
import { ErrorDetails } from '../../../../models/HttpResponse/ErrorDetails';
import StatusCodes from '../../../../models/HttpResponse/StatusCodes';
import { AssignedMissions } from '../../../../models/Missions/AssignedMissions';
import MappedMissionStatus from '../../../../models/Missions/MappedMissionStatus';
import MissionStatus from '../../../../models/Missions/MissionStatus';
import { MissionTypeCode } from '../../../../models/Missions/MissionType';
import { CONSENT_STATUS, ConsentStatusValues } from '../../../../models/Missions/VrpConsentDetailsType';
import { VrpInitiatePaymentDetails } from '../../../../models/Payments/Vrp/VrpInitiatePayment';
import UserType from '../../../../models/User/UserType';
import config, { initialTableParams, pageTitles } from '../../../../resources/config';
import { DEFAULT_ERROR_MESSAGE } from '../../../../resources/Errors';
import { CONSENT_REVOKED_SUCCESSFULLY, PAYMENT_SUCCESS_MESSAGE } from '../../../../resources/Success';
import getNagCoreEnvironmentValue from '../../../../utils/EnvUtils';
import { cVRPExpirationDateCheck, formatDateWithTime } from '../../../../utils/VrpConsentUtils';
import { MissionDetails } from './MissionDetails';
import { ChildComponentRef, PaymentStatus } from './PaymentStatus';
import VrpInitiatePaymentForm from './VrpPaymentDetails/VrpInitiatePaymentForm';

const AdminMissionsProfile = () => {
	const { fetchData } = useAxios({});
	const { setPageTitle } = useContext(PageTitleContext);
	initialTableParams.sortBy = 'CreatedDate';
	initialTableParams.isSortAscending = false;

	const { getIdTokenClaims } = useAuth0();
	const [tableParams, setTableParams] = React.useState<TableParams>({ ...initialTableParams });
	const [adminMissions, setAdminMissions] = useState<AdminMissionsTable[]>([]);
	const [totalCount, setTotalCount] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [openSeeDetailsModal, setOpenSeeDetailsModal] = useState<boolean>(false);
	const [openVRPInitiateModal, setOpenVRPInitiateModal] = useState<boolean>(false);
	const [currentUser, setCurrentUser] = useState<UserType>();

	const [missionToView, setMissionToView] = useState<Row<AdminMissionsTable>>(null);
	const paymentStatusRef = useRef<ChildComponentRef>(null);
	const [getStatusIsDisabled, setGetStatusIsDisabled] = useState<boolean>(false);

	const formRef = useRef<HTMLFormElement>(null);

	const getAdminMissions = async (userType: UserType): Promise<void> => {
		try {
			setIsLoading(true);
			const result = (await fetchData({
				method: 'GET',
				url: '/api/admin/missions',
				headers: {
					accept: '*/*',
				},
				params: tableParams,
			})) as AxiosResponse<AssignedMissions<AdminMissionsTable[]>>;

			const tempMission: AdminMissionsTable[] =
				(result.data?.totalCount || 0) > 0
					? result.data?.missions.map((item: AdminMissionsTable) => ({
							role: userType,
							...item,
					  }))
					: [];

			setAdminMissions(tempMission || []);
			setTotalCount(result.data?.totalCount || 0);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			console.error(error);
		}
	};

	const getTesterMissions = async (userType: UserType): Promise<void> => {
		try {
			setIsLoading(true);
			const result = (await fetchData({
				method: 'GET',
				url: '/api/missions/testerdashboardmissions',
				headers: {
					accept: '*/*',
				},
				params: tableParams,
			})) as AxiosResponse<AssignedMissions<AdminMissionsTable[]>>;

			const tempMission: AdminMissionsTable[] =
				(result.data?.totalCount || 0) > 0
					? result.data?.missions.map((item) => ({
							role: userType,
							...item,
					  }))
					: [];

			setAdminMissions(tempMission || []);
			setTotalCount(result.data?.totalCount || 0);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			console.error(error);
		}
	};

	const getMissionTableAPI = async (): Promise<void> => {
		const data = await getIdTokenClaims();
		if (data !== undefined) {
			const roles = data[`${config.domain}/roles`] as string[];
			if (roles.includes(UserType.ADMINISTRATOR)) {
				setCurrentUser(UserType.ADMINISTRATOR);
				await getAdminMissions(UserType.ADMINISTRATOR);
			} else if (roles.includes(UserType.TESTER)) {
				setCurrentUser(UserType.TESTER);
				await getTesterMissions(UserType.TESTER);
			}
		}
	};

	useEffect(() => {
		setPageTitle(pageTitles.adminMissionsTable);
		void getMissionTableAPI();
	}, [tableParams.isSortAscending, tableParams.sortBy, tableParams.pageSize, tableParams.page]);

	const isMissionType = (row: Row<AdminMissionsTable>, missionTypes: string[]): boolean =>
		missionTypes.includes(row?.original?.type.toUpperCase());

	const triggerSeeDetailsOpenModal = (record: CellContext<AdminMissionsTable, unknown>): void => {
		setMissionToView(record.row);
		setOpenSeeDetailsModal(true);
	};

	const isViewActionDisabled = (record: CellContext<AdminMissionsTable, unknown>) => {
		const isTester = record?.row.original.role === UserType.TESTER;
		return (
			isTester &&
			!isMissionType(record?.row, [MissionTypeCode.Cvrp.toUpperCase(), MissionTypeCode.Svrp.toUpperCase()])
		);
	};

	const renderSeeDetailsCell = (record: CellContext<AdminMissionsTable, unknown>): JSX.Element => {
		const className = `cell-content cell-content-button ${isViewActionDisabled(record) ? 'cl-disabled-text' : ''}`;
		return (
			<Link
				className={className}
				onClick={(): void => (isViewActionDisabled(record) ? undefined : triggerSeeDetailsOpenModal(record))}
			>
				See Details
			</Link>
		);
	};

	const columns = React.useMemo<ColumnDef<AdminMissionsTable>[]>(
		() => [
			{
				header: 'Provider',
				accessorFn: (row: AdminMissionsTable): string => row.bankName,
				id: 'BankName',
			},
			{
				header: 'Market',
				accessorFn: (row: AdminMissionsTable): string => row.market,
				id: 'Market',
			},
			{
				header: 'Mission ID',
				accessorFn: (row: AdminMissionsTable): number => row.id,
				id: 'Id',
			},
			{
				header: 'Mission',
				accessorFn: (row) => AdminMissionTypeDisplay[row.type as keyof typeof AdminMissionTypeDisplay],
				id: 'Type',
			},
			{
				header: 'Tester',
				enableSorting: false,
				accessorFn: (row: AdminMissionsTable): string => row.testerName,
				id: 'TesterName',
			},
			{
				header: 'Status',
				accessorFn: (row: AdminMissionsTable): string => MappedMissionStatus[row.status],
				id: 'Status',
			},
			{
				header: 'Requested Date',
				accessorFn: (row: AdminMissionsTable): string => formatDateWithTime(row.createdDate),
				id: 'CreatedDate',
			},
			{
				header: (): JSX.Element => <div className="cursor-pointer select-none">Action</div>,
				enableSorting: false,
				cell: renderSeeDetailsCell,
				id: 'action',
			},
		],
		[]
	);

	const onModalClose = (): void => {
		setOpenSeeDetailsModal(false);
		setMissionToView(null);
	};

	const onVRPModalClose = (): void => {
		setOpenVRPInitiateModal(!openVRPInitiateModal);
		setOpenSeeDetailsModal(!openSeeDetailsModal);
	};

	const handleApiResponse = async (result: AxiosResponse | AxiosError, successMessage: string): Promise<void> => {
		if (result.status === StatusCodes.OK) {
			DisplaySuccessToaster(successMessage);
			await getMissionTableAPI();
		} else {
			const resultError = result as AxiosError;
			const error = resultError.response.data as ErrorDetails;
			DisplayErrorToaster(error.detail);
		}
		setOpenSeeDetailsModal(false);
		setOpenVRPInitiateModal(false);
		setIsLoading(false);
	};

	const handleApiErrorResponse = (error: unknown): void => {
		setIsLoading(false);
		DisplayErrorToaster(DEFAULT_ERROR_MESSAGE);
		console.error(error);
	};

	const revokeConsent = async (): Promise<void> => {
		const data = {
			missionId: missionToView?.original.id,
		};

		try {
			setIsLoading(true);
			const result = (await fetchData({
				method: 'POST',
				url: '/api/vrp/revoke',
				data,
			})) as AxiosResponse | AxiosError;

			await handleApiResponse(result, CONSENT_REVOKED_SUCCESSFULLY);
		} catch (error) {
			handleApiErrorResponse(error);
		}
	};

	const revokeAPV3Consent = async (): Promise<void> => {
		const payloadData = {
			missionId: missionToView?.original.id,
		};

		try {
			setIsLoading(true);
			const result = (await fetchData({
				method: 'POST',
				url: '/api/cvrp/delete',
				data: payloadData,
			})) as AxiosResponse | AxiosError;

			if (result.status === StatusCodes.OK) {
				await handleApiResponse(result, CONSENT_REVOKED_SUCCESSFULLY);
			} else {
				const resultError = result as AxiosError;
				const error = resultError.response.data as ErrorDetails;
				DisplayErrorToaster(error.detail);
			}
		} catch (error) {
			handleApiErrorResponse(error);
		}
	};

	const initiatePayment = async (): Promise<void> => {
		const data = {
			missionId: missionToView?.original.id,
			environment: getNagCoreEnvironmentValue(),
			endUserContext: {
				language: navigator.language,
				userAgent: navigator.userAgent,
				deviceUuid: uuidv4(),
			},
		};

		try {
			setIsLoading(true);
			const result = (await fetchData({
				method: 'POST',
				url: '/api/vrp/execute',
				data,
			})) as AxiosResponse | AxiosError;

			await handleApiResponse(result, PAYMENT_SUCCESS_MESSAGE);
		} catch (error) {
			handleApiErrorResponse(error);
		}
	};

	const handleFormSubmit = async (data: VrpInitiatePaymentDetails): Promise<void> => {
		const payloadData = {
			missionId: missionToView?.original.id,
			environment: getNagCoreEnvironmentValue(),
			createPayment: {
				amount: Number(data.amount),
				currency: data.currency,
				paymentRail: 'UkFasterPayments',
				identifier: 'CL',
				endUser: {
					isPresent: true,
				},
			},
			endUserContext: {
				language: navigator.language,
				userAgent: navigator.userAgent,
				deviceUuid: uuidv4(),
			},
		};

		try {
			setIsLoading(true);
			const result = (await fetchData({
				method: 'POST',
				url: '/api/cvrp/payment',
				data: payloadData,
			})) as AxiosResponse | AxiosError;

			await handleApiResponse(result, PAYMENT_SUCCESS_MESSAGE);
		} catch (error) {
			handleApiErrorResponse(error);
		}
	};

	const getPaymentStatus = async (): Promise<void> => {
		if (paymentStatusRef.current) {
			setGetStatusIsDisabled(true);
			await paymentStatusRef.current.triggerFetchDataFromServer();
			setGetStatusIsDisabled(false);
		}
	};

	const renderActions = (): JSX.Element => (
		<div className="cl-modal-actions">
			<CLButton
				hidden={
					!isMissionType(missionToView, [
						MissionTypeCode.UkFasterPayment.toUpperCase(),
						MissionTypeCode.Cvrp.toUpperCase(),
						MissionTypeCode.Svrp.toUpperCase(),
						MissionTypeCode.SingleImmediatePayment.toUpperCase(),
					])
				}
				label="Get Status"
				onClick={() => getPaymentStatus()}
				disabled={getStatusIsDisabled || missionToView?.original?.status !== MissionStatus.COMPLETE_SUCCEEDED}
			/>
			{isMissionType(missionToView, [MissionTypeCode.Cvrp.toUpperCase(), MissionTypeCode.Svrp.toUpperCase()]) && (
				<>
					{/* TODO: After fixing the cVRP mission start things, we have to remove this button and set the condition based on type. */}
					{currentUser !== UserType.TESTER && (
						<CLButton
							label="Initiate payment"
							onClick={
								missionToView?.original?.consentDetails.application === 'Core'
									? initiatePayment
									: onVRPModalClose
							}
							className="cl-button-alert"
							disabled={
								missionToView?.original?.consentDetails.consentStatus !== ConsentStatusValues.ACTIVE ||
								missionToView?.original?.status.toLowerCase() === CONSENT_STATUS.FAILED.toLowerCase() ||
								cVRPExpirationDateCheck(missionToView?.original?.consentDetails.validTo) < 0
							}
						/>
					)}
					<CLButton
						label="Revoke consent"
						onClick={
							missionToView?.original?.consentDetails.application === 'Core'
								? revokeConsent
								: revokeAPV3Consent
						}
						className="cl-button-alert"
						disabled={
							missionToView?.original?.consentDetails.consentStatus !== ConsentStatusValues.ACTIVE ||
							missionToView?.original?.status.toLowerCase() === CONSENT_STATUS.FAILED.toLowerCase() ||
							cVRPExpirationDateCheck(missionToView?.original?.consentDetails.validTo) < 0
						}
					/>
				</>
			)}
		</div>
	);

	return (
		<div>
			{adminMissions.length > 0 ? (
				<GenericTableWithServerSidePagination
					pageCount={Math.ceil(totalCount / tableParams.pageSize)}
					isLoading={isLoading}
					totalCount={totalCount}
					defaultSortBy={[{ id: initialTableParams.sortBy, desc: true }]}
					onPaginationUpdate={(params: PaginationUpdateParams): void => {
						setTableParams(
							onPaginationUpdate({ tableParams: params, stateTableParams: tableParams, totalCount })
						);
					}}
					{...{ data: adminMissions, columns }}
				/>
			) : (
				<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
			)}

			<CLModal
				bodyStyle={{ height: '65vh', overflowY: 'auto' }}
				open={openSeeDetailsModal}
				onClose={onModalClose}
				title={
					<h5>
						<b>Mission #{missionToView?.original?.id}</b>
					</h5>
				}
				renderFooter={renderActions}
			>
				<>
					<MissionDetails mission={missionToView} />
					{isMissionType(missionToView, [
						MissionTypeCode.UkFasterPayment.toUpperCase(),
						MissionTypeCode.Cvrp.toUpperCase(),
						MissionTypeCode.Svrp.toUpperCase(),
						MissionTypeCode.SingleImmediatePayment.toUpperCase(),
					]) && (
						<PaymentStatus
							missionId={missionToView?.original?.id}
							missionType={missionToView?.original.type}
							ref={paymentStatusRef}
						/>
					)}
				</>
			</CLModal>

			<CLModal
				className="cl-create-mission-modal"
				// isScrollable={true}
				open={openVRPInitiateModal}
				title={
					<>
						<h5>
							<b>Initiate Payment</b>
						</h5>
					</>
				}
				submitButtonLabel="OK"
				onClose={onVRPModalClose}
				onSubmit={(): void => {
					formRef.current.requestSubmit();
				}}
			>
				<VrpInitiatePaymentForm
					data={null}
					consentDetails={missionToView?.original?.consentDetails}
					setRef={(ref: HTMLFormElement): void => {
						formRef.current = ref;
					}}
					onSubmit={handleFormSubmit}
				/>
			</CLModal>
		</div>
	);
};
export default AdminMissionsProfile;
