import { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';

import { DisplaySuccessToaster, DisplayWarningToaster } from '../cl-shared-components/Toaster/DisplayToaster';
import { ErrorDetails } from '../models/HttpResponse/ErrorDetails';
import StatusCodes from '../models/HttpResponse/StatusCodes';
import { applicationIdType } from '../models/Missions/ApplicationLayerType';
import CreateMissionsMessageEnum from '../models/Missions/CreateMissionsMessageEnum';
import {
	CreateMissionBase,
	CreateMissionsRequest,
	CreateSipMissionsRequest,
	CreateVrpMissionRequest,
} from '../models/Missions/CreateMissionsRequest';
import { CreateMissionResponse } from '../models/Missions/CreateMissionsResponse';
import {
	AccountTransaction,
	CvrpPayment,
	SingleImmediatePayment,
	SvrPayment,
	UkFasterPayment,
} from '../models/Missions/MissionType';
import { TreeDataNode } from '../models/Missions/TreeDataNodeType';
import { PaymentProviderDetailsType } from '../models/Payments';
import { AccountTransactionDetails } from '../models/Payments/AccountTransactions';
import { SingleImmediatePaymentDetails } from '../models/Payments/SingleImmediatePayment';
import { UKFasterPaymentDetails } from '../models/Payments/UkFasterPayment';
import { VrpProviderDetails } from '../models/Payments/Vrp/VrpProviderDetails';
import { DEFAULT_ERROR_MESSAGE, UNKNOWN_TYPE_ERROR_MESSAGE } from '../resources/Errors';
import { operationSuccessful } from '../resources/Success';
import {
	findPaymentIndexByTesterProviderId,
	getDefaultSingleImmediatePaymentsProviderDetails,
	getDefaultUkFasterPaymentsProviderDetails,
	getDefaultVrpProviderDetails,
	removeUncheckedProviders,
} from '../utils/MissionUtils';

interface UpdatedProviderPaymentResponse {
	providersPaymentDetailsCopy: PaymentProviderDetailsType[];
	updatedProvidersPaymentDetailsCopy: PaymentProviderDetailsType[];
}

interface ICreateMissionPayload {
	missionType: string;
	applicationLayer: applicationIdType;
	providersPaymentDetails: PaymentProviderDetailsType[];
	environmentLayer?: string;
	integratorType?: string;
	schemeId?: string;
	market?: string;
}

interface MissionService {
	handleGetTesterProvidersSuccessfully: (postDataResult: AxiosResponse<CreateMissionResponse>) => void;
	handleGetTesterProvidersUnsuccessfully: (
		postDataResult: AxiosResponse<CreateMissionResponse> | AxiosError<ErrorDetails>
	) => void;
	getShowPromptForMissionCreation: (
		details: PaymentProviderDetailsType[],
		id: string | number,
		isDirty: boolean
	) => boolean;
	missionTypeMapPaymentProviderToDto: (
		missionType: string
	) => (payment: PaymentProviderDetailsType) => PaymentProviderDetailsType;
	initializeUkFasterPaymentProvidersDetails: (
		nodes: TreeDataNode[],
		providersPaymentDetails: PaymentProviderDetailsType[],
		updatedProvidersPaymentDetails: PaymentProviderDetailsType[]
	) => UKFasterPaymentDetails[];
	initializeVrpProvidersDetails: (
		nodes: TreeDataNode[],
		providersPaymentDetails: PaymentProviderDetailsType[],
		updatedProvidersPaymentDetails: PaymentProviderDetailsType[],
		vrpType: string,
		filteredProviders?: TreeDataNode[]
	) => VrpProviderDetails[];
	initializeSingleImmediatePaymentProvidersDetails: (
		nodes: TreeDataNode[],
		providersPaymentDetails: PaymentProviderDetailsType[],
		updatedProvidersPaymentDetails: PaymentProviderDetailsType[]
	) => SingleImmediatePaymentDetails[];
	initializeAccountTransactionProvidersDetails: (
		nodes: TreeDataNode[],
		providersPaymentDetails: PaymentProviderDetailsType[],
		updatedProvidersPaymentDetails: PaymentProviderDetailsType[]
	) => AccountTransactionDetails[];
	getUpdateProviderPayment: (
		missionType: string,
		paymentDetails: PaymentProviderDetailsType,
		providersPaymentDetails: PaymentProviderDetailsType[],
		updatedProvidersPaymentDetails: PaymentProviderDetailsType[]
	) => UpdatedProviderPaymentResponse;
	getCreateMissionByMissionType: (payload: ICreateMissionPayload) => { data: CreateMissionBase; actionName: string };
}

const handleGetTesterProvidersSuccessfully = (postDataResult: AxiosResponse<CreateMissionResponse>) => {
	const successMessage = postDataResult.data?.value;
	if (successMessage === CreateMissionsMessageEnum.OPERATION_SUCCESSFUL) {
		DisplaySuccessToaster(operationSuccessful);
	}
};

const handleGetTesterProvidersUnsuccessfully = (
	postDataResult: AxiosResponse<CreateMissionResponse> | AxiosError<ErrorDetails>
) => {
	if (postDataResult.status === StatusCodes.BAD_REQUEST) {
		const response = postDataResult as AxiosResponse<CreateMissionResponse>;
		DisplayWarningToaster(response.data.error.message || DEFAULT_ERROR_MESSAGE);
		return;
	}

	const errorMessage = postDataResult as AxiosError<ErrorDetails>;
	errorMessage.response?.data.errors.forEach((error) =>
		DisplayWarningToaster(error.message || DEFAULT_ERROR_MESSAGE)
	);
};

const getShowPromptForMissionCreation = (
	details: PaymentProviderDetailsType[],
	id: string | number,
	isDirty: boolean
): boolean =>
	details.findIndex((payment: PaymentProviderDetailsType) => payment.testerProviderId === id) > -1 && isDirty;

const attributesToDeleteMap = {
	UkFasterPayment: ['title', 'testerId', 'schedulePayment'] as Array<keyof PaymentProviderDetailsType>,
	SingleImmediatePayment: [
		'title',
		'testerId',
		'schedulePayment',
		'isContractPresent',
		'isCreditorPrePopulated',
	] as Array<keyof PaymentProviderDetailsType>,
	CvrpPayment: [
		'title',
		'testerId',
		'perPayment',
		'day',
		'week',
		'fortnight',
		'month',
		'halfAYear',
		'year',
		'totalAmount',
		'paymentDay',
		'paymentWeek',
		'paymentFortnight',
		'paymentMonth',
		'paymentHalfAYear',
		'paymentYear',
	] as Array<keyof PaymentProviderDetailsType>,
};

const removeAttributesFromProvider = <T extends PaymentProviderDetailsType>(
	attributesToDelete: Array<keyof T>,
	payment: T
): PaymentProviderDetailsType => {
	const providerPayment = { ...payment };

	attributesToDelete.forEach((attribute) => {
		delete providerPayment[attribute];
	});

	return providerPayment;
};

const missionTypeMapPaymentProviderToDto =
	(missionType: string) =>
	(payment: PaymentProviderDetailsType): PaymentProviderDetailsType => {
		const providerPayment = { ...payment };

		providerPayment.isContractPresent = providerPayment.isContractPresent
			? (payment.isContractPresent as string) === 'true'
			: null;

		providerPayment.isCreditorPrePopulated = providerPayment.isCreditorPrePopulated
			? (payment.isCreditorPrePopulated as string) === 'true'
			: null;

		if (missionType === UkFasterPayment.id) {
			return removeAttributesFromProvider(attributesToDeleteMap.UkFasterPayment, providerPayment);
		}

		if (missionType === SingleImmediatePayment.id) {
			return removeAttributesFromProvider(attributesToDeleteMap.SingleImmediatePayment, providerPayment);
		}

		return removeAttributesFromProvider(attributesToDeleteMap.CvrpPayment, providerPayment);
	};

const initializeUkFasterPaymentProvidersDetails = (
	nodes: TreeDataNode[],
	providersPaymentDetails: PaymentProviderDetailsType[],
	updatedProvidersPaymentDetails: PaymentProviderDetailsType[]
): UKFasterPaymentDetails[] => {
	let paymentsDetails: UKFasterPaymentDetails[] = [...providersPaymentDetails] as UKFasterPaymentDetails[];
	const paymentDetailsKeys = paymentsDetails.map(
		(paymentDetails: UKFasterPaymentDetails) => paymentDetails.testerProviderId
	);
	const nodeKeys = nodes.map((node: TreeDataNode) => node.key);

	paymentsDetails = removeUncheckedProviders(nodeKeys, paymentsDetails) as UKFasterPaymentDetails[];

	// add checked providers details
	nodes.forEach((node: TreeDataNode) => {
		const indexOfEditedPayment = updatedProvidersPaymentDetails.findIndex(
			(savedPayment) => savedPayment.testerProviderId === node.key
		);
		if (!paymentDetailsKeys.includes(node.key)) {
			// un-edited payment
			if (indexOfEditedPayment < 0) {
				const providerPaymentDetails: UKFasterPaymentDetails = getDefaultUkFasterPaymentsProviderDetails(node);
				paymentsDetails.push(providerPaymentDetails);
			} else {
				// edited payment
				paymentsDetails.push(updatedProvidersPaymentDetails[indexOfEditedPayment] as UKFasterPaymentDetails);
			}
		}
	});

	return paymentsDetails;
};

const initializeVrpProvidersDetails = (
	nodes: TreeDataNode[],
	providersPaymentDetails: PaymentProviderDetailsType[],
	updatedProvidersPaymentDetails: PaymentProviderDetailsType[],
	vrpType: string,
	filteredProviders?: TreeDataNode[]
): VrpProviderDetails[] => {
	let paymentsDetails: VrpProviderDetails[] = [...providersPaymentDetails] as VrpProviderDetails[];
	const paymentDetailsKeys = paymentsDetails.map(
		(paymentDetails: VrpProviderDetails) => paymentDetails.testerProviderId
	);
	const nodeKeys = nodes.map((node: TreeDataNode) => node.key);

	paymentsDetails = removeUncheckedProviders(nodeKeys, paymentsDetails) as VrpProviderDetails[];

	// add checked providers details
	nodes.forEach((node: TreeDataNode) => {
		const indexOfEditedPayment = updatedProvidersPaymentDetails.findIndex(
			(savedPayment) => savedPayment.testerProviderId === node.key
		);
		if (!paymentDetailsKeys.includes(node.key)) {
			// un-edited payment
			if (indexOfEditedPayment < 0) {
				const providerPaymentDetails: VrpProviderDetails = getDefaultVrpProviderDetails(
					node,
					filteredProviders,
					vrpType
				);
				paymentsDetails.push(providerPaymentDetails);
			} else {
				// edited payment
				paymentsDetails.push(updatedProvidersPaymentDetails[indexOfEditedPayment] as VrpProviderDetails);
			}
		}
	});
	return paymentsDetails;
};

const initializeSingleImmediatePaymentProvidersDetails = (
	nodes: TreeDataNode[],
	providersPaymentDetails: PaymentProviderDetailsType[],
	updatedProvidersPaymentDetails: PaymentProviderDetailsType[]
): SingleImmediatePaymentDetails[] => {
	let paymentsDetails: SingleImmediatePaymentDetails[] = [
		...providersPaymentDetails,
	] as SingleImmediatePaymentDetails[];
	const paymentDetailsKeys = paymentsDetails.map(
		(paymentDetails: SingleImmediatePaymentDetails) => paymentDetails.testerProviderId
	);
	const nodeKeys = nodes.map((node: TreeDataNode) => node.key);

	paymentsDetails = removeUncheckedProviders(nodeKeys, paymentsDetails) as SingleImmediatePaymentDetails[];

	// add checked providers details
	nodes.forEach((node: TreeDataNode) => {
		const indexOfEditedPayment = updatedProvidersPaymentDetails.findIndex(
			(savedPayment) => savedPayment.testerProviderId === node.key
		);
		if (!paymentDetailsKeys.includes(node.key)) {
			// un-edited payment
			if (indexOfEditedPayment < 0) {
				const providerPaymentDetails: SingleImmediatePaymentDetails =
					getDefaultSingleImmediatePaymentsProviderDetails(node);
				paymentsDetails.push(providerPaymentDetails);
			} else {
				// edited payment
				paymentsDetails.push(
					updatedProvidersPaymentDetails[indexOfEditedPayment] as SingleImmediatePaymentDetails
				);
			}
		}
	});
	return paymentsDetails;
};

const initializeAccountTransactionProvidersDetails = (
	nodes: TreeDataNode[],
	providersPaymentDetails: PaymentProviderDetailsType[],
	updatedProvidersPaymentDetails: PaymentProviderDetailsType[]
): AccountTransactionDetails[] => {
	let paymentsDetails: AccountTransactionDetails[] = [...providersPaymentDetails] as AccountTransactionDetails[];
	const paymentDetailsKeys = paymentsDetails.map(
		(paymentDetails: AccountTransactionDetails) => paymentDetails.testerProviderId
	);
	const nodeKeys = nodes.map((node: TreeDataNode) => node.key);

	paymentsDetails = removeUncheckedProviders(nodeKeys, paymentsDetails) as AccountTransactionDetails[];

	// add checked providers details
	nodes.forEach((node: TreeDataNode) => {
		const indexOfEditedPayment = updatedProvidersPaymentDetails.findIndex(
			(savedPayment) => savedPayment.testerProviderId === node.key
		);
		if (!paymentDetailsKeys.includes(node.key)) {
			// un-edited payment
			if (indexOfEditedPayment < 0) {
				const providerPaymentDetails: AccountTransactionDetails =
					getDefaultSingleImmediatePaymentsProviderDetails(node);
				paymentsDetails.push(providerPaymentDetails);
			} else {
				// edited payment
				paymentsDetails.push(updatedProvidersPaymentDetails[indexOfEditedPayment] as AccountTransactionDetails);
			}
		}
	});
	return paymentsDetails;
};

const getUpdateProviderPayment = (
	missionType: string,
	paymentDetails: PaymentProviderDetailsType,
	providersPaymentDetails: PaymentProviderDetailsType[],
	updatedProvidersPaymentDetails: PaymentProviderDetailsType[]
): UpdatedProviderPaymentResponse => {
	const paymentDetailsCopy = { ...paymentDetails };
	const providersPaymentDetailsCopy = [...providersPaymentDetails] as PaymentProviderDetailsType[];
	const updatedProvidersPaymentDetailsCopy = [...updatedProvidersPaymentDetails] as PaymentProviderDetailsType[];

	const savedProviderPaymentIndex = findPaymentIndexByTesterProviderId(
		updatedProvidersPaymentDetailsCopy,
		paymentDetails.testerProviderId
	);
	const providerPaymentIndex = findPaymentIndexByTesterProviderId(
		providersPaymentDetailsCopy,
		paymentDetails.testerProviderId
	);

	// remove old details and push updated details
	if (savedProviderPaymentIndex > -1) {
		updatedProvidersPaymentDetailsCopy.splice(savedProviderPaymentIndex, 1);
	}
	if (providerPaymentIndex > -1) {
		providersPaymentDetailsCopy.splice(providerPaymentIndex, 1);
	}

	switch (missionType) {
		case UkFasterPayment.id:
			if ('executionDate' in paymentDetails) {
				(paymentDetailsCopy as UKFasterPaymentDetails).executionDate = !paymentDetails.executionDate
					? null
					: moment(paymentDetails.executionDate);
			}
			updatedProvidersPaymentDetailsCopy.push(paymentDetailsCopy);
			providersPaymentDetailsCopy.push(paymentDetailsCopy);
			break;
		case CvrpPayment.id:
		case SvrPayment.id:
			if ('validFrom' in paymentDetails) {
				(paymentDetailsCopy as VrpProviderDetails).validFrom = !paymentDetails.validFrom
					? null
					: moment(paymentDetails.validFrom);
			}
			if ('validTo' in paymentDetails) {
				(paymentDetailsCopy as VrpProviderDetails).validTo = !paymentDetails.validTo
					? null
					: moment(paymentDetails.validTo);
			}

			updatedProvidersPaymentDetailsCopy.push(paymentDetailsCopy);
			providersPaymentDetailsCopy.push(paymentDetailsCopy);
			break;
		case SingleImmediatePayment.id:
			updatedProvidersPaymentDetailsCopy.push(paymentDetailsCopy);
			providersPaymentDetailsCopy.push(paymentDetailsCopy);
			break;
		case AccountTransaction.id:
			if ('fetchTransactionsSince' in paymentDetails) {
				(paymentDetailsCopy as AccountTransactionDetails).fetchTransactionsSince =
					!paymentDetails.fetchTransactionsSince ? null : moment(paymentDetails.fetchTransactionsSince);
			}
			updatedProvidersPaymentDetailsCopy.push(paymentDetailsCopy);
			providersPaymentDetailsCopy.push(paymentDetailsCopy);
			break;
		default:
			console.error(UNKNOWN_TYPE_ERROR_MESSAGE);
			break;
	}

	return {
		providersPaymentDetailsCopy,
		updatedProvidersPaymentDetailsCopy,
	};
};

const getCreateMissionByMissionType = (
	payload: ICreateMissionPayload
): { data: CreateMissionBase; actionName: string } => {
	const {
		missionType,
		applicationLayer,
		providersPaymentDetails,
		environmentLayer,
		integratorType,
		schemeId,
		market,
	} = payload;
	let data: CreateMissionBase = {
		missionType: parseInt(missionType),
		applicationLayer,
	};
	const providersPaymentDetailsDto = providersPaymentDetails.map(missionTypeMapPaymentProviderToDto(missionType));
	let actionName = 'bulk';

	switch (missionType) {
		case CvrpPayment.id:
		case SvrPayment.id:
			actionName = 'vrp';
			data = {
				...data,
				vrpTesterProviderDetails: providersPaymentDetailsDto.map((tester: PaymentProviderDetailsType) => ({
					...tester,
					executionEnvironment: Number(environmentLayer),
				}))[0],
			} as CreateVrpMissionRequest;
			break;
		case SingleImmediatePayment.id:
			actionName = 'bulk/sip';
			data = {
				...data,
				integratorType,
				schemeId,
				market,
				testersProvidersDetails: providersPaymentDetailsDto,
			} as CreateSipMissionsRequest;
			break;
		case AccountTransaction.id:
			actionName = 'bulk/ais';
			data = {
				...data,
				testersProvidersDetails: providersPaymentDetailsDto.map((tester: AccountTransactionDetails) => ({
					...tester,
					environment: Number(environmentLayer),
				})),
			} as CreateMissionsRequest;
			break;
		case UkFasterPayment.id:
		default:
			data = {
				...data,
				testersProvidersDetails: providersPaymentDetailsDto.map((tester: PaymentProviderDetailsType) => ({
					...tester,
					executionEnvironment: Number(environmentLayer),
				})),
			} as CreateMissionsRequest;
			break;
	}

	return { data, actionName };
};

const useMissionService = (): MissionService => ({
	handleGetTesterProvidersSuccessfully,
	handleGetTesterProvidersUnsuccessfully,
	getShowPromptForMissionCreation,
	missionTypeMapPaymentProviderToDto,
	initializeUkFasterPaymentProvidersDetails,
	initializeVrpProvidersDetails,
	getUpdateProviderPayment,
	getCreateMissionByMissionType,
	initializeSingleImmediatePaymentProvidersDetails,
	initializeAccountTransactionProvidersDetails,
});

export default useMissionService;
