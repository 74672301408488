import { AppInsightsContext, ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import React from 'react';
import { Switch } from 'react-router-dom';

import Auth0ProviderWithHistory from './auth0-provider-with-history';
import AccountsAndTransactionsGetStarted from './components/AccountsAndTransactions/AccountsAndTransactionsGetStarted';
import AccountsAndTransactionsStageRouting from './components/AccountsAndTransactions/AccountsAndTransactionsStageRouting';
import Dashboard from './components/Dashboard';
import CreateMission from './components/Missions/CreateMission/CreateMission';
import AdminDashboardPage from './components/Missions/ListMissions/AdminAssignedMissions/AdminDashboardPage';
import AdminMissionResultsPage from './components/Missions/ListMissions/AdminMissionResults/AdminMissionResultsPage';
import TesterDashboardPage from './components/Missions/ListMissions/TesterMissions/TesterDashboardPage';
import PrivacyPolicy from './components/PrivacyPolicy';
import NotFound from './components/Routes/NotFound';
import ProtectedRoute from './components/Routes/ProtectedRoute';
import Unauthorized from './components/Routes/Unauthorized';
import AdminApprovePage from './components/SignupRequest/AdminApprovePage';
import SingleImmediatePaymentGetStarted from './components/SingleImmediatePayment/SingleImmediatePaymentGetStarted';
import SingleImmediatePaymentStageRouting from './components/SingleImmediatePayment/SingleImmediatePaymentStageRouting';
import { TesterAgreement } from './components/TesterAgreement/TesterAgreement';
import TesterOnboarding from './components/TesterOnboarding/TesterOnboarding';
import TesterPersonalData from './components/TesterPersonalData/TesterPersonalData';
import TesterProfilePage from './components/TesterProfile/TesterProfilePage';
import UkFasterPaymentGetStarted from './components/UkFasterPayment/UkFasterPaymentsGetStarted';
import UkFasterPaymentStageRouting from './components/UkFasterPayment/UKFasterPaymentsStageRouting';
import CVrpConsentsGetStarted from './components/VrpConsent/CVrpConsentsGetStarted';
import CVrpConsentsStageRouting from './components/VrpConsent/CVrpConsentsStageRouting';
import VrpConsentsGetStarted from './components/VrpConsent/VrpConsentsGetStarted';
import Layout from './layout/Layout';
import UserType from './models/User/UserType';
import config, { defaultPages } from './resources/config';

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();

const { enableAppInsights } = config;
if (enableAppInsights) {
	const appInsights = new ApplicationInsights({
		config: {
			connectionString: process.env.REACT_APP_APPLICATION_INSIGHTS,
			autoTrackPageVisitTime: true,
			extensions: [reactPlugin],
			extensionConfig: {
				[reactPlugin.identifier]: { history: browserHistory },
			},
		},
	});
	appInsights.loadAppInsights();
}

const AppContent = () => (
	<Auth0ProviderWithHistory>
		<Layout>
			<Switch>
				<ProtectedRoute path="/" exact component={Dashboard} />
				<ProtectedRoute path="/results" exact component={AdminMissionResultsPage} />
				<ProtectedRoute path="/create-mission" exact component={CreateMission} />
				<ProtectedRoute path={defaultPages.admin} exact component={AdminDashboardPage} />
				<ProtectedRoute path="/privacy-policy" exact component={PrivacyPolicy} />
				<ProtectedRoute path="/tester-personal-data" exact component={TesterPersonalData} />
				<ProtectedRoute path="/tester-agreement" exact component={TesterAgreement} />
				<ProtectedRoute path="/tester-onboarding" exact component={TesterOnboarding} />
				<ProtectedRoute path="/admin-tester-approval" exact component={AdminApprovePage} />
				<ProtectedRoute path="/tester-profile-page" exact component={TesterProfilePage} />
				<ProtectedRoute path={defaultPages.tester} exact component={TesterDashboardPage} />
				<ProtectedRoute
					path="/uk-faster-payment/:missionId"
					exact
					component={UkFasterPaymentGetStarted}
					routeRoles={[UserType.TESTER]}
				/>
				<ProtectedRoute
					path="/single-immediate-payment/:missionId"
					exact
					component={SingleImmediatePaymentGetStarted}
					routeRoles={[UserType.TESTER]}
				/>
				<ProtectedRoute
					path="/mission/:missionId/sip-callback"
					exact
					component={SingleImmediatePaymentStageRouting}
					routeRoles={[UserType.TESTER]}
				/>
				<ProtectedRoute
					path="/stage-routing/callback"
					exact
					component={UkFasterPaymentStageRouting}
					routeRoles={[UserType.TESTER]}
				/>
				<ProtectedRoute
					path="/accounts/:missionId"
					exact
					component={AccountsAndTransactionsGetStarted}
					routeRoles={[UserType.TESTER]}
				/>
				<ProtectedRoute
					path="/accounts/:missionId/auth-callback"
					exact
					component={AccountsAndTransactionsStageRouting}
					routeRoles={[UserType.TESTER]}
				/>
				<ProtectedRoute
					path="/vrp-consent/:missionId"
					exact
					component={VrpConsentsGetStarted}
					routeRoles={[UserType.TESTER]}
				/>
				<ProtectedRoute
					path="/apv3-cvrp-consent/:missionId"
					exact
					component={CVrpConsentsGetStarted}
					routeRoles={[UserType.TESTER]}
				/>
				<ProtectedRoute
					path="/mission/:missionId/cvrp-callback"
					exact
					component={CVrpConsentsStageRouting}
					routeRoles={[UserType.TESTER]}
				/>
				<ProtectedRoute path="/not-found" component={NotFound} />
				<ProtectedRoute path="/unauthorized" component={Unauthorized} />
				<ProtectedRoute path="*" component={NotFound} />
			</Switch>
		</Layout>
	</Auth0ProviderWithHistory>
);

const AppWithInsights = withAITracking(reactPlugin, () => (
	<AppInsightsContext.Provider value={reactPlugin}>
		<AppContent />
	</AppInsightsContext.Provider>
));

const App = enableAppInsights ? AppWithInsights : AppContent;

export default App;
