import './style.css';

import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';

import { deleteBankModalConfiguration } from '../../resources/config';

export const confirm = (message: string, action: (param?: object) => Promise<void>): void => {
	Modal.confirm({
		title: message,
		icon: <ExclamationCircleFilled />,
		className: 'cl-confirm-modal',
		onOk() {
			action().catch((err) => console.error(err));
		},
		...deleteBankModalConfiguration,
	});
};
export default confirm;
