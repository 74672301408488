import './style.css';

import { Button } from 'antd';
import React from 'react';

import ButtonInterface from './ButtonInterface';

const buttonDefaultProps: ButtonInterface = {
	label: '',
	className: 'cl-button-approve',
	wrapperClassName: '',
	onClick: () => undefined,
	hidden: false,
	htmlType: 'button',
	disabled: false,
};

const CLButton: React.FC<ButtonInterface> = (props: ButtonInterface): JSX.Element => (
	<div className={`cl-button-wrapper ${props.wrapperClassName}`}>
		<Button
			type="primary"
			onClick={props.onClick}
			className={`cl-button ${props.className}`}
			hidden={props.hidden}
			htmlType={props.htmlType}
			disabled={props.disabled}
		>
			{props.label}
		</Button>
	</div>
);

CLButton.defaultProps = buttonDefaultProps;

export default CLButton;
