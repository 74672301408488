import config from '../resources/config';

enum NagCoreEnvironment {
	Test = 0,
	SquadExpansionProd = 1,
	Prod = 2,
}

enum ConnectivityLabEnvironment {
	Dev = 'Dev',
	Test = 'Test',
	Sandbox = 'Sandbox',
	Prod = 'Production',
}

const getNagCoreEnvironmentValue = (): number => {
	switch (config.envName) {
		case ConnectivityLabEnvironment.Dev:
		case ConnectivityLabEnvironment.Test:
			return NagCoreEnvironment.Test;
		case ConnectivityLabEnvironment.Sandbox:
			return NagCoreEnvironment.SquadExpansionProd;
		case ConnectivityLabEnvironment.Prod:
			return NagCoreEnvironment.Prod;
		default:
			return NagCoreEnvironment.Test;
	}
};

export default getNagCoreEnvironmentValue;
