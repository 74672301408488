import { AxiosResponse } from 'axios';

import {
	IMissionDetailsRequest,
	IMissionDetailsResponse,
	MissionHandler,
} from '../../../../models/AdminMissions/AdminMissionHandler';
import { AisMissionDashboardDetails } from '../../../../models/Missions/AisMissionDashboardDetailsType';
import { MissionTypeCode } from '../../../../models/Missions/MissionType';
import { VrpMissionDetails } from '../../../../models/Missions/VrpConsentDetailsType';
import { SingleImmediatePaymentDetails } from '../../../../models/Payments/SingleImmediatePayment';
import { UKFasterPaymentDetails } from '../../../../models/Payments/UkFasterPayment';
import { formatDateWithTime } from '../../../../utils/VrpConsentUtils';
import AISMissionDetails from './AccountTransactionDetails/AISMissionDetails';
import SipViewAdminDetails from './SipDetails/SipViewAdminDetails';
import UkfpViewAdminDetails from './UkFasterPaymentDetails/UkfpViewAdminDetails';
import VrpMissionConsent from './VrpConsent/VrpMissionConsent';
import VrpPaymentDetails from './VrpPaymentDetails/VrpPaymentDetails';

const NoDataAvailableComponent = (): JSX.Element => <>No data available</>;

const VrpHandler: MissionHandler = (request): IMissionDetailsResponse => {
	const handlerResponse: IMissionDetailsResponse = {
		data: null,
		canHandle: false,
		render: () => undefined,
	};

	if (
		request.row.original.type.toUpperCase() === MissionTypeCode.Cvrp.toUpperCase() ||
		request.row.original.type.toUpperCase() === MissionTypeCode.Svrp.toUpperCase()
	) {
		handlerResponse.canHandle = true;
		const data: VrpMissionDetails = {
			id: request.row.original?.id,
			title: request.row.original?.id.toString(),
			type: request.row?.original?.type,
			status: request.row?.original?.status,
			createdDate: formatDateWithTime(request.row.original.createdDate),
			consentDetails: { ...request.row.original.consentDetails },
			paymentDetails: [...request.row.original.paymentDetails],
		};

		const render = (): JSX.Element => (
			<>
				<VrpMissionConsent data={data} />
				<VrpPaymentDetails data={data?.paymentDetails} />
			</>
		);

		handlerResponse.data = data;
		handlerResponse.render = render;
	}

	return handlerResponse;
};

const SipHandler: MissionHandler = (request, getDataAsync?: () => Promise<unknown>): IMissionDetailsResponse => {
	const handlerResponse: IMissionDetailsResponse = {
		data: null,
		canHandle: false,
		render: () => undefined,
	};

	if (request.row.original.type.toUpperCase() === MissionTypeCode.SingleImmediatePayment.toUpperCase()) {
		handlerResponse.canHandle = true;
		let data = {} as SingleImmediatePaymentDetails;
		void (async () => {
			const response = (await getDataAsync()) as AxiosResponse<SingleImmediatePaymentDetails>;
			data = response.data;
		})();

		const render = () => (data ? <SipViewAdminDetails data={data} /> : <NoDataAvailableComponent />);

		handlerResponse.data = data;
		handlerResponse.render = render;
	}
	return handlerResponse;
};

const AisHandler: MissionHandler = (request, getDataAsync?: () => Promise<unknown>): IMissionDetailsResponse => {
	const handlerResponse: IMissionDetailsResponse = {
		data: null,
		canHandle: false,
		render: () => undefined,
	};
	if (request.row.original.type.toUpperCase() === MissionTypeCode.AccountTransaction.toUpperCase()) {
		handlerResponse.canHandle = true;
		let data = {} as AisMissionDashboardDetails;
		void (async () => {
			const response = (await getDataAsync()) as AxiosResponse<AisMissionDashboardDetails>;
			data = response.data;
		})();

		const render = () => (data ? <AISMissionDetails data={data} /> : <NoDataAvailableComponent />);

		handlerResponse.data = data;
		handlerResponse.render = render;
	}

	return handlerResponse;
};

const UkfpHandler: MissionHandler = (request, getDataAsync?: () => Promise<unknown>): IMissionDetailsResponse => {
	const handlerResponse: IMissionDetailsResponse = {
		data: null,
		canHandle: false,
		render: () => undefined,
	};

	if (request.row.original.type.toUpperCase() === MissionTypeCode.UkFasterPayment.toUpperCase()) {
		handlerResponse.canHandle = true;
		let data = {} as UKFasterPaymentDetails;
		void (async () => {
			const response = (await getDataAsync()) as AxiosResponse<UKFasterPaymentDetails>;
			data = response.data;
		})();

		const render = () => (data ? <UkfpViewAdminDetails data={data} /> : <NoDataAvailableComponent />);

		handlerResponse.data = data;
		handlerResponse.render = render;
	}
	return handlerResponse;
};

export const handleMissionDetails = (
	request: IMissionDetailsRequest,
	getDataAsync?: () => Promise<unknown>
): IMissionDetailsResponse => {
	const handlers: MissionHandler[] = [SipHandler, VrpHandler, AisHandler, UkfpHandler];
	let handlerResponse: IMissionDetailsResponse = null;
	if (!request.row) {
		return null;
	}
	for (let i = 0; i < handlers.length; i++) {
		handlerResponse = handlers[i](request, getDataAsync);
		if (handlerResponse.canHandle === true) {
			return handlerResponse;
		}
	}
	return null;
};
