import { PlusCircleOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import moment from 'moment';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Col, Row } from 'reactstrap';

import { CLCheckbox } from '../../../cl-shared-components/Checkbox';
import { CLDatePicker, CLTextInput } from '../../../cl-shared-components/Inputs';
import CLSelectInput from '../../../cl-shared-components/Inputs/CLSelectInput';
import { Country } from '../../../models/Countries/Country';
import { IMissionForm } from '../../../models/Missions/MissionForm';
import { SingleImmediatePaymentDetails } from '../../../models/Payments/SingleImmediatePayment';
import { PAYMENT_CONTEXT_CODE, PAYMENT_PURPOSE_CODE } from '../../../models/Payments/TransactionRiskIndicators';
import { DATE_FORMAT, DATE_PLACEHOLDER } from '../../../resources/config';
import { getRowNameForField, isFieldVisible, isRowVisible } from '../../../utils/EditFormUtils';
import { isCountryCodeRequired, isTownNameRequired } from '../../../utils/MissionUtils';
import { SingleImmediatePaymentSchemaValidation } from '../YupSchemaValidation/SingleImmediatePaymentSchemaValidation';
import { ValidationMapper } from './Models/ValidationMapper';

interface SingleImmediatePaymentMissionFormProps extends IMissionForm<SingleImmediatePaymentDetails> {
	countries: {
		selectedCountry: string;
		sourceCountries: Country[];
	};
}

const SingleImmediatePaymentMissionForm = React.memo(
	(props: SingleImmediatePaymentMissionFormProps): JSX.Element => {
		let Validation = ValidationMapper[props.countries.selectedCountry];
		if (!Validation) {
			Validation = SingleImmediatePaymentSchemaValidation;
		}
		const {
			control,
			handleSubmit,
			setValue,
			getValues,
			trigger,
			formState: { errors, dirtyFields },
		} = useForm({
			defaultValues: props.data,
			values: props.data,
			mode: 'onChange',
			resolver: yupResolver(Validation),
		});

		const { bankId, bankCountryCode } = props.data;

		const generateEndToEndID = (): void => {
			const uid = crypto.randomUUID().substring(0, 35);
			setValue('endToEndId', uid, { shouldDirty: true });
			void trigger('endToEndId');
		};

		const rowNames = {
			amount: getRowNameForField(bankCountryCode, bankId, 'amount'),
			currency: getRowNameForField(bankCountryCode, bankId, 'currency'),
			schedulePayment: getRowNameForField(bankCountryCode, bankId, 'schedulePayment'),
			executionDate: getRowNameForField(bankCountryCode, bankId, 'executionDate'),
			referenceInformation: getRowNameForField(bankCountryCode, bankId, 'referenceInformation'),
			endToEndId: getRowNameForField(bankCountryCode, bankId, 'endToEndId'),
			destinationId: getRowNameForField(bankCountryCode, bankId, 'destinationId'),
			paymentContextCode: getRowNameForField(bankCountryCode, bankId, 'paymentContextCode'),
			paymentPurposeCode: getRowNameForField(bankCountryCode, bankId, 'paymentPurposeCode'),
			thirdPartyIdentifier: getRowNameForField(bankCountryCode, bankId, 'thirdPartyIdentifier'),
			thirdPartyAddress: getRowNameForField(bankCountryCode, bankId, 'thirdPartyAddress'),
			thirdPartyType: getRowNameForField(bankCountryCode, bankId, 'thirdPartyType'),
			countryCode: getRowNameForField(bankCountryCode, bankId, 'countryCode'),
			townName: getRowNameForField(bankCountryCode, bankId, 'townName'),
		};

		return (
			<div className="cl-mission-modal-container">
				<form
					ref={(ref: HTMLFormElement): void => {
						props.setRef(ref);
					}}
					onSubmit={handleSubmit(() => {
						props.onSubmit(getValues(), Object.keys(dirtyFields).length > 0);
					})}
				>
					{isRowVisible(bankCountryCode, bankId, rowNames.amount) && (
						<Row name={rowNames.amount}>
							{isFieldVisible(bankCountryCode, bankId, rowNames.amount, 'amount') && (
								<Col lg={6}>
									<Controller
										name="amount"
										control={control}
										render={({ field }): JSX.Element => (
											<CLTextInput
												{...field}
												label={'Amount'}
												errors={errors}
												required
												type="number"
											/>
										)}
									/>
								</Col>
							)}
							{isFieldVisible(bankCountryCode, bankId, rowNames.currency, 'currency') && (
								<Col lg={6}>
									<Controller
										name="currency"
										control={control}
										render={({ field }): JSX.Element => (
											<CLTextInput {...field} label={'Currency'} errors={errors} required />
										)}
									/>
								</Col>
							)}
						</Row>
					)}
					{isRowVisible(bankCountryCode, bankId, rowNames.schedulePayment) && (
						<Row name={rowNames.schedulePayment}>
							{isFieldVisible(bankCountryCode, bankId, rowNames.schedulePayment, 'schedulePayment') && (
								<Col lg={6}>
									<Controller
										name="schedulePayment"
										control={control}
										render={({ field }): JSX.Element => (
											<CLCheckbox
												className="cl-sip-schedule-payment"
												{...field}
												label="Schedule payment"
												checked={getValues('schedulePayment')}
												onChange={(event: CheckboxChangeEvent): void => {
													setValue(
														'executionDate',
														event.target.checked ? moment().add(1, 'day') : null,
														{
															shouldDirty: true,
														}
													);
													setValue('schedulePayment', event.target.checked, {
														shouldDirty: true,
													});
													void trigger('executionDate');
												}}
											/>
										)}
									/>
								</Col>
							)}
							{isFieldVisible(bankCountryCode, bankId, rowNames.executionDate, 'executionDate') &&
								getValues('schedulePayment') && (
									<Col lg={6}>
										<Controller
											name="executionDate"
											control={control}
											render={({ field }): JSX.Element => (
												<CLDatePicker
													{...field}
													label={'Execution Date'}
													picker="date"
													format={DATE_FORMAT}
													placeholder={DATE_PLACEHOLDER}
													errors={errors.executionDate}
													required
												/>
											)}
										/>
									</Col>
								)}
						</Row>
					)}
					{isRowVisible(bankCountryCode, bankId, rowNames.referenceInformation) && (
						<Row name={rowNames.referenceInformation}>
							{isFieldVisible(
								bankCountryCode,
								bankId,
								rowNames.referenceInformation,
								'referenceInformation'
							) && (
								<Col lg={12}>
									<Controller
										name="referenceInformation"
										control={control}
										render={({ field }): JSX.Element => (
											<CLTextInput {...field} label={'Message Text'} errors={errors} required />
										)}
									/>
								</Col>
							)}
						</Row>
					)}
					{isRowVisible(bankCountryCode, bankId, rowNames.endToEndId) && (
						<Row name={rowNames.endToEndId}>
							{isFieldVisible(bankCountryCode, bankId, rowNames.endToEndId, 'endToEndId') && (
								<Col lg={12}>
									<div className="cl-payment-end-to-end-wrapper">
										<Controller
											name="endToEndId"
											control={control}
											render={({ field }): JSX.Element => (
												<CLTextInput
													{...field}
													label={'End-to-End Id'}
													errors={errors}
													required
												/>
											)}
										/>
										<PlusCircleOutlined
											className="cl-payment-end-to-end-icon"
											onClick={generateEndToEndID}
										/>
									</div>
								</Col>
							)}
						</Row>
					)}
					{isRowVisible(bankCountryCode, bankId, rowNames.destinationId) && (
						<Row name={rowNames.destinationId}>
							{isFieldVisible(bankCountryCode, bankId, rowNames.destinationId, 'destinationId') && (
								<Col lg={12}>
									<Controller
										name="destinationId"
										control={control}
										render={({ field }): JSX.Element => (
											<CLTextInput {...field} label={'Destination Id'} errors={errors} />
										)}
									/>
								</Col>
							)}
						</Row>
					)}
					{isRowVisible(bankCountryCode, bankId, rowNames.paymentContextCode) && (
						<Row name={rowNames.paymentContextCode}>
							{isFieldVisible(
								bankCountryCode,
								bankId,
								rowNames.paymentContextCode,
								'paymentContextCode'
							) && (
								<Col lg={6}>
									<Controller
										name="paymentContextCode"
										control={control}
										render={({ field }): JSX.Element => (
											<CLSelectInput
												className="cl-sip-paymentContextCode"
												{...field}
												label="Payment Context"
												allowEmptyValue
												options={Object.keys(PAYMENT_CONTEXT_CODE).map((key, index) => ({
													value: key === 'null' ? null : index,
													label: key === 'null' ? ' ' : key,
												}))}
											/>
										)}
									/>
								</Col>
							)}
							{isFieldVisible(
								bankCountryCode,
								bankId,
								rowNames.paymentPurposeCode,
								'paymentPurposeCode'
							) && (
								<Col lg={6}>
									<Controller
										name="paymentPurposeCode"
										control={control}
										render={({ field }): JSX.Element => (
											<CLSelectInput
												className="cl-sip-paymentPurposeCode"
												{...field}
												label="Payment Purpose Code"
												allowEmptyValue
												options={Object.keys(PAYMENT_PURPOSE_CODE).map((key, index) => ({
													value: key === 'null' ? null : index,
													label: key === 'null' ? ' ' : key,
													title: PAYMENT_PURPOSE_CODE[
														key as keyof typeof PAYMENT_PURPOSE_CODE
													],
												}))}
											/>
										)}
									/>
								</Col>
							)}
						</Row>
					)}
					{isRowVisible(bankCountryCode, bankId, rowNames.thirdPartyIdentifier) && (
						<Row name={rowNames.thirdPartyIdentifier}>
							{isFieldVisible(
								bankCountryCode,
								bankId,
								rowNames.thirdPartyIdentifier,
								'thirdPartyIdentifier'
							) && (
								<Col lg={6}>
									<Controller
										name="thirdPartyIdentifier"
										control={control}
										render={({ field }): JSX.Element => (
											<CLTextInput {...field} label={'Third Party Identifier'} errors={errors} />
										)}
									/>
								</Col>
							)}
							{isFieldVisible(
								bankCountryCode,
								bankId,
								rowNames.thirdPartyAddress,
								'thirdPartyAddress'
							) && (
								<Col lg={6}>
									<Controller
										name="thirdPartyAddress"
										control={control}
										render={({ field }): JSX.Element => (
											<CLTextInput {...field} label={'Third Party Address'} errors={errors} />
										)}
									/>
								</Col>
							)}
						</Row>
					)}
					{isRowVisible(bankCountryCode, bankId, rowNames.countryCode) && (
						<Row name={rowNames.countryCode}>
							{isFieldVisible(bankCountryCode, bankId, rowNames.countryCode, 'countryCode') && (
								<Col lg={6}>
									<Controller
										name="countryCode"
										control={control}
										render={({ field }): JSX.Element => {
											const isRequired = isCountryCodeRequired(bankId);
											return (
												<CLSelectInput
													{...field}
													label={'Country'}
													optionFilterProp="label"
													errors={errors}
													options={props.countries.sourceCountries.map((country) => ({
														value: country.countryCode,
														label: country.name,
													}))}
													{...(isRequired ? { required: true } : { allowEmptyValue: true })}
												/>
											);
										}}
									/>
								</Col>
							)}
							{isFieldVisible(bankCountryCode, bankId, rowNames.townName, 'townName') && (
								<Col lg={6}>
									<Controller
										name="townName"
										control={control}
										render={({ field }): JSX.Element => {
											const isRequired = isTownNameRequired(bankId);
											return (
												<CLTextInput
													{...field}
													label={'Town'}
													errors={errors}
													{...(isRequired ? { required: true } : { allowEmptyValue: true })}
												/>
											);
										}}
									/>
								</Col>
							)}
						</Row>
					)}
					{isRowVisible(bankCountryCode, bankId, rowNames.thirdPartyType) && (
						<Row name={rowNames.thirdPartyType}>
							{isFieldVisible(bankCountryCode, bankId, rowNames.thirdPartyType, 'thirdPartyType') && (
								<Col lg={12}>
									<Controller
										name="thirdPartyType"
										control={control}
										render={({ field }): JSX.Element => (
											<CLTextInput
												{...field}
												label={'Third Party Type'}
												errors={errors}
												required
											/>
										)}
									/>
								</Col>
							)}
						</Row>
					)}
				</form>
			</div>
		);
	},
	(prevProps, nextProps) => nextProps.data.bankCountryCode === ''
);

export default SingleImmediatePaymentMissionForm;
