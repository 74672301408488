import './TesterPersonalData.css';
import 'antd/dist/antd.css';

import { Col, Form, Input, Row, Select } from 'antd';
import { Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { CLButton } from '../../cl-shared-components/Buttons';
import { Tester } from '../../models/Tester/Tester';
import TesterStatus from '../../models/Tester/TesterStatus';
import { countries } from '../../resources/Countries';
import { RootState } from '../../store';
import { setTesterPersonalData } from '../../store/reducers/testerReducer';
import Welcome from '../Welcome/Welcome';

export default function TesterPersonalData() {
	const history = useHistory();
	const dispatch = useDispatch();
	const email = useSelector((state: RootState) => state.auth.userEmail);
	const initialFormValues = {
		name: '',
		address: '',
		city: '',
		phone: '',
		postalCode: '',
		country: countries[0],
	};
	const errorTemplateMessage = (field: string) => `Please fill in ${field}.`;
	const errorLength = () => 'Only 255 characters are allowed';
	const personalDataSchema = Yup.object().shape({
		name: Yup.string()
			.required(errorTemplateMessage('Name'))
			.matches(/^([^0-9]*)$/, 'Numbers are not allowed')
			.max(255, errorLength()),
		address: Yup.string().required(errorTemplateMessage('Address')).max(255, errorLength()),
		city: Yup.string().required(errorTemplateMessage('City')).max(255, errorLength()),
		phone: Yup.string()
			.required(errorTemplateMessage('Phone'))
			.matches(/^\d+$/, 'Only numbers are allowed')
			.max(255, errorLength()),
		postalCode: Yup.string().required(errorTemplateMessage('Postal Code')).max(255, errorLength()),
	});

	function formSubmit(tester: Tester) {
		const currentTester = tester;
		currentTester.email = email;
		currentTester.status = TesterStatus.Awaiting_Approval;
		dispatch(setTesterPersonalData({ currentTester }));
		history.push('/tester-agreement');
	}
	return (
		<div>
			{' '}
			<Welcome />
			<div className="tester-personal-data-form">
				<Formik
					enableReinitialize
					initialValues={initialFormValues}
					validationSchema={personalDataSchema}
					onSubmit={(values) => {
						formSubmit(values);
					}}
				>
					{({ values, touched, errors, handleBlur, handleChange, handleSubmit }) => (
						<Form onSubmitCapture={handleSubmit} layout="vertical" requiredMark>
							<div className="tester-personal-data">
								<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
									<Col className="gutter-row">
										<Form.Item
											required
											label="Name"
											help={touched.name && errors.name ? errors.name : ''}
											validateStatus={touched.name && errors.name ? 'error' : undefined}
										>
											<Input
												name="name"
												className="personal-data-field"
												placeholder="Please enter your name"
												value={values.name}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Form.Item>
									</Col>

									<Col className="gutter-row">
										<Form.Item
											required
											label="Phone"
											help={touched.phone && errors.phone ? errors.phone : ''}
											validateStatus={touched.phone && errors.phone ? 'error' : undefined}
										>
											<Input
												name="phone"
												className="personal-data-field"
												placeholder="Please enter your phone number"
												value={values.phone}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
									<Col className="gutter-row">
										<Form.Item
											required
											label="Address"
											help={touched.address && errors.address ? errors.address : ''}
											validateStatus={touched.address && errors.address ? 'error' : undefined}
										>
											<Input
												name="address"
												className="personal-data-field"
												placeholder="Please enter your address"
												value={values.address}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Form.Item>
									</Col>
									<Col className="gutter-row">
										<Form.Item
											required
											label="Postal Code"
											help={touched.postalCode && errors.postalCode ? errors.postalCode : ''}
											validateStatus={
												touched.postalCode && errors.postalCode ? 'error' : undefined
											}
										>
											<Input
												name="postalCode"
												className="personal-data-field"
												placeholder="Please enter your postal code"
												value={values.postalCode}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
									<Col className="gutter-row">
										<Form.Item
											required
											label="City"
											help={touched.city && errors.city ? errors.city : ''}
											validateStatus={touched.city && errors.city ? 'error' : undefined}
										>
											<Input
												name="city"
												className="personal-data-field"
												placeholder="Please enter your city"
												value={values.city}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Form.Item>
									</Col>
									<Col className="gutter-row">
										<Form.Item label="Country" className="country-dropdown">
											<Select
												defaultValue={countries[0]}
												size="large"
												className="personal-data-field"
												onChange={(selectedValue) => {
													values.country = selectedValue;
												}}
											>
												{countries.map((country, index) => (
													<Select.Option value={country} key={index}>
														{country}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>
								</Row>
							</div>

							<div className="submit-button-section">
								<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
									<Col span={24}>
										<Form.Item>
											<CLButton label="Submit" htmlType="submit" />
										</Form.Item>
									</Col>
								</Row>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
}
