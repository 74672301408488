import './PrivacyPolicy.css';

import { useContext, useEffect } from 'react';

import { PageTitleContext } from '../layout/Layout';
import { pageTitles } from '../resources/config';

const PrivacyPolicy = () => {
	const { setPageTitle } = useContext(PageTitleContext);

	useEffect(() => {
		setPageTitle(pageTitles.privacyPolicy);
	});

	return (
		<div className="privacy-policy-content">
			<p>
				This privacy policy covers the processing that Aiia A/S performs as data controller, for purposes
				related to your interaction with our provider verification tool. This document covers the information
				you should be aware of according to Article 13 and 14 of the General Data Protection Regulation (GDPR).{' '}
			</p>

			<ul>
				<li>You can contact Aiia at: Aiia A/S, Artillerivej 86, st. tv, 2300 Copenhagen, Denmark.</li>

				<li>
					We also have appointed a Data Protection Officer (DPO) in Aiia, who oversees data protection in
					Aiia. The DPO can answer questions related to how Aiia handles your personal data and help you
					exercise your rights under the GDPR. You can at any time reach our DPO at dpo@aiia.eu.{' '}
				</li>

				<li>
					You should be aware that Chapter III of the GDPR gives you a number of rights. Some of these rights
					apply in specific circumstances. Please contact our DPO if you need to understand and exercise these
					rights.
					<ul>
						<li>
							<b>
								Information, access, rectification, erasure, portability and automated decision-making
							</b>

							<p>
								The European commission summaries these rights on their website: <br />
								<i>
									“You have the right to:
									<br />
									<span>
										- information about the processing of your personal data;
										<br />
										- obtain access to the personal data held about you;
										<br />
										- ask for incorrect, inaccurate or incomplete personal data to be corrected;
										<br />
										- request that personal data be erased when it’s no longer needed or if
										processing it is unlawful;
										<br />
										- object to the processing of your personal data for marketing purposes or on
										grounds relating to your particular situation;
										<br />
										- request the restriction of the processing of your personal data in specific
										cases;
										<br />
										- receive your personal data in a machine-readable format and send it to another
										controller (‘data portability’);
										<br />- request that decisions based on automated processing concerning you or
										significantly affecting you and based on your personal data are made by natural
										persons, not only by computers. You also have the right in this case to express
										your point of view and to contest the decision.”
									</span>
								</i>
							</p>
						</li>

						<li>
							<b>Data processed outside of EU/EEA</b>

							<p>
								If your personal data is processed or transferred outside of the EU/EEA, you can contact
								the DPO for a copy the appropriate or suitable safeguards related to the protection of
								your data.
							</p>
						</li>

						<li>
							<b>Supervisory Authority</b>
							<p>
								You also have a right to lodge a complaint to your national supervisory authority. For
								Denmark, the supervisory authority is Datatilsynet. You can find the contact details on
								their website, www.datatilsynet.dk
							</p>
						</li>
					</ul>
				</li>

				<li>
					We will process your name, information about your bank or provider, and your financial information.
				</li>

				<li>
					We process this data for the purpose of improving the quality of the integrations to banks and other
					providers
				</li>

				<li>
					We process your financial data with your consent. If you do not consent, we cannot perform the
					quality control. You have a right to withdraw your consent. You can do so by contacting the person
					that asked you to perform the quality control or by contacting the DPO directly.
				</li>

				<li>
					We will store a trace of the communication with your provider for up to 14 days after you have used
					this tool.
				</li>

				<li>We will store logs for up to 1 year after you have used this tool.</li>

				<li>
					It is our legtimate interest to store a trace of the communication and logs, for the purpose of
					securing and debugging our services.
				</li>

				<li>
					We will not transfer the information to any other party, except for the processing that takes place
					at our data processors.
				</li>
			</ul>
		</div>
	);
};
export default PrivacyPolicy;
