import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './app';
import config from './resources/config';
import { store } from './store';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
	<Provider store={store}>
		<BrowserRouter
			basename={config.basename}
			getUserConfirmation={() => {
				// override default promt message
			}}
		>
			<App />
		</BrowserRouter>
	</Provider>
);
