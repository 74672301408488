// User
export const approvedTesterMessage = 'User Profile successfully approved';
export const rejectedTesterMessage = 'User Profile successfully rejected';

// Bank
export const successfullyDeletedBankMessage = 'The bank has been successfully deleted.';
export const bankAdded = 'The bank was successfully added';

// Missions
export const operationSuccessful = 'The missions were successfully created';

// Payment
export const PAYMENT_SUCCESS_MESSAGE =
	'Selected mission was started. Please check its final status within your missions table';

export const getPaymentStatusMessage = (status: string): string =>
	`Selected mission was started. The payment status is ${status}`;
// Consent
export const CONSENT_REVOKED_SUCCESSFULLY = 'Consent was revoked successfully';

// Account
export const ACCOUNT_SUCCESS_MESSAGE =
	'Selected mission was started. Please check its final status within your missions table';

// Payment Status - SIP
export const PAYMENT_STATUSES: Record<number, string> = {
	0: 'PREPARING',
	1: 'AUTHORIZING',
	2: 'PROVIDER_PROCESSING',
	3: 'PENDING',
	4: 'INITIATED',
	5: 'PAYMENT_EXECUTED_DEBITED',
	6: 'PAYMENT_EXECUTED_CREDITED',
	7: 'CANCELLED',
	8: 'FAILED',
	9: 'UNKNOWN',
	10: 'AUTHORIZATION_FLOW_INCOMPLETE',
};

// CVRP
export const AUTHORIZED = 2;
