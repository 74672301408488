import { ErrorMessage } from '@hookform/error-message';
import { Input, InputRef } from 'antd';
import React, { FunctionComponent } from 'react';
import { FieldErrors } from 'react-hook-form';
import { Col, Row } from 'reactstrap';

import { CLInputPropsInterface, defaultProps } from './CLInputProps';

interface CLInputPropsExtended extends CLInputPropsInterface {
	errors?: FieldErrors;
	hasNestedObjectForValidate?: boolean;
}
const CLTextinputDefaultProps: CLInputPropsExtended = {
	...defaultProps,
};

const isFieldInvalid = (errors: FieldErrors, name: string, hasNestedObjectForValidate: boolean): boolean => {
	if (!hasNestedObjectForValidate) {
		return errors[name] !== undefined;
	}

	const [parentObjectName, childObjectName] = name.split('.');
	return errors[parentObjectName] && childObjectName in errors[parentObjectName];
};

const CLTextInput: FunctionComponent<CLInputPropsExtended & React.RefAttributes<InputRef>> = React.forwardRef(
	(props, ref): JSX.Element => {
		const { colSize, label, required, errors, name, hasNestedObjectForValidate } = props;
		const showErrorClass = isFieldInvalid(errors, name, hasNestedObjectForValidate);

		const className = `cl-input ${showErrorClass ? 'cl-input-errors' : ''}`;

		return (
			<div className="cl-input-wrapper">
				<Row>
					<Col lg={colSize.lg} md={colSize.md} sm={colSize.sm}>
						<label>
							{label} {required && <span className="cl-input-required">*</span>}
						</label>
					</Col>
					<Col lg={colSize.lg} md={colSize.md} sm={colSize.sm}>
						<Input {...props} ref={ref} size="large" className={className} required={false} />
					</Col>
					<Col lg={colSize.lg} md={colSize.md} sm={colSize.sm}>
						{errors && (
							<ErrorMessage
								errors={errors}
								name={name}
								render={({ message }) => <div className="cl-text-input-errors">{message}</div>}
							/>
						)}
					</Col>
				</Row>
			</div>
		);
	}
);
CLTextInput.defaultProps = CLTextinputDefaultProps;

export default CLTextInput;
