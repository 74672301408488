import './GenericTable.css';

import {
	ColumnDef,
	ExpandedState,
	getCoreRowModel,
	getExpandedRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	PaginationState,
	RowData,
	SortingState,
	Table,
	TableOptions,
	Updater,
	useReactTable,
} from '@tanstack/react-table';
import React, { useEffect } from 'react';

import { AdminMissionDto } from '../../models/AdminMissions/AdminMissionDto';
import { PaginationUpdateParams } from '../../models/GenericTable/PaginationUpdateParams';
import { defaultPaginationState } from '../../resources/config';
import Spinner from '../Spinner/Spinner';
import GenericTable from './GenericTable';
import GenericTableServerSidePagination from './GenericTableServerSidePagination';

export default function GenericTableWithServerSidePagination({
	data,
	columns,
	getTableSubRows,
	onTableInitialization,
	onRowSelectionChanging,
	onPaginationUpdate,
	defaultSortBy,
	pageCount,
	isLoading,
	totalCount,
}: {
	getTableSubRows?: (originalRow: RowData | AdminMissionDto) => RowData[];
	data: RowData[];
	columns: ColumnDef<RowData>[];
	onTableInitialization?: (table: Table<RowData>) => void;
	onRowSelectionChanging?: (rowSelection: object) => void;
	onPaginationUpdate: (params: PaginationUpdateParams) => void;
	defaultSortBy?: Updater<SortingState>;
	isLoading?: boolean;
	pageCount: number;
	totalCount: number;
}): JSX.Element {
	const [rowSelection, setRowSelection] = React.useState({});
	const [sorting, setSorting] = React.useState<SortingState>([]);
	const [{ pageIndex, pageSize }] = React.useState<PaginationState>(defaultPaginationState);
	const [expanded, setExpanded] = React.useState<ExpandedState>({});
	const pagination = React.useMemo(
		() => ({
			pageIndex,
			pageSize,
		}),
		[pageIndex, pageSize]
	);
	useEffect(() => {
		onPaginationUpdate({ sorting });
	}, [sorting]);
	const tableProps: TableOptions<RowData> = {
		data,
		columns,
		state: {
			sorting,
			rowSelection,
			expanded,
		},
		initialState: {
			pagination,
		},
		manualPagination: true,
		manualSorting: true,
		pageCount,
		onExpandedChange: setExpanded,
		onRowSelectionChange: setRowSelection,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		onSortingChange: (sortingState) => {
			setSorting(sortingState);
		},
		getExpandedRowModel: getExpandedRowModel(),
		sortDescFirst: false,
	};

	if (typeof getTableSubRows === 'function') {
		tableProps.getSubRows = (row: RowData) => getTableSubRows(row);
	}

	const table = useReactTable(tableProps);

	useEffect(() => {
		if (onTableInitialization !== undefined) {
			onTableInitialization(table);
		}
	}, [onTableInitialization, table]);

	useEffect(() => {
		if (onRowSelectionChanging !== undefined) {
			onRowSelectionChanging(rowSelection);
		}
	}, [onRowSelectionChanging, rowSelection]);

	return (
		<div className="p-2">
			{isLoading && (
				<div className="spinnerWrapper">
					<Spinner />{' '}
				</div>
			)}
			<GenericTable table={table} defaultSortBy={defaultSortBy} />
			<GenericTableServerSidePagination
				totalCount={totalCount}
				onPaginationUpdate={onPaginationUpdate}
				table={table}
			/>
		</div>
	);
}
