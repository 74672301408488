import { CoreRow } from '@tanstack/react-table';
import { AxiosError, AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';

import useAxios from '../../../../hooks/axios';
import { IMissionDetailsResponse } from '../../../../models/AdminMissions/AdminMissionHandler';
import { AdminMissionsTable } from '../../../../models/AdminMissions/AdminMissionsTable';
import { ErrorDetails } from '../../../../models/HttpResponse/ErrorDetails';
import { AisMissionDashboardDetails } from '../../../../models/Missions/AisMissionDashboardDetailsType';
import { MissionTypeCode } from '../../../../models/Missions/MissionType';
import { handleMissionDetails } from './MissionDetailsHandler';

export const MissionDetails = ({ mission }: { mission: CoreRow<AdminMissionsTable> }): JSX.Element => {
	const { fetchData } = useAxios({});
	const [handler, setHandler] = useState<IMissionDetailsResponse | null>(null);

	useEffect(() => {
		let getDataAsync = null;
		if (mission?.original.type.toUpperCase() === MissionTypeCode.AccountTransaction.toUpperCase()) {
			getDataAsync = async () =>
				(await fetchData({
					method: 'GET',
					url: `/api/missions/aisdetails/${mission.original.id}`,
					headers: {
						accept: '*/*',
					},
				})) as AxiosResponse<AisMissionDashboardDetails> | AxiosError<ErrorDetails>;
		} else if (mission?.original.type.toUpperCase() === MissionTypeCode.SingleImmediatePayment.toUpperCase()) {
			getDataAsync = async () =>
				(await fetchData({
					method: 'GET',
					url: `/api/missions/${mission.original.id}/sip/admin-details`,
					headers: {
						accept: '*/*',
					},
				})) as AxiosResponse | AxiosError<ErrorDetails>;
		} else if (mission?.original.type.toUpperCase() === MissionTypeCode.UkFasterPayment.toUpperCase()) {
			getDataAsync = async () =>
				(await fetchData({
					method: 'GET',
					url: `/api/missions/${mission.original.id}/ukfp/admin-details`,
					headers: {
						accept: '*/*',
					},
				})) as AxiosResponse | AxiosError<ErrorDetails>;
		}

		setHandler(handleMissionDetails({ row: mission }, getDataAsync));
	}, [mission?.original?.id]);

	return <>{handler?.render()}</>;
};
