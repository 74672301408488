import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import React, { ComponentType, useEffect, useRef, useState } from 'react';
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';

import Spinner from '../../cl-shared-components/Spinner/Spinner';
import useUserInfo from '../../hooks/userInfo';
import UserType from '../../models/User/UserType';
import config, { restrictedUrls } from '../../resources/config';

interface ProtectedRouteProps extends RouteProps {
	component: ComponentType;
	routeRoles?: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component, routeRoles, ...args }) => {
	const { isAuthenticated } = useAuth0();
	const { getUserInfo } = useUserInfo();
	const [isLoading, setIsloading] = useState<boolean | null>(null);
	const initialValues = { userRoles: [''], isRegistrationCompleted: false };
	const ref = useRef(initialValues);
	const history = useHistory();

	const userHasRoles = (roles: string[], userRoles: string[]): boolean =>
		roles && roles.length && userRoles.length ? roles.some((role) => userRoles.indexOf(role) > -1) : true;

	const redirectRoute = (): JSX.Element => {
		const isTester = ref.current.userRoles?.includes(UserType.TESTER);
		const isAdmin = ref.current.userRoles?.includes(UserType.ADMINISTRATOR);
		const isAuthorizedUser = isAdmin || isTester;

		if (
			(ref.current.isRegistrationCompleted && isTester && restrictedUrls.includes(args.path.toString())) ||
			!userHasRoles(routeRoles, ref.current.userRoles) ||
			(ref.current.isRegistrationCompleted && isAdmin && restrictedUrls.includes(args.path.toString())) ||
			(!isAdmin && args.path.toString() === '/create-mission')
		) {
			return <Redirect to="/unauthorized" />;
		}

		if (
			(!isAuthorizedUser && ref.current.isRegistrationCompleted) ||
			(ref.current.isRegistrationCompleted && !isTester && !isAdmin)
		) {
			return <Redirect to="/tester-onboarding" />;
		}

		if (!ref.current.isRegistrationCompleted && isAuthenticated) {
			return <Redirect to="/tester-personal-data" />;
		}

		return <></>;
	};

	const isRegistered = () => {
		setIsloading(true);
		const getIdToken = async () => {
			const { isRegistrationCompleted, roles } = await getUserInfo();

			ref.current.userRoles = roles;
			ref.current.isRegistrationCompleted = isRegistrationCompleted;
			setIsloading(false);
		};

		void getIdToken();
	};

	useEffect(() => {
		if (isLoading === null) {
			isRegistered();
		}
	}, [history.location.pathname, isLoading]);

	if (isLoading === false) {
		return (
			<>
				{redirectRoute()}
				{
					<Route
						component={withAuthenticationRequired(component, {
							onRedirecting: () => <Spinner />,
							loginOptions: {
								scope: config.scope,
								audience: config.audience,
							},
						})}
						{...args}
					/>
				}
			</>
		);
	}

	return (
		<Route
			component={withAuthenticationRequired(() => (
				<Spinner />
			))}
			{...args}
		/>
	);
};
export default ProtectedRoute;
