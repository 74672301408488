export interface IMissionViewDetailsFields {
	[field: string]: {
		[key: string]: string | number | string[];
	};
}

export const SipViewDetailsFields: IMissionViewDetailsFields = {
	applicationLayer: {
		markets: ['DE', 'GB', 'DK', 'NO', 'SE'],
		label: 'Application',
	},
	provider: {
		markets: ['DE', 'GB', 'DK', 'NO', 'SE', 'AT'],
		label: 'Provider',
	},
	amount: {
		markets: ['DE', 'GB', 'DK', 'NO', 'SE', 'AT'],
		label: 'Amount',
	},
	referenceInformation: {
		markets: ['GB', 'DK', 'NO', 'SE', 'AT'],
		label: 'Message',
	},
	endToEndId: {
		markets: ['GB'],
		label: 'End-To-End Id',
	},
	destinationId: {
		markets: ['DE', 'GB', 'DK', 'NO', 'SE', 'AT'],
		label: 'Destination Id',
	},
	schemeId: {
		markets: ['DE', 'GB', 'DK', 'NO', 'SE', 'AT'],
		label: 'Scheme Id',
	},
};

export const UkfpViewDetailsFields: IMissionViewDetailsFields = {
	applicationLayer: {
		label: 'Application',
	},
	provider: {
		label: 'Provider',
	},
	amount: {
		label: 'Amount',
	},
	referenceInformation: {
		label: 'Message',
	},
	endToEndId: {
		label: 'End-To-End Id',
	},
	destinationId: {
		label: 'Destination Id',
	},
};
