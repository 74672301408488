import { Alert } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import Spinner from '../../cl-shared-components/Spinner/Spinner';
import useAxios from '../../hooks/axios';
import { PageTitleContext } from '../../layout/Layout';
import { ErrorDetails } from '../../models/HttpResponse/ErrorDetails';
import StatusCodes from '../../models/HttpResponse/StatusCodes';
import { MISSION_STATUS_COMPLETED, MISSION_STATUS_FAILED } from '../../models/Missions/MissionStatus';
import {
	AccountTransactionCallbackResponse,
	AccountTransactionSyncResponse,
	UpdateMissionStatusPayload,
} from '../../models/Payments/AccountTransactions';
import { AlertStatusInterface } from '../../models/ui/Alert';
import { pageTitles } from '../../resources/config';
import { ACCOUNTS_TRANSACTIONS_FAIL_MESSAGE, ACCOUNTS_TRANSACTIONS_ROUTING_ERRORS } from '../../resources/Errors';
import { ACCOUNT_SUCCESS_MESSAGE } from '../../resources/Success';

const AccountsAndTransactionsStageRouting = (): JSX.Element => {
	const { setPageTitle } = useContext(PageTitleContext);
	const { fetchData } = useAxios({});
	const { missionId } = useParams<{ missionId: string }>();
	const { search, hash } = useLocation();
	const [isLoading, setIsLoading] = useState(false);
	const [missionStatus, setMissionStatus] = useState<AlertStatusInterface>({
		type: 'success',
		message: '',
	});

	const getHashParameters = (): URLSearchParams => new URLSearchParams(hash ? hash.replace('#', '') : search);
	const hashParameters = getHashParameters();

	const accountCallback = async (): Promise<
		AxiosResponse<AccountTransactionCallbackResponse> | AxiosError<ErrorDetails>
	> => {
		const payload = {
			code: hashParameters.get('code'),
			missionId: parseInt(missionId),
		};

		return (await fetchData({
			method: 'post',
			url: 'api/providers/authenticate/callback',
			data: payload,
		})) as AxiosResponse<AccountTransactionCallbackResponse> | AxiosError<ErrorDetails>;
	};

	const accountSync = async (): Promise<AxiosResponse<AccountTransactionSyncResponse> | AxiosError<ErrorDetails>> =>
		(await fetchData({
			method: 'post',
			url: 'api/providers/sync',
			data: {
				missionId,
				endUserContext: {
					language: navigator.language,
					userAgent: navigator.userAgent,
					deviceUuid: uuidv4(),
				},
			},
		})) as AxiosResponse<AccountTransactionSyncResponse> | AxiosError<ErrorDetails>;

	const updateMissionStatus = async (payload: UpdateMissionStatusPayload): Promise<AxiosResponse | AxiosError> =>
		(await fetchData({
			method: 'post',
			url: 'api/missions/status',
			data: payload,
		})) as AxiosResponse | AxiosError;

	const accountCallbackHandler = async (): Promise<boolean> => {
		const stageResponse = await accountCallback();

		if (stageResponse.status !== StatusCodes.OK) {
			const stageResponseError = stageResponse as AxiosError<ErrorDetails>;
			if (stageResponseError) {
				const { data } = stageResponseError.response;
				throw Error(data?.detail || stageResponseError.message);
			}
		}
		const response = stageResponse as AxiosResponse<AccountTransactionCallbackResponse>;
		return response.data.success;
	};

	const accountSyncHandler = async (): Promise<boolean> => {
		const stageResponse = await accountSync();

		if (stageResponse.status !== StatusCodes.OK) {
			const stageResponseError = stageResponse as AxiosError<ErrorDetails>;
			if (stageResponseError) {
				const { data } = stageResponseError.response;
				throw Error(data?.detail || stageResponseError.message);
			}
		}
		const response = stageResponse as AxiosResponse<AccountTransactionSyncResponse>;
		return response.data.success;
	};

	const handleStageRoutingSuccessResponse = async () => {
		try {
			setIsLoading(true);
			const callbackSuccess = await accountCallbackHandler();

			if (callbackSuccess) {
				const syncSuccess = await accountSyncHandler();

				if (syncSuccess) {
					const missionStatusPayload: UpdateMissionStatusPayload = {
						missionId: parseInt(missionId),
						missionStatus: MISSION_STATUS_COMPLETED,
					};
					const { status } = await updateMissionStatus(missionStatusPayload);
					const isMissionRequestOk = status === StatusCodes.OK;

					if (!isMissionRequestOk) {
						throw Error(ACCOUNTS_TRANSACTIONS_FAIL_MESSAGE);
					}

					setMissionStatus({ type: 'success', message: ACCOUNT_SUCCESS_MESSAGE });
				}
			}
		} catch (error) {
			const errorObject = error as Error;
			setMissionStatus({ type: 'error', message: errorObject.message || ACCOUNTS_TRANSACTIONS_FAIL_MESSAGE });
		} finally {
			setIsLoading(false);
		}
	};

	const handleStageRoutingError = async (errorType: string): Promise<void> => {
		let message = ACCOUNTS_TRANSACTIONS_FAIL_MESSAGE;
		if (errorType) {
			message = ACCOUNTS_TRANSACTIONS_ROUTING_ERRORS[errorType];
		}

		setMissionStatus({
			type: 'error',
			message,
		});

		try {
			const missionStatusPayload: UpdateMissionStatusPayload = {
				missionId: parseInt(hashParameters.get('state')),
				missionStatus: MISSION_STATUS_FAILED,
			};
			await updateMissionStatus(missionStatusPayload);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		setPageTitle(pageTitles.accountAuthorization);
		const errorType = hashParameters.get('error');
		const load = async (): Promise<void> => {
			if (errorType) {
				await handleStageRoutingError(errorType);
			} else {
				await handleStageRoutingSuccessResponse();
			}
		};
		void load();
	}, []);

	return (
		<>
			{isLoading && (
				<div className="spinnerWrapper">
					<Spinner />
				</div>
			)}
			{missionStatus.message && <Alert message={missionStatus.message} type={missionStatus.type} />}
		</>
	);
};

export default AccountsAndTransactionsStageRouting;
