import { useAuth0 } from '@auth0/auth0-react';

import config from '../resources/config';

export const useUserInfo = () => {
	const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();

	const getUserInfo = async () => {
		let roles: string[] = [];
		let isRegistrationCompleted = false;

		try {
			await getAccessTokenSilently();

			const token = await getIdTokenClaims();
			isRegistrationCompleted = token[`${config.claim}/isRegistrationCompleted`] as boolean;

			roles = token[`${config.domain}/roles`] as string[];

			return { isRegistrationCompleted, roles };
		} catch (error) {
			return { error };
		}
	};

	return { getUserInfo };
};

export default useUserInfo;
