import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

type SORT_DIRECTION = 0 | 1 | 2;
export const NO_SORT: SORT_DIRECTION = 0;
export const SORT_ASC: SORT_DIRECTION = 1;
export const SORT_DESC: SORT_DIRECTION = 2;

const GenericTableSort = ({ sortDirection }: { sortDirection: SORT_DIRECTION }): JSX.Element => (
	<>
		<CaretUpOutlined className={sortDirection === SORT_ASC ? 'sort-selected' : ''} />
		<CaretDownOutlined className={sortDirection === SORT_DESC ? 'sort-selected' : ''} />
	</>
);

export default GenericTableSort;
