import { Alert } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { CLButton } from '../../cl-shared-components/Buttons';
import Spinner from '../../cl-shared-components/Spinner/Spinner';
import useAxios from '../../hooks/axios';
import { PageTitleContext } from '../../layout/Layout';
import { ErrorDetails } from '../../models/HttpResponse/ErrorDetails';
import StatusCodes from '../../models/HttpResponse/StatusCodes';
import { AisMissionDashboardDetails } from '../../models/Missions/AisMissionDashboardDetailsType';
import { MISSION_STATUS_FAILED } from '../../models/Missions/MissionStatus';
import {
	AccountTransactionConsentResponse,
	UpdateMissionStatusPayload,
} from '../../models/Payments/AccountTransactions';
import { AlertStatusInterface } from '../../models/ui/Alert';
import { pageTitles } from '../../resources/config';
import { ACCOUNTS_TRANSACTIONS_FAIL_MESSAGE } from '../../resources/Errors';

const AccountsAndTransactionsGetStarted = (): JSX.Element => {
	const { fetchData } = useAxios({});
	const { missionId } = useParams<{ missionId: string }>();
	const [isLoading, setIsLoading] = useState(false);
	const [providerName, setProviderName] = useState('');
	const { setPageTitle } = useContext(PageTitleContext);
	const [missionStatus, setMissionStatus] = useState<AlertStatusInterface>({
		type: 'success',
		message: '',
	});

	useEffect(() => {
		setPageTitle(pageTitles.missionStart);
	});

	const getProviderName = async (): Promise<void> => {
		try {
			const result = (await fetchData({
				method: 'get',
				url: `api/missions/aisdetails/${missionId}`,
				headers: { accept: '*/*' },
			})) as AxiosResponse<AisMissionDashboardDetails>;

			setProviderName(result?.data?.provider.split('_')[1]);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		void getProviderName();
	}, []);

	const updateMissionStatus = async (payload: UpdateMissionStatusPayload): Promise<AxiosResponse | AxiosError> =>
		(await fetchData({
			method: 'post',
			url: 'api/missions/status',
			data: payload,
		})) as AxiosResponse | AxiosError;

	const onStart = async (): Promise<void> => {
		setIsLoading(true);
		const payload = {
			missionId,
			code: 'connectivity-lab',
			endUserContext: {
				language: navigator.language,
				userAgent: navigator.userAgent,
				deviceUuid: uuidv4(),
			},
		};

		try {
			const result = (await fetchData({
				method: 'post',
				url: 'api/providers/authenticate',
				data: payload,
			})) as AxiosResponse<AccountTransactionConsentResponse> | AxiosError<ErrorDetails>;
			setIsLoading(false);
			if (result.status === StatusCodes.OK) {
				const response = result as AxiosResponse<AccountTransactionConsentResponse>;
				const { url, success } = response.data;
				if (success) {
					window.location.replace(url);
				} else {
					throw Error(ACCOUNTS_TRANSACTIONS_FAIL_MESSAGE);
				}
			} else {
				const error = result as AxiosError<ErrorDetails>;
				throw Error(error.response.data.detail || ACCOUNTS_TRANSACTIONS_FAIL_MESSAGE);
			}
		} catch (error) {
			const errorObject = error as Error;
			setMissionStatus({ type: 'error', message: errorObject.message });
			const missionStatusPayload: UpdateMissionStatusPayload = {
				missionId: parseInt(missionId),
				missionStatus: MISSION_STATUS_FAILED,
			};
			await updateMissionStatus(missionStatusPayload);
			setIsLoading(false);
		}
	};

	return (
		<>
			{missionStatus.message && <Alert message={missionStatus.message} type={missionStatus.type} />}
			{!missionStatus.message && (
				<>
					<p className="cl-uk-faster-paragraph">
						Please click on the bellow button to initiate selected AIS (Accounts and Transactions) mission.
					</p>
					<p className="cl-uk-faster-paragraph">
						By clicking the button below, you consent to the processing of your financial data, for the
						purpose of quality control the connection to <b>{providerName}</b>. We will fetch data samples
						from some of your accounts, chosen at random. For more information about the processing of your
						data, including information on how to revoke your consent, please see the{' '}
						<Link to="/privacy-policy">Privacy policy</Link>.
					</p>
					{isLoading && (
						<div className="spinnerWrapper">
							<Spinner />
						</div>
					)}

					<CLButton className="cl-button-start" label="Start" onClick={onStart} />
				</>
			)}
		</>
	);
};

export default AccountsAndTransactionsGetStarted;
