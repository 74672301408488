import { IReferenceInformationValidator } from './Models/IReferenceInformationValidator';

export class SwedenReferenceInformationValidator implements IReferenceInformationValidator {
	pattern = /^[A-Za-z0-9 ]{0,10}$/;

	maxLength = 10;

	message?: string;

	constructor() {
		this.message = `Maximum Length is of ${this.maxLength} characters and the message must follow this pattern "${this.pattern}"`;
	}
}
