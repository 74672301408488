import './PageTitle.css';

import React, { Component } from 'react';

interface Props {
	title?: string;
}

class PageTitle extends Component<Props> {
	render() {
		const { title } = this.props;

		return <div className="header-layout">{title}</div>;
	}
}
export default PageTitle;
