import { ILicense } from '../../models/License/License';
import { MissionTypeCode } from '../../models/Missions/MissionType';

export const tokenHeaderElement = (actionText: string) => (
	<div className="cl-license-info">
		Powered by Token: You use Token&apos;s Payment service to {actionText} secure payment directly from your bank
		account.
	</div>
);

export const vrpTokenElement = (props: ILicense) => (
	<>
		{tokenHeaderElement('enable')}
		<div>
			By clicking the button {props.buttonPosition}, You are agreeing to
			<a href="https://token.io/terms" target="_blank">
				{' '}
				Token&apos;s Terms & Privacy Policy
			</a>
			.&nbsp; We will create a consent on your behalf, and for that purpose we might process the list of accounts
			you have access to.
		</div>
	</>
);

export const sipTokenElement = () => (
	<div>
		By accepting, you agree to the Mastercard OB Services UK Limited Terms of Use and permit us to initiate your
		payment from your bank. We will collect your account holder name(s), account number and sort code to enable
		refunds if requested.
	</div>
);

export const defaultTokenElement = (props: ILicense, nullMissionType: boolean) => (
	<>
		{tokenHeaderElement('make a')}
		<div>
			By clicking the button {props.buttonPosition}, You are agreeing to
			<a href="https://token.io/terms" target="_blank">
				{' '}
				Token&apos;s Terms & Privacy Policy
			</a>
			.&nbsp;
			{!nullMissionType && (
				<>
					You consent to the processing of your financial data, for the purpose of quality control the
					connection of {props.displayName}
					.&nbsp;
				</>
			)}
			We will create a payment on your behalf, and for that purpose we might process the list of accounts you have
			access to.
		</div>
	</>
);

export const missionTokens: Partial<Record<MissionTypeCode, (props: ILicense) => JSX.Element>> & {
	default: (props: ILicense, nullMisisonType: boolean) => JSX.Element;
} = {
	[MissionTypeCode.Svrp]: (props) => vrpTokenElement(props),
	[MissionTypeCode.Cvrp]: (props) => vrpTokenElement(props),
	[MissionTypeCode.SingleImmediatePayment]: sipTokenElement,
	default: (props, nullMisisonType) => defaultTokenElement(props, nullMisisonType),
};
