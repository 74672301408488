export type PaymentLimitTypeName =
	| 'paymentDay'
	| 'paymentWeek'
	| 'paymentFortnight'
	| 'paymentMonth'
	| 'paymentHalfAYear'
	| 'paymentYear';

export enum PaymentLimitTypeValues {
	Unknown = -1,
	Day,
	Week,
	Fortnight,
	Month,
	HalfAYear,
	Year,
}

export type PaymentLimitType = {
	name: PaymentLimitTypeName;
	label: string;
	isPeriodic: boolean;
	id: number;
	hide: boolean;
	colSize?: { lg: number };
	startingAtTypesSource?: { name: string; id: number }[];
};

export type PaymentLimit = {
	paymentLimit: number;
	numberOfPayments: number;
	startingType: number;
	isChecked?: boolean;
	settings?: PaymentLimitType;
};

export const getPaymentLimitTypeNames = (): PaymentLimitTypeName[] => [
	'paymentDay',
	'paymentWeek',
	'paymentFortnight',
	'paymentMonth',
	'paymentHalfAYear',
	'paymentYear',
];
