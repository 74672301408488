import moment from 'moment';

import { MISSION_TYPE } from '../models/Missions/MissionType';
import { CONSENT_DETAIL_LABELS, CONSENT_STATUS, ConsentStatusValues } from '../models/Missions/VrpConsentDetailsType';
import {
	CREDITOR_ACCOUNT_TYPE,
	PAYMENT_CONTEXT_CODE,
	PAYMENT_PURPOSE_CODE,
} from '../models/Payments/TransactionRiskIndicators';
import { AmountLimit, AmountLimitTypeValues } from '../models/Payments/Vrp/Limits/AmountLimit';
import { PaymentLimit, PaymentLimitTypeValues } from '../models/Payments/Vrp/Limits/PaymentLimit';
import { dateFormatWithoutTime, dateFormatWithTime } from '../resources/config';

export const getLabelForAmountLimit = (amountLimit: AmountLimit): string => {
	const limit = AmountLimitTypeValues[amountLimit.limitType];
	const key = `maxAmount${limit}` as keyof typeof CONSENT_DETAIL_LABELS;
	return CONSENT_DETAIL_LABELS[key];
};

export const getLabelForPaymentLimit = (amountLimit: PaymentLimit): string => {
	const limit = PaymentLimitTypeValues[amountLimit.paymentLimit];
	const key = `maxPayment${limit}` as keyof typeof CONSENT_DETAIL_LABELS;
	return CONSENT_DETAIL_LABELS[key];
};
export const getPaymentContextCodeByOrdinal = (index: number): PAYMENT_CONTEXT_CODE =>
	Object.values(PAYMENT_CONTEXT_CODE)[index];

export const getPaymentPurposeCodeByOrdinal = (index: number): PAYMENT_PURPOSE_CODE =>
	Object.values(PAYMENT_PURPOSE_CODE)[index];

export const getCreditorAccountTypeByOrdinal = (index: number): CREDITOR_ACCOUNT_TYPE =>
	Object.values(CREDITOR_ACCOUNT_TYPE)[index];

export const getConsentStatusCodeByOrdinal = (consentStatus: ConsentStatusValues): CONSENT_STATUS =>
	Object.values(CONSENT_STATUS)[consentStatus];

export const getMissionTypeByName = (missionType: string): MISSION_TYPE =>
	MISSION_TYPE[missionType as keyof typeof MISSION_TYPE];

export const isBooleanValue = (value: boolean | null): boolean => value !== null && (value === true || value === false);

export const isPropertyDefinedAndNotNull = <D extends object>(details: D, key: keyof D): boolean =>
	key in details && details[key] !== null && details[key] !== undefined;

export const formatDateWithTime = (date: string): string => moment(date).format(dateFormatWithTime.format);

export const formatDateWithoutTime = (date: string): string => moment(date).format(dateFormatWithoutTime.format);

export const cVRPExpirationDateCheck = (date: string): number => moment(date).diff(moment(), 'days');
