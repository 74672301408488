export const MISSION_STATUS_NOT_STARTED = 0;
export const MISSION_STATUS_FAILED = 1;
export const MISSION_STATUS_COMPLETED = 2;

enum MissionStatus {
	NOT_STARTED = 'NOT_STARTED',
	FAILED = 'FAILED',
	COMPLETE_SUCCEEDED = 'COMPLETE_SUCCEEDED',
}
export default MissionStatus;
