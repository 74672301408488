export interface SipGetStartedDetails {
	type: string;
	amount: number;
	currency: string;
	destinationId: string;
	referenceInformation: string;
	countryCode: string;
	displayName: string;
}

export enum SIP_DETAIL_LABELS {
	amount = 'Amount',
	destination = 'Destination',
	message = 'Message',
	title = 'Single Immediate Payment Details',
}
