import { Col, Row } from 'antd';
import React from 'react';

const ConsentItemDetail = (props: { label: string; value: string; key?: string }): JSX.Element => (
	<Row gutter={{ xs: 8, sm: 16 }} key={props.key}>
		<Col xs={12} lg={6}>
			{props.label}:
		</Col>
		<Col xs={12} lg={18}>
			{props.value}
		</Col>
	</Row>
);
export default ConsentItemDetail;
