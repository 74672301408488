import { PaginationState } from '@tanstack/react-table';
import { ModalFuncProps } from 'antd/lib/modal';
import { toast } from 'react-toastify';

import { TableParams } from '../cl-shared-components/GenericTable/TableParams';

export const pathsWithFooter = ['/tester-profile-page'];
export const restrictedUrls = ['/tester-personal-data', '/tester-agreement', '/tester-onboarding'];
export const splunkBaseUrl = 'https://eu-mastercard.splunkcloud.com/en-GB/app/search/search?q=';

// configs
export const defaultToasterConfiguration = {
	position: toast.POSITION.BOTTOM_RIGHT,
	autoClose: 5000,
	hideProgressBar: false,
	closeOnClick: true,
};

export const deleteBankModalConfiguration: ModalFuncProps = {
	width: 670,
	okText: 'Ok',
	cancelText: 'Cancel',
	okType: 'primary',
};

export const defaultPaginationState: PaginationState = {
	pageIndex: 0,
	pageSize: 15,
};

export const initialTableParams: TableParams = {
	page: 1,
	pageSize: 15,
	sortBy: '',
	isSortAscending: true,
};
export const missionName = 'Complete Uk Faster Payments Test';

export const dateFormatWithTime = {
	format: 'MM/DD/YYYY hh:mm:ss A',
};

export const dateFormatWithoutTime = {
	format: 'MM/DD/YYYY',
};

export const DATE_PICKER_FORMAT = 'DD/MM/YYYY';

export const defaultPages = {
	tester: '/tester-dashboard-page',
	admin: '/admin-dashboard-page',
};

export const defaultRowsPerPageOptions: Array<number | { value: number; label: string }> = [
	defaultPaginationState.pageSize,
	20,
	25,
	50,
];

export const UK_FASTER_PAYMENT_IN_PROGRESS_STATUS = 'InProgress';

export const pageTitles: Record<string, string> = {
	createMissions: 'Create Mission',
	adminMissionsTable: 'My Created Missions',
	adminMissionResultsPage: 'Missions Results',
	adminApprovePage: 'Profiles for Approval',
	testerMissionsTable: 'My Assigned Missions',
	testerProfilePage: 'My Banks',
	missionStart: 'Mission Start',
	paymentAuthorization: 'Payment Authorization',
	notFound: 'This page was not found',
	notAuthorized: 'Your access is not authorized',
	welcome: 'Welcome',
	privacyPolicy: 'Privacy Policy',
	vrpConsent: 'Mission Start',
	accountAuthorization: 'Account Authorization',
};

export const IBAN_VALID_LENGTH_GB = 22;
export const BBAN_VALID_LENGTH_GB = [14];
export const COUNTRY_CODE_GB = 'GB';
export const DEFAULT_AMOUNT_LIMIT_LENGTH = 2;
export const NATWEST_AMOUNT_LIMIT_LENGTH = 3;

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_PLACEHOLDER = 'yyyy-mm-dd';

export const DEFAULT_CURRENCY = 'GBP';

declare global {
	interface Window {
		REACT_APP_API_BASE_URL: string;
		REACT_APP_AUTH_AUDIENCE: string;
		REACT_APP_AUTH_CLIENT_ID: string;
		REACT_APP_AUTH_DOMAIN: string;
		REACT_APP_ENV_NAME: string;
		REACT_APP_USE_AWS: string;
		REACT_APP_BASE_URL: string;
	}
}

const config = (() => {
	if (window.REACT_APP_USE_AWS === 'true') {
		return {
			clientId: window.REACT_APP_AUTH_CLIENT_ID,
			enableAppInsights: false,
			baseUrl: window.REACT_APP_API_BASE_URL,
			envName: window.REACT_APP_ENV_NAME,
			audience: window.REACT_APP_AUTH_AUDIENCE,
			domain: window.REACT_APP_AUTH_DOMAIN,
			scope: window.REACT_APP_API_BASE_URL,
			claim: process.env.REACT_APP_CUSTOM_CLAIMS_AWS,
			basename: window.REACT_APP_BASE_URL,
		};
	}

	return {
		clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
		enableAppInsights: true,
		baseUrl: process.env.REACT_APP_API_BASE_URL,
		envName: process.env.REACT_APP_ENV_NAME,
		audience: process.env.REACT_APP_AUTH_AUDIENCE,
		domain: process.env.REACT_APP_AUTH_DOMAIN,
		scope: process.env.REACT_APP_API_BASE_URL,
		claim: process.env.REACT_APP_CUSTOM_CLAIMS,
		basename: process.env.REACT_APP_PUBLIC_URL,
	};
})();

export default config;
