import { TablePagination } from '@mui/material';
import { PaginationState, RowData, Table } from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';

import { PaginationUpdateParams } from '../../models/GenericTable/PaginationUpdateParams';
import { defaultPaginationState, defaultRowsPerPageOptions } from '../../resources/config';

export default function GenericTableServerSidePagination({
	table,
	totalCount,
	onPaginationUpdate,
}: {
	table: Table<RowData>;
	totalCount: number;
	onPaginationUpdate: (params: PaginationUpdateParams) => void;
}) {
	const [controller, setController] = useState<PaginationState>(defaultPaginationState);

	const rowsPerPageOptions = [...defaultRowsPerPageOptions, { label: 'All', value: totalCount }];

	const [pageSizeSelected, setPageSizeSelected] = useState<number | { value: number; label: string }>(
		controller.pageSize
	);

	const handlePageChange = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		const currentController: PaginationState = {
			...controller,
			pageIndex: newPage,
		};
		setController(currentController);
		onPaginationUpdate({ pagination: currentController });
	};
	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const currentController: PaginationState = {
			...controller,
			pageSize: parseInt(event.target.value, 10),
			pageIndex: Number(event.target.value) < totalCount ? 0 : controller.pageIndex,
		};
		setController(currentController);
		onPaginationUpdate({ pagination: currentController });
	};

	const handleOnRowsPerPageChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const size = event.target.value ? Number(event.target.value) : defaultPaginationState.pageSize;
		const selectedOption = rowsPerPageOptions.find((option) =>
			typeof option === 'object' ? option.value === size : option === size
		);

		setPageSizeSelected(selectedOption);
		handleChangeRowsPerPage(event);
	};

	useEffect(() => {
		if (typeof pageSizeSelected === 'object' && controller.pageSize !== totalCount) {
			table.setPageSize(totalCount);
		}
	}, [table.getState().pagination]);

	return (
		<TablePagination
			sx={{
				'.MuiInputBase-root': {
					top: -7,
				},
				'.MuiTablePagination-displayedRows': {
					top: -7,
				},
				'.MuiButtonBase-root': {
					top: -7,
				},
			}}
			showFirstButton={true}
			showLastButton={true}
			component="div"
			onPageChange={handlePageChange}
			page={controller.pageSize >= totalCount ? 0 : controller.pageIndex}
			count={totalCount}
			rowsPerPage={controller.pageSize}
			rowsPerPageOptions={rowsPerPageOptions}
			onRowsPerPageChange={handleOnRowsPerPageChange}
		/>
	);
}
