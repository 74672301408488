import './VrpMissionConsent.css';

import { Col, Row } from 'antd';

import {
	CONSENT_STATUS,
	ConsentStatusValues,
	VrpMissionDetails,
} from '../../../../../models/Missions/VrpConsentDetailsType';
import { cVRPExpirationDateCheck, getConsentStatusCodeByOrdinal } from '../../../../../utils/VrpConsentUtils';
import VrpConsentDetails from './VrpConsentDetails';

interface VrpMissionConsentProps {
	data: VrpMissionDetails;
}

const VrpMissionConsent = (props: VrpMissionConsentProps): JSX.Element => {
	const { consentDetails, status } = props.data;
	const { consentStatus } = consentDetails;

	const renderActiveIcon = (): JSX.Element => (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
				fill="#35B132"
			/>
		</svg>
	);

	const renderExpiredIcon = (): JSX.Element => (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
				fill="#995600"
			/>
		</svg>
	);

	const renderRevokedIcon = (): JSX.Element => (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
				fill="#EE0000"
			/>
		</svg>
	);

	const renderStatusIcon = (): JSX.Element => {
		switch (consentDetails.consentStatus) {
			case ConsentStatusValues.ACTIVE:
				return renderActiveIcon();
			case ConsentStatusValues.EXPIRED:
				return renderExpiredIcon();
			case ConsentStatusValues.REVOKED:
				return renderRevokedIcon();
			case ConsentStatusValues.CREATED:
			default:
				return <></>;
		}
	};

	return (
		<div className="cl-details-container">
			<Row className="cl-consent-status" gutter={{ xs: 8, sm: 8 }}>
				<Col lg={7}>
					<h6>Consent Status:</h6>
				</Col>
				<Col lg={17}>
					{cVRPExpirationDateCheck(consentDetails.validTo) < 0 ? (
						<>
							{renderExpiredIcon()}
							{getConsentStatusCodeByOrdinal(ConsentStatusValues.EXPIRED)}
						</>
					) : (
						<>
							{status.toLowerCase() !== CONSENT_STATUS.FAILED.toLowerCase() && renderStatusIcon()}{' '}
							{getConsentStatusCodeByOrdinal(
								status.toLowerCase() === CONSENT_STATUS.FAILED.toLowerCase()
									? ConsentStatusValues.FAILED
									: consentStatus
							)}
						</>
					)}
				</Col>
			</Row>
			<VrpConsentDetails data={props.data} />
		</div>
	);
};

export default VrpMissionConsent;
