import { Col, Row } from 'antd';

import { SIP_DETAIL_LABELS, SipGetStartedDetails } from '../../models/Missions/SipGetStartedDetails';
import ConsentItemDetail from '../VrpConsent/ConsentItemDetail';

interface SipGetStartedDetailsProps {
	data: SipGetStartedDetails;
}

const SipsWithDetails = (props: SipGetStartedDetailsProps): JSX.Element => {
	const { amount, currency, destinationId, referenceInformation } = props.data;

	return (
		<Row className="vrp-consent-container">
			<h3>{SIP_DETAIL_LABELS.title}</h3>

			<Col xs={24} lg={24} className="vrp-consent-container-items">
				<ConsentItemDetail label={SIP_DETAIL_LABELS.amount} value={`${amount} ${currency}`} />

				<ConsentItemDetail label={SIP_DETAIL_LABELS.destination} value={destinationId} />

				<ConsentItemDetail label={SIP_DETAIL_LABELS.message} value={referenceInformation} />
			</Col>
		</Row>
	);
};

export default SipsWithDetails;
