export const countries: string[] = [
	'Austria',
	'Belgium',
	'Bulgaria',
	'Croatia',
	'Cyprus',
	'Czechia',
	'Denmark',
	'Estonia',
	'Faroe Islands',
	'Finland',
	'France',
	'Germany',
	'Gibraltar',
	'Greece',
	'Greenland',
	'Guernsey',
	'Hungary',
	'Iceland',
	'Ireland',
	'Isle Of Man',
	'Italy',
	'Jersey',
	'Latvia',
	'Liechtenstein',
	'Lithuania',
	'Luxembourg',
	'Malta',
	'Netherlands',
	'Norway',
	'Poland',
	'Portugal',
	'Romania',
	'San Marino',
	'Slovakia',
	'Slovenia',
	'Spain',
	'Sweden',
	'Switzerland',
	'United Kingdom',
];
