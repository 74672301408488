export interface UkfpGetStartedDetails {
	type: string;
	amount: number;
	currency: string;
	creditorSortCode: string;
	creditorAccountNumber: string;
	referenceInformation: string;
	countryCode: string;
	displayName: string;
}

export enum UKFP_DETAIL_LABELS {
	amount = 'Amount',
	destination = 'Destination',
	message = 'Message',
	title = 'UK Faster Payment Details',
}
