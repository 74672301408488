import { InputProps } from 'antd';

export interface IBasicInputProps {
	label?: string;
	required?: boolean;
	colSize?: { lg?: number; md?: number; sm?: number };
}

export interface CLInputPropsInterface extends IBasicInputProps, InputProps {
	type?: string;
}

export type CLInputPropsType = {
	label?: string;
	required?: boolean;
	className?: string;
	colSize?: { lg?: number; md?: number; sm?: number };
};

export const defaultProps = {
	colSize: {
		lg: 12,
		md: 12,
		sm: 12,
	},
};
