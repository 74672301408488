export enum AdminMissionType {
	UkFasterPayment = 'UkFasterPayment',
	Cvrp = 'Cvrp',
	Svrp = 'Svrp',
	SingleImmediatePayment = 'SingleImmediatePayment',
	AccountsAndTransactions = 'AccountsAndTransactions',
	Unknown = 'Unknown',
}

export enum AdminMissionTypeDisplay {
	UkFasterPayment = 'Uk Faster Payment',
	Cvrp = 'Commercial VRP',
	Svrp = 'Sweeping VRP',
	SingleImmediatePayment = 'Single Immediate Payment',
	AccountsAndTransactions = 'Accounts and Transactions',
	Unknown = 'Unknown',
}
