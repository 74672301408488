import { toast } from 'react-toastify';

import { defaultToasterConfiguration } from '../../resources/config';

export const DisplaySuccessToaster = (message: string) => {
	toast.success(message, defaultToasterConfiguration);
};
export const DisplayErrorToaster = (message: string) => {
	toast.error(message, defaultToasterConfiguration);
};
export const DisplayWarningToaster = (message: string) => {
	toast.warn(message, defaultToasterConfiguration);
};
