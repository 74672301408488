import { DEFAULT_CURRENCY } from '../../../resources/config';
import { AmountLimit, AmountLimitTypeName, AmountLimitTypeValues } from './Limits/AmountLimit';
import { PaymentLimit, PaymentLimitTypeName, PaymentLimitTypeValues } from './Limits/PaymentLimit';
import { StartingAtType } from './VrpProviderDetails';

export const VrpProviderDetailsDefaultValues = {
	amount: 0.1,
	currency: DEFAULT_CURRENCY,
	referenceInformation: 'CVRP Test',
	endToEndId: 'e2e123',
	numberOfPayments: 2,
};

const startingAtTypes = [
	{ name: StartingAtType[StartingAtType.Consent], id: StartingAtType.Consent },
	{ name: StartingAtType[StartingAtType.Calendar], id: StartingAtType.Calendar },
];

const getDefaultAmountLimitValue = (
	label: { checkboxLabel: string; amountLabel?: string },
	name: AmountLimitTypeName,
	isPeriodic: boolean,
	id: number,
	colSize?: { lg: number },
	startingAtTypesSource: { name: string; id: number }[] = startingAtTypes,
	hide = false
): AmountLimit => ({
	settings: {
		label,
		name,
		isPeriodic,
		hide,
		id,
		colSize,
		startingAtTypesSource,
	},
	amount: 0,
	currency: '',
	startingType: StartingAtType.Unknown,
	isChecked: false,
	limitType: id,
});

export const defaultAmountLimitValues = {
	totalAmount: getDefaultAmountLimitValue(
		{ checkboxLabel: 'Total amount', amountLabel: 'Maximum total amount' },
		'totalAmount',
		false,
		AmountLimitTypeValues.TotalAmount,
		{ lg: 12 }
	),
	perPayment: getDefaultAmountLimitValue(
		{ checkboxLabel: 'Per payment' },
		'perPayment',
		false,
		AmountLimitTypeValues.PerPayment
	),
	day: getDefaultAmountLimitValue({ checkboxLabel: 'Day' }, 'day', true, AmountLimitTypeValues.Day),
	week: getDefaultAmountLimitValue({ checkboxLabel: 'Week' }, 'week', true, AmountLimitTypeValues.Week),
	fortnight: getDefaultAmountLimitValue(
		{ checkboxLabel: 'Fortnight' },
		'fortnight',
		true,
		AmountLimitTypeValues.Fortnight,
		{ lg: 6 },
		[...startingAtTypes.slice(0, 1)]
	),
	month: getDefaultAmountLimitValue({ checkboxLabel: 'Month' }, 'month', true, AmountLimitTypeValues.Month),
	halfAYear: getDefaultAmountLimitValue(
		{ checkboxLabel: 'Half a year' },
		'halfAYear',
		true,
		AmountLimitTypeValues.HalfAYear
	),
	year: getDefaultAmountLimitValue({ checkboxLabel: 'Year' }, 'year', true, AmountLimitTypeValues.Year),
};

const getDefaultPaymentLimitValue = (
	label: string,
	name: PaymentLimitTypeName,
	id: number,
	hide = true,
	startingAtTypesSource: { name: string; id: number }[] = startingAtTypes,
	numberOfPayments = 0,
	startingType = StartingAtType.Unknown,
	isChecked = false
): PaymentLimit => ({
	settings: {
		label,
		name,
		isPeriodic: true,
		hide,
		id,
		colSize: { lg: 12 },
		startingAtTypesSource,
	},
	numberOfPayments,
	startingType,
	isChecked,
	paymentLimit: id,
});

export const getDefaultPaymentLimitValues = (hide = false) => {
	const defaulValues = {
		paymentDay: getDefaultPaymentLimitValue(
			'Day',
			'paymentDay',
			PaymentLimitTypeValues.Day,
			hide,
			startingAtTypes,
			2,
			StartingAtType.Consent,
			true
		),
		paymentWeek: getDefaultPaymentLimitValue('Week', 'paymentWeek', PaymentLimitTypeValues.Week, hide),
		paymentFortnight: getDefaultPaymentLimitValue(
			'Fortnight',
			'paymentFortnight',
			PaymentLimitTypeValues.Fortnight,
			hide,
			[...startingAtTypes.slice(0, 1)],
			0,
			StartingAtType.Unknown,
			false
		),
		paymentMonth: getDefaultPaymentLimitValue('Month', 'paymentMonth', PaymentLimitTypeValues.Month, hide),
		paymentHalfAYear: getDefaultPaymentLimitValue(
			'Half a year',
			'paymentHalfAYear',
			PaymentLimitTypeValues.HalfAYear,
			hide
		),
		paymentYear: getDefaultPaymentLimitValue('Year', 'paymentYear', PaymentLimitTypeValues.Year, hide),
	};

	return defaulValues;
};
