import moment from 'moment';
import * as yup from 'yup';

import { DATE_FORMAT } from '../../../resources/config';
import { DESTINATION_NAME_REQUIRED_ERROR_MESSAGE } from '../../../resources/Errors';
import { isTownNameRequired } from '../../../utils/MissionUtils';

const UkFasterPaymentSchemaValidation = yup.object().shape({
	schedulePayment: yup.bool().default(false),
	amount: yup
		.number()
		.min(0.01, 'The Amount must be greater than 0')
		.typeError('The Amount must be filled in')
		.test('is-decimal', 'The Amount must have maximum two decimals', (value: number) =>
			/^\d{0,4}(?:[.,]\d{1,2})?$/.test(`${value}`)
		),
	currency: yup
		.string()
		.test('required', 'The Currency must be filled in', (value: string) => value !== '')
		.test('number-of-3-characters', 'The Currency requests a number of 3 characters', (value: string) =>
			/^[a-zA-Z]{3}$/.test(value)
		)
		.trim(),
	executionDate: yup
		.date()
		.nullable()
		.when('schedulePayment', {
			is: true,
			then: yup
				.date()
				.nullable()
				.min(moment().format(DATE_FORMAT), 'The Execution Date must be today or a future date')
				.required('Execution Date must be filled in'),
		})
		.when('schedulePayment', {
			is: false,
			then: yup.date().default(null).nullable(),
		}),
	referenceInformation: yup
		.string()
		.required('The Message Text must be filled in')
		.max(18, 'The Message Text requests maximum 18 characters')
		.trim(),
	townName: yup.string().when('bankId', {
		is: (value: string) => isTownNameRequired(value),
		then: yup
			.string()
			.max(35, 'The Town requests maximum 35 characters')
			.required('The Town must be filled in')
			.trim(),
		otherwise: yup.string().nullable().notRequired(),
	}),
	creditorName: yup.string().required(DESTINATION_NAME_REQUIRED_ERROR_MESSAGE),
	creditorSortCode: yup
		.string()
		.nullable()
		.test(
			'only-numeric',
			'The Sort Code must contain only digits',
			(value: string) => !value || /^\d+$/.test(value)
		)
		.test(
			'number-of-8-characters',
			'The Sort Code requests a number of 6 characters',
			(value: string) => !value || /^\w{6}$/.test(value)
		)
		.trim(),
	creditorAccountNumber: yup
		.string()
		.nullable()
		.test(
			'only-numeric',
			'The Account Number must contain only digits',
			(value: string) => !value || /^\d+$/.test(value)
		)
		.test(
			'number-of-8-characters',
			'The Account Number requests a number of 8 characters',
			(value: string) => !value || /^\w{8}$/.test(value)
		)
		.trim(),
	endToEndID: yup
		.string()
		.required('The End-to-End Id must be filled in')
		.test('number-of-35-characters', 'The End-to-End Id requests a number of 35 characters', (value: string) =>
			/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{11}$/.test(value)
		)
		.trim(),
});

export { UkFasterPaymentSchemaValidation };
