import 'antd/dist/antd.css';

import { useAuth0 } from '@auth0/auth0-react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import UserType from '../../models/User/UserType';
import config, { defaultPages } from '../../resources/config';
import { setMetrics } from '../../resources/insights-connector';

const adminMenuItems: MenuProps['items'] = [
	{
		label: <NavLink to={'/admin-tester-approval'}>Tester Approval</NavLink>,
		key: 'admin-tester-approval',
	},
	{
		label: <NavLink to={'/create-mission'}>Create Mission</NavLink>,
		key: 'create-mission',
	},
	{
		label: <NavLink to={'/results'}>Results</NavLink>,
		key: 'results',
	},
];

const testerMenuItems: MenuProps['items'] = [
	{
		label: <NavLink to={defaultPages.admin}>Dashboard</NavLink>,
		key: 'admin-dashboard-page',
	},
	{
		label: <NavLink to={defaultPages.tester}>My Work</NavLink>,
		key: 'tester-dashboard-page',
	},
	{
		label: <NavLink to={'/tester-profile-page'}>My Profile</NavLink>,
		key: 'tester-profile-page',
	},
];

const getPathNameFromHistory = (pathName: string): string => {
	const splitedPathName = pathName.split('/');
	const [, second] = splitedPathName;
	const pathNameCopy = second;
	return pathNameCopy || 'dashboard' || 'my work';
};

const { enableAppInsights } = config;

const OptionsMenu = ({ mode }: MenuProps): React.ReactElement<Menu> => {
	const history = useHistory();
	const appInsights = enableAppInsights ? useAppInsightsContext() : null;

	const currentPath = getPathNameFromHistory(history.location.pathname);
	const [current, setCurrent] = useState(currentPath);

	const [items, setItems] = useState<MenuProps['items']>(null);
	const { getIdTokenClaims, isAuthenticated } = useAuth0();
	const locationState = useLocation();
	const [route, setRoute] = useState<string>(locationState.pathname);

	useEffect(() => {
		setRoute(locationState.pathname);

		if (enableAppInsights && appInsights) {
			setMetrics(appInsights, locationState.pathname);
		}
	}, [locationState]);

	useEffect(
		() =>
			history.listen((location) => {
				setCurrent(getPathNameFromHistory(location.pathname));
			}),
		[current]
	);

	const setMenuItems = (roles: string[]): void => {
		if (roles.includes(UserType.ADMINISTRATOR) && roles.includes(UserType.TESTER)) {
			setItems(adminMenuItems.concat(testerMenuItems));
		} else if (roles.includes(UserType.ADMINISTRATOR)) {
			setItems(adminMenuItems);
		} else if (roles.includes(UserType.TESTER)) {
			setItems(testerMenuItems);
		} else {
			setItems([]);
		}
	};

	const setDefaultRoute = (roles: string[]): void => {
		if (roles.includes(UserType.ADMINISTRATOR) && route === '/') {
			history.push(defaultPages.admin);
		} else if (roles.includes(UserType.TESTER) && route === '/') {
			history.push(defaultPages.tester);
		}
	};
	useEffect(() => {
		const load = async (): Promise<void> => {
			const data = await getIdTokenClaims();
			if (data !== undefined) {
				const roles = data[`${config.domain}/roles`] as string[];
				setMenuItems(roles);
				setDefaultRoute(roles);
			}
		};
		load().catch((err) => console.error(err));
		setCurrent(currentPath);
	}, [isAuthenticated, getIdTokenClaims]);

	return (
		<Menu
			className={items?.length > 2 ? 'desktop-menu' : ''}
			selectedKeys={[current]}
			mode={mode}
			items={items}
			defaultSelectedKeys={[current]}
			disabledOverflow={true}
		/>
	);
};

export default OptionsMenu;
