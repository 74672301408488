import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

interface RouterPromptProps {
	when: boolean;
	onOK: () => boolean;
	onCancel: () => boolean;
}

interface RouterPromptActions {
	showRoutePrompt: boolean;
	onRoutePromptConfirm: () => Promise<void>;
	onRoutePromptCancel: () => Promise<void>;
}

const useRouterPrompt = (props: RouterPromptProps): RouterPromptActions => {
	const { when, onOK, onCancel } = props;

	const history = useHistory();

	const [showRoutePrompt, setShowRoutePrompt] = useState(false);
	const [currentPath, setCurrentPath] = useState('');

	useEffect(() => {
		if (when) {
			history.block((prompt) => {
				setCurrentPath(prompt.pathname);
				setShowRoutePrompt(true);
				return 'true';
			});
		} else {
			history.block(() => {
				// override  prompt
			});
		}

		return () => {
			history.block(() => {
				// override  prompt
			});
		};
	}, [history, when]);

	const onRoutePromptConfirm = useCallback(async () => {
		if (onOK) {
			const canRoute = await Promise.resolve(onOK());
			if (canRoute) {
				history.block(() => {
					// override  prompt
				});
				history.push(currentPath);
			}
		}
	}, [currentPath, history, onOK]);

	const onRoutePromptCancel = useCallback(async () => {
		if (onCancel) {
			const canRoute = await Promise.resolve(onCancel());
			if (canRoute) {
				history.block(() => {
					// override  prompt
				});
				history.push(currentPath);
			}
		}
		setShowRoutePrompt(false);
	}, [currentPath, history, onCancel]);

	return { showRoutePrompt, onRoutePromptConfirm, onRoutePromptCancel };
};

export default useRouterPrompt;
