import { Link } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { Col, Empty, Form, Row, Select } from 'antd';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import GenericTableWithServerSidePagination from '../../../../cl-shared-components/GenericTable/GenericTableWithServerSidePagination';
import { TableParams } from '../../../../cl-shared-components/GenericTable/TableParams';
import { onPaginationUpdate } from '../../../../cl-shared-components/GenericTable/TableUtils';
import useAxios from '../../../../hooks/axios';
import { PageTitleContext } from '../../../../layout/Layout';
import { AdminMissionTypeDisplay } from '../../../../models/AdminMissions/AdminMissionType';
import { PaginationUpdateParams } from '../../../../models/GenericTable/PaginationUpdateParams';
import { Area, areas } from '../../../../models/Missions/Area';
import { AssignedMissions } from '../../../../models/Missions/AssignedMissions';
import MappedMissionStatus from '../../../../models/Missions/MappedMissionStatus';
import MissionActionByStatus from '../../../../models/Missions/MissionActionByStatus';
import { TesterMissionsTable } from '../../../../models/Tester/TesterMissionsTable';
import { dateFormatWithTime, initialTableParams, pageTitles } from '../../../../resources/config';
import { MISSIONS_AREA_RULE_MESSAGE } from '../../../../resources/Errors';
import { License } from '../../../Licenses';

const TesterMissionsProfile = (): JSX.Element => {
	const VRP_CONSENT_URL = 'vrp-consent';
	const UK_FASTER_PAYMENT_URL = 'uk-faster-payment';
	const ACCOUNTS_AND_TRANSACTIONS_URL = 'accounts';
	const APV3_COMMERCIAL_VRP_CONSENT_URL = 'apv3-cvrp-consent';
	const SINGLE_IMMEDIATE_PAYMENT_URL = 'single-immediate-payment';

	const { Option } = Select;
	const { fetchData } = useAxios({});
	const { setPageTitle } = useContext(PageTitleContext);
	const history = useHistory();

	initialTableParams.sortBy = 'CreatedDate';
	initialTableParams.area = areas.find((area) => area.value === 'Uk')?.value;
	const [tableParams, setTableParams] = React.useState<TableParams>(structuredClone(initialTableParams));
	const [testerMissions, setTesterMissions] = useState<TesterMissionsTable[]>([]);
	const [totalCount, setTotalCount] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const getTesterMisisons = async (): Promise<void> => {
		try {
			setIsLoading(true);
			const result = (await fetchData({
				method: 'GET',
				url: '/api/missions/testermissions',
				headers: {
					accept: '*/*',
				},
				params: tableParams,
			})) as AxiosResponse<AssignedMissions<TesterMissionsTable[]>>;

			setTesterMissions(result.data?.missions || []);
			setTotalCount(result.data?.totalCount || 0);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			console.error(error);
		}
	};

	useEffect(() => {
		setPageTitle(pageTitles.testerMissionsTable);
		getTesterMisisons().catch((err) => console.error(err));
	}, [tableParams.isSortAscending, tableParams.sortBy, tableParams.pageSize, tableParams.page, tableParams.area]);

	const getUrl = (missionType: string, applicationLayer: string): string => {
		switch (missionType) {
			case 'UkFasterPayment':
				return UK_FASTER_PAYMENT_URL;
			case 'Cvrp':
				return applicationLayer === 'Core' ? VRP_CONSENT_URL : APV3_COMMERCIAL_VRP_CONSENT_URL;
			case 'Svrp':
				return VRP_CONSENT_URL;
			case 'SingleImmediatePayment':
				return SINGLE_IMMEDIATE_PAYMENT_URL;
			case 'AccountsAndTransactions':
				return ACCOUNTS_AND_TRANSACTIONS_URL;
			default:
				throw new Error(`Unsupported mission type: ${missionType}`);
		}
	};

	const triggerMissionAction = (mission: TesterMissionsTable): void => {
		const url = `/${getUrl(mission.type, mission.applicationLayer)}/${mission.id}`;
		history.push(url);
	};

	const columns = React.useMemo<ColumnDef<TesterMissionsTable>[]>(
		() => [
			{
				header: 'Provider',
				accessorFn: (row) => row.bankName,
				id: 'BankName',
			},
			{
				header: 'Mission ID',
				accessorFn: (row) => row.id,
				id: 'Id',
			},
			{
				header: 'Mission',
				accessorFn: (row) => AdminMissionTypeDisplay[row.type as keyof typeof AdminMissionTypeDisplay],
				id: 'Type',
			},
			{
				header: 'Status',
				cell: ({ getValue }) => (
					<div>
						<>{getValue()}</>
					</div>
				),
				accessorFn: (row) => MappedMissionStatus[row.status],
				id: 'Status',
			},
			{
				header: 'Requested Date',
				accessorFn: (row) => moment(row.createdDate).format(dateFormatWithTime.format),
				id: 'CreatedDate',
			},
			{
				header: () => <div className="cursor-pointer select-none">Actions</div>,
				enableSorting: false,
				cell: (record) => (
					<Link className="cell-content" onClick={() => triggerMissionAction(record.row.original)}>
						<>{record.getValue()}</>
					</Link>
				),
				accessorFn: (row) => MissionActionByStatus[row.status],
				id: 'actions',
			},
		],
		[]
	);

	const onAreaChange = (area: string): void => {
		try {
			const tableParamsCopy = structuredClone(tableParams);
			tableParamsCopy.area = area;
			setTableParams(tableParamsCopy);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<div>
			<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
				<Col className="gutter-row">
					<Form.Item
						label="Area"
						name="Area"
						className="select-form-item"
						labelCol={{ span: 24 }}
						wrapperCol={{ span: 24 }}
						rules={[{ required: true, message: MISSIONS_AREA_RULE_MESSAGE }]}
					>
						<Select
							showSearch
							optionFilterProp="children"
							placeholder="Click on the dropdown to select an area"
							className="market-select"
							defaultValue={'Uk'}
							onChange={onAreaChange}
						>
							{areas?.map((area: Area) => (
								<Option value={area.value} key={area.id}>
									{area.name}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Col>
			</Row>

			{testerMissions.length > 0 ? (
				<GenericTableWithServerSidePagination
					pageCount={Math.ceil(totalCount / tableParams.pageSize)}
					isLoading={isLoading}
					totalCount={totalCount}
					onPaginationUpdate={(params: PaginationUpdateParams): void => {
						setTableParams(
							onPaginationUpdate({ tableParams: params, stateTableParams: tableParams, totalCount })
						);
					}}
					defaultSortBy={[{ id: initialTableParams.sortBy, desc: false }]}
					{...{ data: testerMissions, columns }}
				/>
			) : (
				<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
			)}
			<License area={tableParams.area.toString()} />
		</div>
	);
};

export default TesterMissionsProfile;
