import { PaymentLimit } from '../Payments/Vrp/Limits/PaymentLimit';
import { MissionTypeCode } from './MissionType';
import { BaseVrpMissionConsentDetails } from './VrpMissionConsent/BaseVrpMissionConsentDetails';
import { VrpPaymentDetailsType } from './VrpPaymentDetailsType';

export enum ConsentStatusValues {
	CREATED = 0,
	ACTIVE = 1,
	REVOKED = 2,
	EXPIRED = 3,
	FAILED = 4,
}

export enum CONSENT_STATUS {
	CREATED = 'Created',
	ACTIVE = 'Active',
	REVOKED = 'Revoked',
	EXPIRED = 'Expired',
	FAILED = 'Failed',
}

export interface ConsentPaymentDetails {
	amount: number;
	currency: string;
	endToEndId: string;
}
export interface VrpMissionConsentDetails extends BaseVrpMissionConsentDetails {
	consentStatus: ConsentStatusValues;
	application: string;
	provider: string;
	validFrom: string;
	paymentContextCode?: number;
	creditorAccountType?: number;
	isContractPresent?: boolean;
	isCreditorPrePopulated?: boolean;
	paymentPurposeCode?: number;
	paymentLimits?: PaymentLimit[];
	consentPaymentDetails?: ConsentPaymentDetails;
}
export interface VrpMissionDetails {
	id?: number;
	title?: string;
	type: string;
	createdDate: string;
	status?: string;
	consentDetails: VrpMissionConsentDetails;
	paymentDetails: VrpPaymentDetailsType[];
}

export enum CONSENT_DETAIL_LABELS {
	application = 'Application',
	provider = 'Provider',
	vrpType = 'VRP Type',
	destinationName = 'Destination Name',
	destinationSortCode = 'Destination Sort Code',
	destinationAccountNumber = 'Destination Account number',

	maxAmountTotalAmount = 'Max amount per consent',
	maxAmountPerPayment = 'Max amount per payment',
	maxAmountDay = 'Max amount per day',
	maxAmountWeek = 'Max amount per week',
	maxAmountFortnight = 'Max amount per fortnight',
	maxAmountMonth = 'Max amount per month',
	maxAmountHalfAYear = 'Max amount per half a year',
	maxAmountYear = 'Max amount per year',

	maxPaymentDay = 'Max payments per day',
	maxPaymentWeek = 'Max payments per week',
	maxPaymentFortnight = 'Max payments per fortnight',
	maxPaymentMonth = 'Max payments per month',
	maxPaymentHalfAYear = 'Max payments per half a year',
	maxPaymentYear = 'Max payments per year',

	consentCreatedDate = 'Consent Created Date',
	consentExpiryDate = 'Consent Expiry Date',
	consentRequestDateAndTime = 'Consent Request Date and Time',

	paymentContextCode = 'Payment Context Code',
	paymentPurposeCode = 'Payment Purpose Code',
	creditorAccountType = 'Creditor Account type',
	isContractPresent = 'Is contract present',
	isCreditorPrePopulated = 'Is creditor prepopulated',

	paymentAmountPerPayment = 'Payment amount per payment',
}

const consentDetailsTitle = 'Variable Recurring Payments Consent Details';

const getPageTitle = (missionType: string): string => {
	switch (missionType) {
		case MissionTypeCode.Svrp:
			return `Sweeping ${consentDetailsTitle}`;
		case MissionTypeCode.Cvrp:
			return `Commercial ${consentDetailsTitle}`;
		default:
			return consentDetailsTitle;
	}
};

export default getPageTitle;
