import { Moment } from 'moment';

import { BasePaymentDetails } from '../BasePaymentDetails';
import { AmountLimit } from './Limits/AmountLimit';
import { PaymentLimit } from './Limits/PaymentLimit';

export enum StartingAtType {
	Unknown = -1,
	Consent = 0,
	Calendar = 1,
}

export type PaymentDetails = {
	amount: number;
	currency: string;
	endToEndId: string;
};

export interface VrpProviderDetails extends BasePaymentDetails {
	validFrom: Moment | undefined;
	validTo: Moment | undefined;
	totalAmount: AmountLimit | undefined;
	perPayment: AmountLimit | undefined;
	day: AmountLimit | undefined;
	week: AmountLimit | undefined;
	fortnight: AmountLimit | undefined;
	month: AmountLimit | undefined;
	halfAYear: AmountLimit | undefined;
	year: AmountLimit | undefined;
	queryDebtorAccount: boolean;
	amountLimits?: AmountLimit[] | [];
	paymentContextCode?: string;
	paymentDetails: PaymentDetails;
	paymentDay: PaymentLimit | undefined;
	paymentWeek: PaymentLimit | undefined;
	paymentFortnight: PaymentLimit | undefined;
	paymentMonth: PaymentLimit | undefined;
	paymentHalfAYear: PaymentLimit | undefined;
	paymentYear: PaymentLimit | undefined;
	paymentLimits?: PaymentLimit[] | [];
	IsFirstPaymentApplicable: boolean;
}
