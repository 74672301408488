export enum CreateMissionEnvironmentEnum {
	Prod = '1',
	Test = '2',
	SquadExpansionProd = '3',
	Capybara = '4',
	Mousedeer = '5',
	Platypus = '6',
	Sloth = '7',
	Squirrel = '8',
}

export enum CreateMissionEnvironmentCoreEnum {
	Test = '0',
	SquadExpansionProd = '1',
	Prod = '2',
	Capybara = '3',
	Mousedeer = '4',
	Platypus = '5',
	Sloth = '6',
	Squirrel = '7',
	ProdNe = '8',
	ProdWe = '9',
	Spiir = '10',
	SpiirNe = '11',
	SpiirWe = '12',
}
