import './navbar.css';

import React from 'react';

import IconMenu from './IconMenu';
import OptionsMenu from './OptionsMenu';

const NavMenu = (): JSX.Element => (
	<header>
		<nav className="navbar">
			<a className="logo" href="/" title="Connectivity Lab">
				<img src="./assets/img/logo.png" width="100" />
				<i className="title">Connectivity Lab</i>
			</a>
			<div className="navbar-menu">
				<OptionsMenu mode={'horizontal'} />
				<IconMenu mode={'horizontal'} />
			</div>
		</nav>
	</header>
);

export default NavMenu;
