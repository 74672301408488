import './AdminMissionProvidersTable.css';

import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { ColumnDef } from '@tanstack/react-table';
import { Empty } from 'antd';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';

import GenericTableWithServerSidePagination from '../../../../cl-shared-components/GenericTable/GenericTableWithServerSidePagination';
import { TableParams } from '../../../../cl-shared-components/GenericTable/TableParams';
import { extractSortingColumn } from '../../../../cl-shared-components/GenericTable/TableUtils';
import useAxios from '../../../../hooks/axios';
import { PageTitleContext } from '../../../../layout/Layout';
import { AdminMissionDetails } from '../../../../models/AdminMissions/AdminMissionDetails';
import { AdminMissionDto } from '../../../../models/AdminMissions/AdminMissionDto';
import { AdminMissionTypeDisplay } from '../../../../models/AdminMissions/AdminMissionType';
import { RowType } from '../../../../models/AdminMissions/RowType';
import { PaginationUpdateParams } from '../../../../models/GenericTable/PaginationUpdateParams';
import { AssignedMissions } from '../../../../models/Missions/AssignedMissions';
import MappedMissionStatus from '../../../../models/Missions/MappedMissionStatus';
import { MissionTypeCode } from '../../../../models/Missions/MissionType';
import { dateFormatWithTime, initialTableParams, missionName, pageTitles } from '../../../../resources/config';
import ActionsMenu from './ActionsMenu/ActionsMenu';
import Dropdowns, { IFilters } from './Dropdowns';

export default function AdminMissionProvidersTable() {
	const [adminProviders, setAdminProviders] = useState<AdminMissionDto[]>([]);
	const { fetchData } = useAxios({});
	initialTableParams.sortBy = 'CreatedDate';
	initialTableParams.isSortAscending = false;
	const [tableParams, setTableParams] = React.useState<TableParams>(structuredClone(initialTableParams));
	const [isLoading, setIsLoading] = useState(false);
	const [totalCount, setTotalCount] = useState<number>(0);
	const { setPageTitle } = useContext(PageTitleContext);

	const [tableFilters, setTableFilters] = useState<IFilters>({} as IFilters);

	const isRowType = (obj: RowType): obj is RowType => Array.isArray(obj.missions);

	const columns = React.useMemo<ColumnDef<AdminMissionDto>[]>(
		() => [
			{
				header: 'Provider',
				cell: ({ row, getValue }) => (
					<div className="provider-cell">
						<>
							{row.getCanExpand() && (
								<div className="icon-wrapper" onClick={row.getToggleExpandedHandler()}>
									{row.getIsExpanded() ? (
										<DownOutlined className="expand-icon" />
									) : (
										<RightOutlined className="expand-icon" />
									)}
								</div>
							)}
							{getValue()}
						</>
					</div>
				),
				accessorKey: 'bankName',
				accessorFn: (row) => row.bankName,
				id: 'BankName',
			},

			{
				header: () => <div className="cursor-pointer select-none">Market</div>,
				accessorKey: 'id',
				accessorFn: (row: RowType) => {
					if (isRowType(row)) {
						return row.missions[0].market;
					}
					const rowCopy = row as RowType;
					return rowCopy.id;
				},
				id: 'Market',
			},

			{
				header: () => <div className="cursor-pointer select-none">Mission</div>,
				accessorFn: (row: RowType) => {
					if (isRowType(row)) {
						return AdminMissionTypeDisplay[row.missions[0]?.type as keyof typeof AdminMissionTypeDisplay];
					}
					return '';
				},
				accessorKey: 'name',
				enableSorting: false,
				id: 'Mission',
			},

			{
				header: () => <div className="cursor-pointer select-none">Mission Id</div>,
				accessorKey: 'id',
				accessorFn: (row: RowType) => {
					if (isRowType(row)) {
						return row.missions[0].id;
					}
					const rowCopy = row as RowType;
					return rowCopy.id;
				},
				enableSorting: false,
				id: 'Id',
			},

			{
				header: 'Tester',
				enableSorting: false,
				accessorFn: (row: RowType) => {
					if (isRowType(row)) {
						return row.missions[0].testerName;
					}
					const rowCopy = row as RowType;
					return rowCopy.testerName;
				},
				accessorKey: 'testerName',
				id: 'TesterName',
			},
			{
				header: () => <div className="cursor-pointer select-none">Requested by</div>,
				accessorFn: (row: RowType) => {
					if (isRowType(row)) {
						return row.missions[0].createdBy;
					}
					const rowCopy = row as RowType;
					return rowCopy.createdBy;
				},

				enableSorting: false,
				accessorKey: 'createdBy',
				id: 'CreatedBy',
			},
			{
				header: 'Status',
				accessorKey: 'status',
				accessorFn: (row: RowType) => {
					if (isRowType(row)) {
						return MappedMissionStatus[row.missions[0].status];
					}
					const rowCopy = row as RowType;
					return MappedMissionStatus[rowCopy.status];
				},
				id: 'Status',
			},
			{
				header: 'Date',
				accessorKey: 'createdDate',
				accessorFn: (row: RowType) => {
					if (isRowType(row)) {
						return moment(row.missions[0].createdDate).format(dateFormatWithTime.format);
					}
					const rowCopy = row as RowType;
					return rowCopy.createdDate;
				},
				id: 'CreatedDate',
			},
			{
				header: () => <div className="cursor-pointer select-none">Actions</div>,
				enableSorting: false,
				accessorKey: 'actions',
				accessorFn: (row: RowType) => {
					if (isRowType(row)) {
						return {
							missionStatus: row.missions[0].status,
							missionUserReference: row.missions[0].userReference,
							modifyDate: row.missions[0].modifyDate,
							bankId: row.bankId,
						};
					}
					const rowCopy = row as RowType;
					return rowCopy.actions;
				},
				cell: ({ getValue }) => <>{getValue() ? <ActionsMenu rowInfo={getValue()} /> : <></>}</>,
			},
		],
		[]
	);

	const getAdminProvidersData = async (searchParams: TableParams) => {
		try {
			setIsLoading(true);
			const result = (await fetchData({
				method: 'GET',
				url: 'api/missions/adminmissions',
				headers: {
					accept: '*/*',
				},
				params: searchParams,
			})) as AxiosResponse<AssignedMissions<AdminMissionDto[]>>;

			setAdminProviders(result.data?.missions);
			setTotalCount(result.data?.totalCount);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			console.error(error);
		}
	};

	useEffect(() => {
		setPageTitle(pageTitles.adminMissionResultsPage);
		getAdminProvidersData(initialTableParams).catch((err) => console.error(err));
	}, []);

	const getFilters = (filters: IFilters): IFilters => {
		const cleanFilters = Object.keys(filters).reduce((acc, key) => {
			const accumulator = acc;
			const filterKey = key as keyof IFilters;
			const value = filters[filterKey];
			if (value) {
				if (filterKey === 'missionType') {
					accumulator[filterKey] = value as MissionTypeCode;
				} else {
					accumulator[filterKey] = value;
				}
			}
			return accumulator;
		}, {} as IFilters);
		setTableFilters(cleanFilters);
		return cleanFilters;
	};

	const onFiltersChange = (filters: IFilters): void => {
		setTableParams(initialTableParams);
		setAdminProviders([]);

		try {
			const tableParameters: TableParams = { ...initialTableParams, ...getFilters(filters) };
			getAdminProvidersData(tableParameters).catch((err) => console.error(err));
		} catch (error) {
			console.error(error);
		}
	};

	const onPaginationUpdate = (params: PaginationUpdateParams) => {
		const currentTableParams = { ...tableParams, ...tableFilters };
		if (params.sorting) {
			const { id, desc } = extractSortingColumn(params.sorting);
			currentTableParams.sortBy = id;
			currentTableParams.isSortAscending = !desc;
		}

		if (params.pagination) {
			currentTableParams.page = params.pagination.pageSize >= totalCount ? 0 : params.pagination.pageIndex + 1;
			currentTableParams.pageSize = params.pagination.pageSize;
		}

		setTableParams(currentTableParams);
		getAdminProvidersData(currentTableParams).catch((err) => console.error(err));
	};

	const getTableSubRows = (originalRow: AdminMissionDto) =>
		originalRow?.missions
			?.filter((mission: AdminMissionDetails) => mission.id !== originalRow.missions[0].id)
			.map((mission: AdminMissionDetails) => ({
				bankName: '',
				id: mission.id,
				name: missionName,
				testerName: mission.testerName,
				createdBy: mission.createdBy,
				createdDate: moment(mission.createdDate).format(dateFormatWithTime.format),
				status: mission.status,
				actions: { missionStatus: mission.status, missionUserReference: mission.userReference },
			}));

	return (
		<div>
			<Dropdowns onFiltersChange={onFiltersChange} />

			{adminProviders?.length > 0 ? (
				<GenericTableWithServerSidePagination
					pageCount={Math.ceil(totalCount / tableParams.pageSize)}
					isLoading={isLoading}
					totalCount={totalCount}
					defaultSortBy={[{ id: initialTableParams.sortBy, desc: !initialTableParams.isSortAscending }]}
					onPaginationUpdate={onPaginationUpdate}
					getTableSubRows={getTableSubRows}
					{...{ data: adminProviders, columns }}
				/>
			) : (
				<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
			)}
		</div>
	);
}
