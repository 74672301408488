import '../style.css';

import { Col, Row } from 'antd';
import moment from 'moment';

import { IMissionViewDetailsFields, SipViewDetailsFields } from '../../../../../models/Missions/MissionViewDetails';
import { SingleImmediatePaymentDetails } from '../../../../../models/Payments/SingleImmediatePayment';

const SipViewAdminDetails = ({ data }: { data: SingleImmediatePaymentDetails }): JSX.Element => {
	const { countryCode } = data;
	return (
		<>
			<Row className="cl-mission-admin-details-title">
				<Col xs={24} lg={24}>
					<h6>Single Immediate Payment Details</h6>
				</Col>
			</Row>
			<Row className="cl-mission-admin-details">
				{(Object.keys(SipViewDetailsFields) as Array<keyof IMissionViewDetailsFields>).map((key) => {
					const SipKey = key as keyof SingleImmediatePaymentDetails;
					const value = data[SipKey];
					return (
						value !== undefined &&
						(SipViewDetailsFields[key]?.markets as string[]).includes(countryCode) && (
							<>
								<Col xs={12} lg={12}>
									{SipViewDetailsFields[key].label}
								</Col>
								<Col xs={12} lg={12}>
									{value instanceof moment ? '' : value?.toString() || 'NA'}
								</Col>
							</>
						)
					);
				})}
			</Row>
		</>
	);
};

export default SipViewAdminDetails;
