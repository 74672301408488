import { Alert } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import Spinner from '../../cl-shared-components/Spinner/Spinner';
import useAxios from '../../hooks/axios';
import { PageTitleContext } from '../../layout/Layout';
import { ErrorDetails } from '../../models/HttpResponse/ErrorDetails';
import StatusCodes from '../../models/HttpResponse/StatusCodes';
import { MISSION_STATUS_COMPLETED, MISSION_STATUS_FAILED } from '../../models/Missions/MissionStatus';
import { UpdateMissionStatusPayload } from '../../models/Payments/AccountTransactions';
import { AlertStatusInterface } from '../../models/ui/Alert';
import { pageTitles } from '../../resources/config';
import { VRP_CONSENT_DETAIL_FAIL, VRP_CONSENT_ROUTING_ERRORS } from '../../resources/Errors';
import { AUTHORIZED, PAYMENT_SUCCESS_MESSAGE } from '../../resources/Success';

const CVrpConsentsStageRouting = (): JSX.Element => {
	const { setPageTitle } = useContext(PageTitleContext);
	const { fetchData } = useAxios({});
	const { missionId } = useParams<{ missionId: string }>();
	const { search, hash } = useLocation();
	const [isLoading, setIsLoading] = useState(false);
	const [missionStatus, setMissionStatus] = useState<AlertStatusInterface>({
		type: 'success',
		message: '',
	});

	const getHashParameters = (): URLSearchParams => new URLSearchParams(hash ? hash.replace('#', '') : search);
	const hashParameters = getHashParameters();
	const mandateId = search.split('=')[1];

	const cvrpCallbackHandler = async (): Promise<AxiosResponse | AxiosError> => {
		const stageResponse = (await fetchData({
			method: 'get',
			url: `api/cvrp/consentstatus?missionId=${missionId}&mandateId=${mandateId}`,
			headers: { accept: '*/*' },
		})) as AxiosResponse | AxiosError;

		if (stageResponse.status !== StatusCodes.OK) {
			const stageResponseError = stageResponse as AxiosError<ErrorDetails>;
			if (stageResponseError) {
				const { data } = stageResponseError.response;
				throw Error(data?.detail || stageResponseError.message);
			}
		}
		return stageResponse;
	};

	const updateMissionStatus = async (payload: UpdateMissionStatusPayload): Promise<AxiosResponse | AxiosError> =>
		(await fetchData({
			method: 'post',
			url: 'api/missions/status',
			data: payload,
		})) as AxiosResponse | AxiosError;

	const handleStageRoutingSuccessResponse = async (): Promise<void> => {
		try {
			setIsLoading(true);
			const callbackSuccess = await cvrpCallbackHandler();

			if (callbackSuccess.status === StatusCodes.OK) {
				const consentStatus = JSON.parse(callbackSuccess.request.response);
				const { code } = consentStatus.status;

				if (code === AUTHORIZED) {
					const missionStatusPayload: UpdateMissionStatusPayload = {
						missionId: parseInt(missionId),
						missionStatus: MISSION_STATUS_COMPLETED,
					};
					const { status } = await updateMissionStatus(missionStatusPayload);
					const isMissionRequestOk = status === StatusCodes.OK;

					if (!isMissionRequestOk) {
						throw Error(VRP_CONSENT_DETAIL_FAIL);
					}

					setMissionStatus({ type: 'success', message: PAYMENT_SUCCESS_MESSAGE });
				} else {
					setMissionStatus({ type: 'error', message: VRP_CONSENT_DETAIL_FAIL });
					const missionStatusPayload: UpdateMissionStatusPayload = {
						missionId: parseInt(missionId),
						missionStatus: MISSION_STATUS_FAILED,
					};
					await updateMissionStatus(missionStatusPayload);
					setIsLoading(false);
				}
			}
		} catch (error) {
			const errorObject = error as Error;
			setMissionStatus({ type: 'error', message: errorObject.message || VRP_CONSENT_DETAIL_FAIL });
		} finally {
			setIsLoading(false);
		}
	};

	const handleStageRoutingError = async (errorType: string): Promise<void> => {
		let message = VRP_CONSENT_DETAIL_FAIL;
		if (errorType) {
			message = VRP_CONSENT_ROUTING_ERRORS[errorType];
		}

		setMissionStatus({
			type: 'error',
			message,
		});

		try {
			const missionStatusPayload: UpdateMissionStatusPayload = {
				missionId: parseInt(hashParameters.get('state')),
				missionStatus: MISSION_STATUS_FAILED,
			};
			await updateMissionStatus(missionStatusPayload);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		setPageTitle(pageTitles.accountAuthorization);
		const errorType = hashParameters.get('error');
		const load = async (): Promise<void> => {
			if (errorType) {
				await handleStageRoutingError(errorType);
			} else {
				await handleStageRoutingSuccessResponse();
			}
		};
		void load();
	}, []);

	return (
		<>
			{isLoading && (
				<div className="spinnerWrapper">
					<Spinner />
				</div>
			)}
			{missionStatus.message && <Alert message={missionStatus.message} type={missionStatus.type} />}
		</>
	);
};

export default CVrpConsentsStageRouting;
