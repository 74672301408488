import './ActionsMenu.css';

import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, Space } from 'antd';
import React, { Component } from 'react';

import MissionStatus from '../../../../../models/Missions/MissionStatus';
import { generateSplunkUrl } from '../../../../../utils/SplunkUtils';

interface Props {
	rowInfo: { missionStatus?: string; missionUserReference?: string; bankId?: string; modifyDate?: Date };
}

class ActionsMenu extends Component<Props> {
	safePattern = /[^a-zA-Z0-9-_@:. /]/g;

	sanitize(input: string): string {
		return (input && input.replace(this.safePattern, '')) || '';
	}

	render() {
		const isMissionNotStarted = this.props.rowInfo.missionStatus === MissionStatus.NOT_STARTED;
		const splunkUrl = generateSplunkUrl(
			this.sanitize(this.props.rowInfo.missionUserReference),
			this.sanitize(this.props.rowInfo.bankId),
			(this.props.rowInfo.modifyDate && this.props.rowInfo.modifyDate) || null
		);

		const items: MenuProps['items'] = [
			{
				label: (
					<a href={splunkUrl.href} target="_blank" rel="noreferrer">
						View Splunk Logs
					</a>
				),
				key: '0',
			},
			{
				label: 'View Metabase Logs',
				key: '1',
				disabled: true,
			},
			{
				label: 'View Recording',
				key: '2',
				disabled: true,
			},
			{
				label: 'View Comment from Tester',
				key: '3',
				disabled: true,
			},
		];

		return (
			<Space className="actions-dropdown">
				<Dropdown menu={{ items }} trigger={['click']} disabled={isMissionNotStarted}>
					<a onClick={(e) => e.preventDefault()}>
						<MoreOutlined className="icon-style" />
					</a>
				</Dropdown>
			</Space>
		);
	}
}
export default ActionsMenu;
