import './GenericTable.css';

import {
	ColumnDef,
	getCoreRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	PaginationState,
	RowData,
	SortingState,
	Table,
	Updater,
	useReactTable,
} from '@tanstack/react-table';
import React, { useEffect } from 'react';

import { defaultPaginationState } from '../../resources/config';
import GenericTable from './GenericTable';
import GenericTablePagination from './GenericTablePagination';

export default function GenericTableWithPagination({
	data,
	columns,
	onTableInitialization,
	onRowSelectionChanging,
	defaultSortBy,
}: {
	data: RowData[];
	columns: ColumnDef<RowData>[];
	onTableInitialization?: (table: Table<RowData>) => void;
	onRowSelectionChanging?: (rowSelection: object) => void;
	defaultSortBy?: Updater<SortingState>;
}): JSX.Element {
	const [rowSelection, setRowSelection] = React.useState({});
	const [sorting, setSorting] = React.useState<SortingState>([]);
	const [{ pageIndex, pageSize }] = React.useState<PaginationState>(defaultPaginationState);
	const pagination = React.useMemo(
		() => ({
			pageIndex,
			pageSize,
		}),
		[pageIndex, pageSize]
	);
	const table = useReactTable({
		data,
		columns,
		state: {
			sorting,
			rowSelection,
		},
		initialState: {
			pagination,
		},
		onRowSelectionChange: setRowSelection,
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		sortDescFirst: false,
	});

	useEffect(() => {
		if (onTableInitialization !== undefined) {
			onTableInitialization(table);
		}
	}, [onTableInitialization, table]);

	useEffect(() => {
		if (onRowSelectionChanging !== undefined) {
			onRowSelectionChanging(rowSelection);
		}
	}, [onRowSelectionChanging, rowSelection]);

	return (
		<div className="p-2">
			<GenericTable table={table} defaultSortBy={defaultSortBy} />
			<GenericTablePagination table={table} data={data} />
		</div>
	);
}
