import './GenericTable.css';

import {
	ColumnDef,
	getCoreRowModel,
	getSortedRowModel,
	RowData,
	SortingState,
	Table,
	Updater,
	useReactTable,
} from '@tanstack/react-table';
import React, { useEffect } from 'react';

import GenericTable from './GenericTable';

export default function GenericTableWithoutPagination({
	data,
	columns,
	onTableInitialization,
	onRowSelectionChanging,
	defaultSortBy,
}: {
	data: RowData[];
	columns: ColumnDef<RowData>[];
	onTableInitialization?: (table: Table<RowData>) => void;
	onRowSelectionChanging?: (rowSelection: object) => void;
	defaultSortBy?: Updater<SortingState>;
}): JSX.Element {
	const [rowSelection, setRowSelection] = React.useState({});
	const [sorting, setSorting] = React.useState<SortingState>([]);

	const table = useReactTable({
		data,
		columns,
		state: {
			sorting,
			rowSelection,
		},
		onRowSelectionChange: setRowSelection,
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		sortDescFirst: false,
	});

	useEffect(() => {
		if (onTableInitialization !== undefined) {
			onTableInitialization(table);
		}
	}, [onTableInitialization, table]);

	useEffect(() => {
		if (onRowSelectionChanging !== undefined) {
			onRowSelectionChanging(rowSelection);
		}
	}, [onRowSelectionChanging, rowSelection]);

	return <GenericTable table={table} defaultSortBy={defaultSortBy} />;
}
