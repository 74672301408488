interface IPaymentRailValues {
	[key: string]: string[];
}

export const PaymentRailValues: IPaymentRailValues = {
	DE: ['GermanInstantSepaCapability', 'GermanSepaCapability', 'GermanSepaCreditTransfer'],
	NO: ['NorwegianDomesticCreditTransfer'],
	DK: ['DanishDomesticCreditTransfer'],
	SE: ['SwedishDomesticCreditTransferLegacy'],
	AT: ['AustrianSepaCreditTransfer'],
	GB: ['UkFasterPayments'],
};
