import './VrpPaymentDetails.css';

import { Link } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { Col, Empty, Row } from 'antd';
import React from 'react';

import GenericTableWithoutPagination from '../../../../../cl-shared-components/GenericTable/GenericTableWithoutPagination';
import MappedMissionStatus from '../../../../../models/Missions/MappedMissionStatus';
import { VrpPaymentDetailsType } from '../../../../../models/Missions/VrpPaymentDetailsType';
import { generateSplunkUrl } from '../../../../../utils/SplunkUtils';

interface VrpPaymentDetailsProps {
	data: VrpPaymentDetailsType[];
}

const renderCell = ({ getValue }: { getValue: () => VrpPaymentDetailsType }): React.ReactNode => (
	<div className="cell-content-table">
		<>{getValue()}</>
	</div>
);

const searchLogs = (userReference: string): void => {
	const splunkUrl = generateSplunkUrl(userReference);
	window.open(splunkUrl.toString(), '_blank', 'noreferrer');
};

const renderSplunkLogsCell = ({ row: { original } }: { row: { original: VrpPaymentDetailsType } }): JSX.Element => (
	<Link className="logs-content" onClick={(): void => searchLogs(original.userReference)}>
		<>Logs</>
	</Link>
);

const VrpPaymentDetails = (props: VrpPaymentDetailsProps): JSX.Element => {
	const columns = React.useMemo<ColumnDef<VrpPaymentDetailsType>[]>(
		() => [
			{
				header: 'Date',
				id: 'createdDate',
				size: 120,
				enableSorting: false,
				accessorFn: (row: VrpPaymentDetailsType): string => row.createdDate,
				cell: renderCell,
			},
			{
				header: 'Amount',
				id: 'amount',
				size: 120,
				enableSorting: false,
				accessorFn: (row: VrpPaymentDetailsType): string => `${row.amount} ${row.currency}`,
				cell: renderCell,
			},
			{
				header: 'Status',
				id: 'status',
				size: 240,
				enableSorting: false,
				accessorFn: (row: VrpPaymentDetailsType): string => MappedMissionStatus[row.status],
				cell: renderCell,
			},
			{
				header: 'Splunk logs',
				id: 'logs',
				size: 130,
				enableSorting: false,
				accessorFn: (row: VrpPaymentDetailsType): string => row.userReference,
				cell: renderSplunkLogsCell,
			},
		],
		[]
	);

	return (
		<Row className="payment-details-container">
			<Col xs={24} lg={24}>
				<h6>Payment Details</h6>
			</Col>
			{props.data.length > 0 ? (
				<div className="payment-details-table">
					<GenericTableWithoutPagination {...{ data: props.data, columns }} />
				</div>
			) : (
				<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
			)}
		</Row>
	);
};

export default VrpPaymentDetails;
