import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Tester } from '../../models/Tester/Tester';

export interface TesterState {
	currentTester?: Tester;
	auth0UserIds?: string[];
}

const initialState: TesterState = {
	currentTester: null,
	auth0UserIds: null,
};
export const testerSlice = createSlice({
	name: 'tester',
	initialState,
	reducers: {
		setTesterPersonalData: (state: TesterState, { payload }: PayloadAction<TesterState>) => {
			state.currentTester = payload.currentTester;
		},
		setAuth0UserIds: (state: TesterState, { payload }: PayloadAction<TesterState>) => {
			state.auth0UserIds = payload.auth0UserIds;
		},
	},
});
export const { setTesterPersonalData, setAuth0UserIds } = testerSlice.actions;
