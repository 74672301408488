import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Row } from 'antd';
import { Controller, useForm } from 'react-hook-form';

import { CLTextInput } from '../../../../../cl-shared-components/Inputs';
import { IMissionForm } from '../../../../../models/Missions/MissionForm';
import { VrpInitiatePaymentDetails } from '../../../../../models/Payments/Vrp/VrpInitiatePayment';
import { initiatePaymentSchemaValidation } from '../../../YupSchemaValidation/VrpSchemaValidation';

const VrpInitiatePaymentForm = (props: IMissionForm<VrpInitiatePaymentDetails>): JSX.Element => {
	const { consentPaymentDetails } = props.consentDetails;

	const {
		control,
		handleSubmit,
		getValues,
		reset,
		formState: { errors, dirtyFields },
	} = useForm({
		defaultValues: props.data,
		values: props.data,
		mode: 'onChange',
		resolver: yupResolver(initiatePaymentSchemaValidation),
	});

	return (
		<div className="cl-mission-modal-container">
			<form
				className="vrp-mission-form"
				ref={(ref: HTMLFormElement): void => {
					props.setRef(ref);
				}}
				onSubmit={handleSubmit(() => {
					props.onSubmit(getValues(), Object.keys(dirtyFields).length > 0);
					reset();
				})}
			>
				<Row gutter={16}>
					<Col xs={24} sm={24} lg={12}>
						<Controller
							name="amount"
							defaultValue={consentPaymentDetails?.amount}
							control={control}
							render={({ field }): JSX.Element => (
								<CLTextInput
									{...field}
									label={'Amount'}
									required
									errors={errors}
									hasNestedObjectForValidate
								/>
							)}
						/>
					</Col>

					<Col xs={24} sm={24} lg={12}>
						<Controller
							name="currency"
							defaultValue={consentPaymentDetails?.currency}
							control={control}
							render={({ field }): JSX.Element => (
								<CLTextInput
									{...field}
									label={'Currency'}
									required
									errors={errors}
									hasNestedObjectForValidate
									disabled
								/>
							)}
						/>
					</Col>
				</Row>
			</form>
		</div>
	);
};

export default VrpInitiatePaymentForm;
