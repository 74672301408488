import { SortingState } from '@tanstack/react-table';

import { PaginationUpdateParams } from '../../models/GenericTable/PaginationUpdateParams';
import { initialTableParams } from '../../resources/config';
import { TableParams } from './TableParams';

const extractSearchParams = (tableParams: TableParams) =>
	Object.keys(tableParams)
		.map((key: string) => {
			const copy = tableParams;
			return `${key}=${copy[key]}`;
		})
		.join('&');
export default extractSearchParams;

export const extractSortingColumn = (sortingElements: SortingState) => {
	if (sortingElements.length > 0) {
		return sortingElements[0];
	}
	return { id: initialTableParams.sortBy, desc: true };
};

export const onPaginationUpdate = ({
	tableParams,
	stateTableParams,
	totalCount,
}: {
	tableParams: PaginationUpdateParams;
	stateTableParams: TableParams;
	totalCount: number;
}): TableParams => {
	const currentTableParams: TableParams = structuredClone(stateTableParams);

	if (tableParams.sorting) {
		const { id, desc } = extractSortingColumn(tableParams.sorting);
		currentTableParams.sortBy = id;
		currentTableParams.isSortAscending = !desc;
	}

	if (tableParams.pagination) {
		currentTableParams.page =
			tableParams.pagination.pageSize >= totalCount ? 0 : tableParams.pagination.pageIndex + 1;
		currentTableParams.pageSize = tableParams.pagination.pageSize;
	}

	return currentTableParams;
};
