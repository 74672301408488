import { flexRender, Header, HeaderGroup, Row, RowData, SortingState, Table, Updater } from '@tanstack/react-table';
import { useEffect } from 'react';

import GenericTableSort, { NO_SORT, SORT_ASC, SORT_DESC } from './GenericTableSort';

export default function GenericTable({
	table,
	defaultSortBy,
}: {
	table: Table<RowData>;
	defaultSortBy?: Updater<SortingState>;
}): JSX.Element {
	useEffect(() => {
		table.setSorting(defaultSortBy);
	}, []);
	return (
		<div>
			<div className="h-2" />
			<table className="table table-bordered table-sm" cellSpacing="0" width="100%">
				<thead>
					{table.getHeaderGroups().map((headerGroup: HeaderGroup<RowData>) => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map((header: Header<RowData, RowData>) => (
								<th style={{ width: header.column.getSize() }} key={header.id} colSpan={header.colSpan}>
									{header.isPlaceholder ? null : (
										<div
											{...{
												className: header.column.getCanSort()
													? 'table-header-container cursor-pointer select-none'
													: 'table-header-container',
												onClick: header.column.getToggleSortingHandler(),
											}}
										>
											<div className="cl-generic-table-header-text">
												{flexRender(header.column.columnDef.header, header.getContext())}
											</div>
											<div className="cl-generic-table-sort-icons">
												{header.column.getCanSort() &&
													({
														asc: <GenericTableSort sortDirection={SORT_ASC} />,
														desc: <GenericTableSort sortDirection={SORT_DESC} />,
													}[header.column.getIsSorted() as string] ?? (
														<GenericTableSort sortDirection={NO_SORT} />
													))}
											</div>
										</div>
									)}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody>
					{table.getRowModel().rows.map((row: Row<RowData>) => (
						<tr key={row.id}>
							{row.getVisibleCells().map((cell) => (
								<td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}
