import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import StatusCodes from '../models/HttpResponse/StatusCodes';
import config from '../resources/config';
import { RootState } from '../store';
import { setAntiForgeryToken, setJwtToken } from '../store/reducers/authReducer';

const { baseUrl } = config;
axios.defaults.baseURL = baseUrl;

const useAxios = (axiosParams: AxiosRequestConfig) => {
	const [response, setResponse] = useState<AxiosResponse>();
	const history = useHistory();
	const [error, setError] = useState<AxiosError>();
	const [loading, setLoading] = useState(axiosParams.method === 'GET' || axiosParams.method === 'get');
	const { getAccessTokenSilently, loginWithRedirect, isAuthenticated } = useAuth0();
	const dispatch = useDispatch();
	const jwt = useSelector((state: RootState) => state.auth.jwt);
	const antiForgeryToken = useSelector((state: RootState) => state.auth.antiForgeryToken);

	const getJwt = async () => {
		if (jwt !== null) {
			return jwt;
		}
		let token = null;
		try {
			token = await getAccessTokenSilently();
			dispatch(setJwtToken(token));
			return token;
		} catch (e) {
			if (e instanceof Error) {
				if (e.message === 'login_required' || e.message === 'consent_required') {
					await loginWithRedirect({
						scope: config.scope,
						audience: config.audience,
					});
				}
			}

			throw e;
		}
	};

	const getAntiForgeryToken = async (jwtToken: string) => {
		if (antiForgeryToken) {
			return antiForgeryToken;
		}

		try {
			const result = await axios.get('/api/antiforgery/token', {
				headers: {
					Accept: '*/*',
					Authorization: `Bearer ${jwtToken}`,
				},
				withCredentials: true,
			});

			const token = result?.data;
			if (token) {
				dispatch(setAntiForgeryToken(token));
			}

			return token;
		} catch (ex) {
			console.error('Error fetching anti-forgery token:', ex);
			throw ex;
		}
	};

	const resetPreviousStates = () => {
		setResponse(undefined);
		setError(undefined);
	};

	const fetchData = async (params: AxiosRequestConfig) => {
		const token = await getJwt();
		const forgeryToken = await getAntiForgeryToken(token);
		axios.defaults.headers.common.Authorization = `Bearer ${token}`;
		axios.defaults.headers.common['X-XSRF-TOKEN'] = forgeryToken;
		axios.defaults.withCredentials = true;
		resetPreviousStates();

		try {
			const result = await axios.request(params);
			setResponse(result);
			return result;
		} catch (err) {
			setError(err as AxiosError);

			const statusCodesForRedirect: number[] = [StatusCodes.FORBIDDEN, StatusCodes.UNAUTHORIZED];
			if (err instanceof AxiosError && statusCodesForRedirect.includes(err.response.status)) {
				history.push('/unauthorized');
			}
			return err;
		} finally {
			setLoading(false);
		}
	};

	const sendData = () => {
		fetchData(axiosParams).catch((err) => console.error(err));
	};

	useEffect(() => {
		if (isAuthenticated === false) {
			return;
		}
		if (axiosParams.method === 'GET' || axiosParams.method === 'get') {
			fetchData(axiosParams).catch((err) => console.error(err));
		}
	}, [isAuthenticated]);

	return { response, error, loading, sendData, fetchData };
};

export default useAxios;
