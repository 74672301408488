import { ILicense } from '../../models/License/License';
import { Uk } from '../../models/Missions/Area';
import { AiiaLicense } from './AiiaLicense';
import { TokenLicense } from './TokenLicense';

export const License = (props: ILicense): JSX.Element =>
	props.area === Uk.value ? <TokenLicense {...props} /> : <AiiaLicense {...props} />;

License.defaultProps = {
	buttonPosition: 'above',
	displayName: 'provider',
	missionType: null,
};
