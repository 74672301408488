import { PlusCircleOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import moment from 'moment';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { Col, Row } from 'reactstrap';

import { CLCheckbox } from '../../../cl-shared-components/Checkbox';
import { CLDatePicker, CLTextInput } from '../../../cl-shared-components/Inputs';
import CLSelectInput from '../../../cl-shared-components/Inputs/CLSelectInput';
import { Country } from '../../../models/Countries/Country';
import { IMissionForm } from '../../../models/Missions/MissionForm';
import {
	BOOLEAN_STRING_TYPE,
	BOOLEAN_VALUES,
	CREDITOR_ACCOUNT_TYPE,
	PAYMENT_PURPOSE_CODE,
} from '../../../models/Payments/TransactionRiskIndicators';
import { UKFasterPaymentDetails } from '../../../models/Payments/UkFasterPayment';
import { DATE_FORMAT, DATE_PLACEHOLDER } from '../../../resources/config';
import { isCountryCodeRequired, isTownNameRequired } from '../../../utils/MissionUtils';
import { UkFasterPaymentSchemaValidation } from '../YupSchemaValidation/UkFasterPaymentSchemaValidation';

interface UkFasterPaymentMissionFormProps extends IMissionForm<UKFasterPaymentDetails> {
	countries: {
		selectedCountry: string;
		sourceCountries: Country[];
	};
}

const UkFasterPaymentMissionForm = (props: UkFasterPaymentMissionFormProps): JSX.Element => {
	const {
		control,
		handleSubmit,
		setValue,
		getValues,
		trigger,
		formState: { errors, dirtyFields },
	} = useForm({
		defaultValues: props.data,
		values: props.data,
		mode: 'onChange',
		resolver: yupResolver(UkFasterPaymentSchemaValidation),
	});

	const generateEndToEndID = (): void => {
		const uid = crypto.randomUUID().substring(0, 35);
		setValue('endToEndID', uid, { shouldDirty: true });
		void trigger('endToEndID');
	};

	const handleInputBlur = <FieldName extends keyof UKFasterPaymentDetails>(
		field: ControllerRenderProps<UKFasterPaymentDetails, FieldName>
	): void => {
		if (field.value === '') {
			field.onChange(null);
		}
	};

	return (
		<div className="cl-mission-modal-container">
			<form
				ref={(ref: HTMLFormElement): void => {
					props.setRef(ref);
				}}
				onSubmit={handleSubmit(() => {
					props.onSubmit(getValues(), Object.keys(dirtyFields).length > 0);
				})}
			>
				<Row>
					<Col lg={6}>
						<Controller
							name="amount"
							control={control}
							render={({ field }): JSX.Element => (
								<CLTextInput {...field} label={'Amount'} errors={errors} required type="number" />
							)}
						/>
					</Col>
					<Col lg={6}>
						<Controller
							name="currency"
							control={control}
							render={({ field }): JSX.Element => (
								<CLTextInput {...field} label={'Currency'} errors={errors} required />
							)}
						/>
					</Col>
				</Row>
				<Row>
					<Col lg={6}>
						<Controller
							name="schedulePayment"
							control={control}
							render={({ field }): JSX.Element => (
								<CLCheckbox
									className="cl-ukfp-schedule-payment"
									{...field}
									label="Schedule payment"
									checked={getValues('schedulePayment')}
									onChange={(event: CheckboxChangeEvent): void => {
										setValue('executionDate', event.target.checked ? moment() : null, {
											shouldDirty: true,
										});
										setValue('schedulePayment', event.target.checked, { shouldDirty: true });
										void trigger('executionDate');
									}}
								/>
							)}
						/>
					</Col>
					{getValues('schedulePayment') && (
						<Col lg={6}>
							<Controller
								name="executionDate"
								control={control}
								render={({ field }): JSX.Element => (
									<CLDatePicker
										{...field}
										label={'Execution Date'}
										picker="date"
										format={DATE_FORMAT}
										placeholder={DATE_PLACEHOLDER}
										errors={errors.executionDate}
										required
									/>
								)}
							/>
						</Col>
					)}
				</Row>
				<Row>
					<Col lg={12}>
						<Controller
							name="creditorName"
							control={control}
							render={({ field }): JSX.Element => (
								<CLTextInput {...field} label={'Destination Name'} errors={errors} required />
							)}
						/>
					</Col>
				</Row>
				<Row>
					<Col lg={6}>
						<Controller
							name="townName"
							control={control}
							render={({ field }): JSX.Element => {
								const bankId = getValues('bankId');
								const isRequired = isTownNameRequired(bankId);
								return (
									<CLTextInput
										{...field}
										label={'Town'}
										errors={errors}
										{...(isRequired && { required: true })}
									/>
								);
							}}
						/>
					</Col>
					<Col lg={6}>
						<Controller
							name="countryCode"
							control={control}
							render={({ field }): JSX.Element => {
								const bankId = getValues('bankId');
								const isRequired = isCountryCodeRequired(bankId);
								return (
									<CLSelectInput
										{...field}
										label={'Country'}
										optionFilterProp="label"
										errors={errors}
										options={props.countries.sourceCountries.map((country) => ({
											value: country.countryCode,
											label: country.name,
										}))}
										{...(isRequired ? { required: true } : { allowEmptyValue: true })}
									/>
								);
							}}
						/>
					</Col>
				</Row>
				<Row>
					<Col lg={6}>
						<Controller
							name="creditorSortCode"
							control={control}
							render={({ field }): JSX.Element => (
								<CLTextInput
									{...field}
									label={'Sort Code'}
									errors={errors}
									onBlur={() => handleInputBlur<'creditorSortCode'>(field)}
								/>
							)}
						/>
					</Col>
					<Col lg={6}>
						<Controller
							name="creditorAccountNumber"
							control={control}
							render={({ field }): JSX.Element => (
								<CLTextInput
									{...field}
									label={'Account Number'}
									errors={errors}
									onBlur={() => handleInputBlur<'creditorAccountNumber'>(field)}
								/>
							)}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Controller
							name="referenceInformation"
							control={control}
							render={({ field }): JSX.Element => (
								<CLTextInput {...field} label={'Message Text'} errors={errors} required />
							)}
						/>
					</Col>
				</Row>
				<Row>
					<Col lg={12}>
						<div className="cl-payment-end-to-end-wrapper">
							<Controller
								name="endToEndID"
								control={control}
								render={({ field }): JSX.Element => (
									<CLTextInput {...field} label={'End-to-End Id'} errors={errors} required />
								)}
							/>
							<PlusCircleOutlined className="cl-payment-end-to-end-icon" onClick={generateEndToEndID} />
						</div>
					</Col>
				</Row>
				<Row className="cl-payment-transaction-risk-indicators">
					<Col lg={6}>
						<Controller
							name="creditorAccountType"
							control={control}
							render={({ field }): JSX.Element => (
								<CLSelectInput
									{...field}
									label="Destination Account Type"
									optionFilterProp="label"
									options={Object.keys(CREDITOR_ACCOUNT_TYPE).map((key, index) => ({
										value: key === 'null' ? null : index,
										label: key === 'null' ? ' ' : key,
									}))}
									allowEmptyValue
								/>
							)}
						/>
					</Col>
					<Col lg={6}>
						<Controller
							name="isCreditorPrePopulated"
							control={control}
							render={({ field }): JSX.Element => (
								<CLSelectInput
									{...field}
									label="Is destination prepopulated"
									optionFilterProp="label"
									options={Object.keys(BOOLEAN_VALUES).map((key) => ({
										value: key,
										label: BOOLEAN_VALUES[key as BOOLEAN_STRING_TYPE],
									}))}
									allowEmptyValue
								/>
							)}
						/>
					</Col>
					<Col lg={6}>
						<Controller
							name="paymentPurposeCode"
							control={control}
							render={({ field }): JSX.Element => (
								<CLSelectInput
									{...field}
									label="Purpose Code"
									optionFilterProp="label"
									options={Object.keys(PAYMENT_PURPOSE_CODE).map((key, index) => ({
										value: key === 'null' ? null : index,
										label: key === 'null' ? ' ' : key,
										title: PAYMENT_PURPOSE_CODE[key as keyof typeof PAYMENT_PURPOSE_CODE],
									}))}
									allowEmptyValue
								/>
							)}
						/>
					</Col>
					<Col lg={6}>
						<Controller
							name="isContractPresent"
							control={control}
							render={({ field }): JSX.Element => (
								<CLSelectInput
									{...field}
									optionFilterProp="label"
									label="Is contract present"
									options={Object.keys(BOOLEAN_VALUES).map((key) => ({
										value: key,
										label: BOOLEAN_VALUES[key as BOOLEAN_STRING_TYPE],
									}))}
									allowEmptyValue
								/>
							)}
						/>
					</Col>
				</Row>
			</form>
		</div>
	);
};

export default UkFasterPaymentMissionForm;
