import './VrpMissionForm.css';

import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Form, Row, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useState } from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';

import { CLCheckbox } from '../../../cl-shared-components/Checkbox';
import { CLDatePicker, CLTextInput } from '../../../cl-shared-components/Inputs';
import CLSelectInput from '../../../cl-shared-components/Inputs/CLSelectInput';
import { IMissionForm } from '../../../models/Missions/MissionForm';
import {
	BOOLEAN_STRING_TYPE,
	BOOLEAN_VALUES,
	CREDITOR_ACCOUNT_TYPE,
	PAYMENT_CONTEXT_CODE,
	PAYMENT_PURPOSE_CODE,
} from '../../../models/Payments/TransactionRiskIndicators';
import {
	AmountLimit,
	AmountLimitTypeName,
	getAmountLimitTypeNames,
} from '../../../models/Payments/Vrp/Limits/AmountLimit';
import {
	getPaymentLimitTypeNames,
	PaymentLimit,
	PaymentLimitTypeName,
} from '../../../models/Payments/Vrp/Limits/PaymentLimit';
import { VrpProviderDetails } from '../../../models/Payments/Vrp/VrpProviderDetails';
import {
	DATE_FORMAT,
	DATE_PLACEHOLDER,
	DEFAULT_AMOUNT_LIMIT_LENGTH,
	NATWEST_AMOUNT_LIMIT_LENGTH,
} from '../../../resources/config';
import {
	CVRP_NATWEST_LIMIT_AMOUNT_COUNT_ERROR_MESSAGE,
	VRP_LIMIT_AMOUNT_COUNT_ERROR_MESSAGE,
} from '../../../resources/Errors';
import { extractLimitDataSources, getCheckedAmountLimits, getCheckedPaymentLimits } from '../../../utils/MissionUtils';
import { getSchemaValidation } from '../YupSchemaValidation/VrpSchemaValidation';

const VrpMissionForm = (props: IMissionForm<VrpProviderDetails>): JSX.Element => {
	const { data, setRef, onSubmit } = props;
	const [errorMessages, setErrorMessages] = useState<string[]>([]);
	const { Option } = Select;
	const isDefaultVrpForm = data.totalAmount.settings.hide ?? false;
	const { amountLimitsSource, paymentLimitsSource } = extractLimitDataSources(data);
	const schemaValidation = getSchemaValidation(isDefaultVrpForm);

	const {
		control,
		handleSubmit,
		setValue,
		watch,
		getValues,
		reset,
		formState: { errors, isDirty },
	} = useForm({
		defaultValues: data,
		values: data,
		mode: 'onChange',
		resolver: yupResolver(schemaValidation),
	});

	const onStartingAtChange = (name: AmountLimitTypeName | PaymentLimitTypeName, selectedIdx: number): void => {
		try {
			setValue(`${name}.startingType`, selectedIdx, { shouldValidate: true });
		} catch (error) {
			console.error(error);
		}
	};

	const getAmountLimitSubmitValidation = () => {
		if (isDefaultVrpForm) {
			return { totalLength: DEFAULT_AMOUNT_LIMIT_LENGTH, errorMessage: VRP_LIMIT_AMOUNT_COUNT_ERROR_MESSAGE };
		}

		return {
			totalLength: NATWEST_AMOUNT_LIMIT_LENGTH,
			errorMessage: CVRP_NATWEST_LIMIT_AMOUNT_COUNT_ERROR_MESSAGE,
		};
	};

	const isValidSubmitForm = (amountLimits: AmountLimit[]): boolean => {
		const submitErrMessage: string[] = [];
		const perPaymentChecked = getValues('perPayment').isChecked;

		const { totalLength, errorMessage } = getAmountLimitSubmitValidation();

		if (!perPaymentChecked || amountLimits.length < totalLength) {
			submitErrMessage.push(errorMessage);
		}

		setErrorMessages(submitErrMessage);

		return submitErrMessage.length === 0;
	};

	const handleInputBlur = <FieldName extends keyof VrpProviderDetails>(
		field: ControllerRenderProps<VrpProviderDetails, FieldName>
	): void => {
		if (field.value === '') {
			field.onChange(null);
		}
	};
	const getSelectedPaymentLimits = (): PaymentLimit[] => {
		const paymentLimitNames = getPaymentLimitTypeNames();
		return isDefaultVrpForm ? [] : getCheckedPaymentLimits(getValues(paymentLimitNames));
	};

	const handleVrpSubmit = (): void => {
		const amountLimitNames = getAmountLimitTypeNames();
		const amountLimits = getCheckedAmountLimits(getValues(amountLimitNames));

		if (isValidSubmitForm(amountLimits)) {
			const formValues = getValues();
			const paymentLimits = getSelectedPaymentLimits();

			const vrpFormData: VrpProviderDetails = {
				...formValues,
				referenceInformation: formValues.referenceInformation.trim(),
				amountLimits,
				paymentDetails: {
					...formValues.paymentDetails,
					amount: +formValues.paymentDetails.amount,
				},
				paymentLimits,
			};

			reset();
			onSubmit(vrpFormData, isDirty);
		}
	};

	return (
		<div className="cl-mission-modal-container">
			<form
				className="vrp-mission-form"
				ref={(ref: HTMLFormElement): void => {
					if (setRef) {
						setRef(ref);
					}
				}}
				onSubmit={handleSubmit(() => handleVrpSubmit())}
			>
				<Row gutter={16}>
					<Col xs={24} sm={24} lg={12}>
						<Controller
							name="validFrom"
							control={control}
							render={({ field }): JSX.Element => (
								<CLDatePicker
									{...field}
									label={'Valid From'}
									picker="date"
									format={DATE_FORMAT}
									placeholder={DATE_PLACEHOLDER}
									errors={errors.validFrom}
									required
								/>
							)}
						/>
					</Col>

					<Col xs={24} sm={24} lg={12}>
						<Controller
							name="validTo"
							control={control}
							render={({ field }): JSX.Element => (
								<CLDatePicker
									{...field}
									label={'Valid To'}
									picker="date"
									format={DATE_FORMAT}
									placeholder={DATE_PLACEHOLDER}
									errors={errors.validTo}
									required
								/>
							)}
						/>
					</Col>
				</Row>

				<Row className="amount-limits-header">
					<h4>
						Amount Limits <span className="cl-input-required">*</span>
					</h4>
				</Row>

				{amountLimitsSource.map((limit) => {
					const { name, label, isPeriodic, hide, colSize, startingAtTypesSource } = limit.settings;

					return (
						!hide && (
							<Row key={name}>
								<Row>
									<Col>
										<Controller
											name={`${name}.isChecked`}
											control={control}
											render={({ field }): JSX.Element => (
												<CLCheckbox
													{...field}
													label={label.checkboxLabel}
													checked={getValues(`${name}.isChecked`)}
													onChange={(event: CheckboxChangeEvent): void => {
														setValue(`${name}.isChecked`, event.target.checked, {
															shouldDirty: true,
														});
													}}
												/>
											)}
										/>
									</Col>
								</Row>

								{watch(`${name}.isChecked`) && (
									<Row gutter={16}>
										<Col xs={24} sm={24} lg={colSize?.lg ?? 6}>
											<Controller
												name={`${name}.amount`}
												control={control}
												render={({ field }): JSX.Element => (
													<CLTextInput
														{...field}
														className="cl-ukfp-schedule-payment"
														label={label.amountLabel ?? 'Amount'}
														required
														errors={errors}
														hasNestedObjectForValidate
													/>
												)}
											/>
										</Col>

										<Col xs={24} sm={24} lg={colSize?.lg ?? 6}>
											<Controller
												name={`${name}.currency`}
												control={control}
												render={({ field }): JSX.Element => (
													<CLTextInput
														{...field}
														label={'Currency'}
														required
														errors={errors}
														hasNestedObjectForValidate
													/>
												)}
											/>
										</Col>

										{isPeriodic && (
											<Col xs={24} sm={24} lg={12} className="selector-label">
												<Form.Item
													label="Starting at"
													name={`${name}.startingType`}
													labelCol={{ span: 24 }}
													wrapperCol={{ span: 24 }}
													required
												>
													<Select
														showSearch
														optionFilterProp="children"
														status={`${errors[name] ? 'error' : ''}`}
														onChange={(selectedOption: number) =>
															onStartingAtChange(name, selectedOption)
														}
														defaultValue={
															limit.startingType >= 0 ? limit.startingType : null
														}
													>
														{startingAtTypesSource?.map((startingAtType) => (
															<Option
																value={startingAtType.id}
																key={`${name}.${startingAtType.name}`}
															>
																{startingAtType.name}
															</Option>
														))}
													</Select>
													<ErrorMessage
														errors={errors}
														name={`${name}.startingType`}
														render={({ message }) => (
															<p className="cl-text-input-errors">{message}</p>
														)}
													/>
												</Form.Item>
											</Col>
										)}
									</Row>
								)}
							</Row>
						)
					);
				})}

				{!isDefaultVrpForm && (
					<Row className="payment-limits-header">
						<h4>Payment Limits</h4>
					</Row>
				)}

				{paymentLimitsSource.map((limit) => {
					const { name, label, hide, colSize, startingAtTypesSource } = limit.settings;

					return (
						!hide && (
							<Row key={name} className="payment-limits-items">
								<Row>
									<Col>
										<Controller
											name={`${name}.isChecked`}
											control={control}
											render={({ field }): JSX.Element => (
												<CLCheckbox
													{...field}
													label={label}
													checked={getValues(`${name}.isChecked`)}
													onChange={(event: CheckboxChangeEvent): void => {
														setValue(`${name}.isChecked`, event.target.checked, {
															shouldDirty: true,
														});
													}}
												/>
											)}
										/>
									</Col>
								</Row>

								{watch(`${name}.isChecked`) && (
									<Row gutter={16}>
										<Col xs={24} sm={24} lg={colSize?.lg ?? 6}>
											<Controller
												name={`${name}.numberOfPayments`}
												control={control}
												render={({ field }): JSX.Element => (
													<CLTextInput
														{...field}
														className="cl-ukfp-schedule-payment"
														label="No. of payments"
														required
														errors={errors}
														hasNestedObjectForValidate
													/>
												)}
											/>
										</Col>

										<Col xs={24} sm={24} lg={12} className="selector-label">
											<Form.Item
												label="Starting at"
												name={`${name}.startingType`}
												labelCol={{ span: 24 }}
												wrapperCol={{ span: 24 }}
												required
											>
												<Select
													showSearch
													optionFilterProp="children"
													status={`${errors[name] ? 'error' : ''}`}
													onChange={(selectedOption: number) =>
														onStartingAtChange(name, selectedOption)
													}
													defaultValue={limit.startingType >= 0 ? limit.startingType : null}
												>
													{startingAtTypesSource?.map((startingAtType) => (
														<Option
															value={startingAtType.id}
															key={`${name}.${startingAtType.name}`}
														>
															{startingAtType.name}
														</Option>
													))}
												</Select>
												<ErrorMessage
													errors={errors}
													name={`${name}.startingType`}
													render={({ message }) => (
														<p className="cl-text-input-errors">{message}</p>
													)}
												/>
											</Form.Item>
										</Col>
									</Row>
								)}
							</Row>
						)
					);
				})}

				<Row className={'creditor-details'}>
					<Col xs={24} sm={24} lg={24}>
						<Controller
							name="creditorName"
							control={control}
							render={({ field }): JSX.Element => (
								<CLTextInput {...field} label={'Destination Name'} errors={errors} required />
							)}
						/>
					</Col>
				</Row>

				<Row gutter={16}>
					<Col xs={24} sm={24} lg={6}>
						<Controller
							name="creditorSortCode"
							control={control}
							render={({ field }): JSX.Element => (
								<CLTextInput
									{...field}
									label={'Sort Code'}
									errors={errors}
									onBlur={() => handleInputBlur<'creditorSortCode'>(field)}
									required
								/>
							)}
						/>
					</Col>
					<Col xs={24} sm={24} lg={18}>
						<Controller
							name="creditorAccountNumber"
							control={control}
							render={({ field }): JSX.Element => (
								<CLTextInput
									{...field}
									label={'Account Number'}
									errors={errors}
									onBlur={() => handleInputBlur<'creditorAccountNumber'>(field)}
									required
								/>
							)}
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={24} sm={24} lg={24}>
						<Controller
							name="referenceInformation"
							control={control}
							render={({ field }): JSX.Element => (
								<CLTextInput
									{...field}
									label={'Message Text'}
									placeholder="Enter the Message Text"
									errors={errors}
								/>
							)}
						/>
					</Col>
					<Col lg={12}>
						<Controller
							name="queryDebtorAccount"
							control={control}
							render={({ field }): JSX.Element => (
								<CLCheckbox
									className="cl-checkbox-field"
									{...field}
									label="Query debtor account"
									checked={getValues('queryDebtorAccount')}
									onChange={(event: CheckboxChangeEvent): void => {
										setValue('queryDebtorAccount', event.target.checked);
									}}
								/>
							)}
						/>
					</Col>
				</Row>

				<Row className="vrp-payment-transaction-risk-indicators">
					<h4>UK Open Banking Risk</h4>

					<Col xs={24}>
						<Controller
							name="paymentContextCode"
							control={control}
							render={({ field }): JSX.Element => (
								<CLSelectInput
									{...field}
									label="Payment Context"
									allowEmptyValue
									optionFilterProp="label"
									options={Object.keys(PAYMENT_CONTEXT_CODE).map((key, index) => ({
										value: key === 'null' ? null : index,
										label: key === 'null' ? ' ' : key,
									}))}
								/>
							)}
						/>
					</Col>
					<Col xs={24}>
						<Controller
							name="creditorAccountType"
							control={control}
							render={({ field }): JSX.Element => (
								<CLSelectInput
									{...field}
									label="Creditor Account Type"
									allowEmptyValue
									optionFilterProp="label"
									options={Object.keys(CREDITOR_ACCOUNT_TYPE).map((key, index) => ({
										value: key === 'null' ? null : index,
										label: key === 'null' ? ' ' : key,
									}))}
								/>
							)}
						/>
					</Col>
					<Col xs={24}>
						<Controller
							name="isContractPresent"
							control={control}
							render={({ field }): JSX.Element => (
								<CLSelectInput
									{...field}
									label="Is Contract Present?"
									allowEmptyValue
									optionFilterProp="label"
									options={Object.keys(BOOLEAN_VALUES).map((key) => ({
										value: key,
										label: BOOLEAN_VALUES[key as BOOLEAN_STRING_TYPE],
									}))}
								/>
							)}
						/>
					</Col>
					<Col xs={24}>
						<Controller
							name="isCreditorPrePopulated"
							control={control}
							render={({ field }): JSX.Element => (
								<CLSelectInput
									{...field}
									label="Is Creditor Prepopulated?"
									allowEmptyValue
									optionFilterProp="label"
									options={Object.keys(BOOLEAN_VALUES).map((key) => ({
										value: key,
										label: BOOLEAN_VALUES[key as BOOLEAN_STRING_TYPE],
									}))}
								/>
							)}
						/>
					</Col>
					<Col xs={24}>
						<Controller
							name="paymentPurposeCode"
							control={control}
							render={({ field }): JSX.Element => {
								const name = 'paymentPurposeCode';
								const extendedProps = { ...field, name };
								return (
									<CLSelectInput
										{...extendedProps}
										label="Payment Purpose Code"
										required
										errors={errors}
										optionFilterProp="label"
										options={Object.keys(PAYMENT_PURPOSE_CODE).map((key, index) => ({
											value: key === 'null' ? null : index,
											label: key === 'null' ? ' ' : key,
											title: PAYMENT_PURPOSE_CODE[key as keyof typeof PAYMENT_PURPOSE_CODE],
										}))}
									/>
								);
							}}
						/>
					</Col>
				</Row>

				<Row className="payment-details-header">
					<h4>
						Payment details <span className="cl-input-required">*</span>
					</h4>
					<h6>Set details for payments initiated under the consent:</h6>
				</Row>

				<Row gutter={16}>
					<Col xs={24} sm={24} lg={12}>
						<Controller
							name="paymentDetails.amount"
							control={control}
							render={({ field }): JSX.Element => (
								<CLTextInput
									{...field}
									label={'Amount'}
									required
									errors={errors}
									hasNestedObjectForValidate
								/>
							)}
						/>
					</Col>

					<Col xs={24} sm={24} lg={12}>
						<Controller
							name="paymentDetails.currency"
							control={control}
							render={({ field }): JSX.Element => (
								<CLTextInput
									{...field}
									label={'Currency'}
									required
									errors={errors}
									hasNestedObjectForValidate
								/>
							)}
						/>
					</Col>

					<Col xs={24}>
						<Controller
							name="paymentDetails.endToEndId"
							control={control}
							render={({ field }): JSX.Element => (
								<CLTextInput
									{...field}
									label={'End-to-End Id'}
									errors={errors}
									required
									hasNestedObjectForValidate
								/>
							)}
						/>
					</Col>

					<Col lg={24}>
						<Controller
							name="IsFirstPaymentApplicable"
							control={control}
							render={({ field }): JSX.Element => (
								<CLCheckbox
									className="cl-checkbox-field"
									{...field}
									label="Initiate first payment at mandate creation"
									checked={getValues('IsFirstPaymentApplicable')}
									onChange={(event: CheckboxChangeEvent): void => {
										setValue('IsFirstPaymentApplicable', event.target.checked);
									}}
								/>
							)}
						/>
					</Col>
				</Row>

				{errorMessages.length > 0 &&
					errorMessages.map((message, index) => (
						<p key={index} className="vrp-submit-errors">
							{message}
						</p>
					))}
			</form>
		</div>
	);
};

export default VrpMissionForm;
