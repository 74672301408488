import { IReferenceInformationValidator } from './Models/IReferenceInformationValidator';

export class NorwayReferenceInformationValidator implements IReferenceInformationValidator {
	pattern = /^[A-Za-z0-9 ]{0,20}$/;

	maxLength = 20;

	message?: string;

	constructor() {
		this.message = `Maximum Length is of ${this.maxLength} characters and the message must follow this pattern "${this.pattern}"`;
	}
}
