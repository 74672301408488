import './navbar.css';

import { UserOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Menu, MenuProps } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useAxios from '../../hooks/axios';
import useUserInfo from '../../hooks/userInfo';
import config from '../../resources/config';
import { setUserEmail } from '../../store/reducers/authReducer';

const IconMenu = ({ mode }: MenuProps): JSX.Element => {
	const history = useHistory();
	const [hideIconMenu, setHideIconMenu] = useState(true);
	const { getUserInfo } = useUserInfo();
	const { logout } = useAuth0();
	const dispatch = useDispatch();

	const logoutWithRedirect = (): void => {
		logout({
			returnTo: window.location.origin,
			federated: true,
			localOnly: false,
			client_id: config.clientId,
		});
	};

	const { response, error }: { response: AxiosResponse<string>; error: Error } = useAxios({
		method: 'GET',
		url: '/api/testers/current',
		headers: {
			accept: '*/*',
		},
	});

	useEffect(() => {
		if (error === undefined && response !== undefined) {
			dispatch(setUserEmail(response?.data));
		}
	}, [response, error, dispatch]);

	useEffect(() => {
		void (async (): Promise<void> => {
			const { isRegistrationCompleted, roles } = await getUserInfo();
			setHideIconMenu(
				isRegistrationCompleted && !roles.length && history.location.pathname === '/tester-onboarding'
			);
		})();
	}, [history.location.pathname]);

	const logoutItem: ItemType = {
		label: 'Logout',
		key: 'logoutItem',
		className: 'logoutItem',
		onClick: () => logoutWithRedirect(),
	};

	const iconMenu: MenuProps['items'] = [];

	if (!hideIconMenu) {
		iconMenu.push({
			label: '',
			key: 'IconMenu',
			icon: <UserOutlined className="icon" />,
			children: [logoutItem],
		});
	}
	return <Menu mode={mode} items={iconMenu} title={response?.data} className={'profileMenu'} />;
};

export default IconMenu;
