import { MarketRules, ProviderRules, Row } from './interfaces';
import { renderConditions } from './renderConditions';

// Helper function to determine visibility based on the `name` property of fields
export const isFieldVisible = (market: string, providerId: string, rowName: string, fieldName: string): boolean => {
	if (renderConditions[market]) {
		const activeMarket = renderConditions[market];
		const activeProvider = (activeMarket as ProviderRules)[providerId] || (activeMarket as MarketRules).Default;

		// Type assertion to ensure that activeProvider is of type Record<string, Row>
		const row = (activeProvider as Record<string, Row>)[rowName];
		return row?.fields[fieldName]?.isVisible ?? false;
	}

	const activeMarket = renderConditions.Default;

	// Type assertion to ensure that activeProvider is of type Record<string, Row>
	const row = activeMarket[rowName] as Row;
	return row?.fields[fieldName]?.isVisible ?? false;
};

// Helper function to determine if the entire row should be visible
export const isRowVisible = (market: string, providerId: string, rowName: string): boolean => {
	if (renderConditions[market]) {
		const activeMarket = renderConditions[market];
		const activeProvider = (activeMarket as ProviderRules)[providerId] || (activeMarket as MarketRules).Default;

		// Type assertion to ensure that activeProvider is of type Record<string, Row>
		const row = (activeProvider as Record<string, Row>)[rowName];
		const fields = row?.fields || {};

		// Cast fields to Record<string, Field> to ensure correct type for the some() method
		return Object.values(fields).some((field) => field.isVisible);
	}

	const activeMarket = renderConditions.Default;

	// Type assertion to ensure that activeProvider is of type Record<string, Row>
	const row = activeMarket[rowName] as Row;
	const fields = row?.fields || {};

	// Cast fields to Record<string, Field> to ensure correct type for the some() method
	return Object.values(fields).some((field) => field.isVisible);
};

export const getRowNameForField = (market: string, providerId: string, fieldName: string): string => {
	const findRowName = (activeProvider: Record<string, Row>): string | undefined =>
		Object.keys(activeProvider).find((rowName) => {
			const row = activeProvider[rowName];
			return row.fields && Object.prototype.hasOwnProperty.call(row.fields, fieldName);
		});

	if (renderConditions[market]) {
		const activeMarket = renderConditions[market];
		const activeProvider =
			(activeMarket as Record<string, Record<string, unknown>>)[providerId] ||
			(activeMarket as Record<string, unknown>).Default;

		const rowName = findRowName(activeProvider as Record<string, Row>);
		if (rowName) {
			return rowName;
		}
	} else {
		const activeMarket = renderConditions.Default;
		const rowName = findRowName(activeMarket as Record<string, Row>);
		if (rowName) {
			return rowName;
		}
	}

	throw new Error(`Row name for field "${fieldName}" not found in market "${market}" with provider "${providerId}".`);
};
