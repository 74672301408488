import { configureStore } from '@reduxjs/toolkit';

import { authSlice } from './reducers/authReducer';
import { testerSlice } from './reducers/testerReducer';

export const store = configureStore({
	reducer: {
		auth: authSlice.reducer,
		tester: testerSlice.reducer,
	},
});

export type RootState = ReturnType<typeof store.getState>;
